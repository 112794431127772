<template>
  <page-header-wrapper class="month-discount" :title='this.$route.meta.title'>
    <a-tabs default-active-key="1" :tabBarStyle="{background: '#fff'}">
      <a-tab-pane key="1" :tab="'会员订单' + (memberTotal > -1 ? '(' + memberTotal + '笔)' : '') ">
        <member-list @setMemberTitle="setMemberTitle"></member-list>
      </a-tab-pane>
      <a-tab-pane key="3" :tab="'合伙人订单' + (partnerTotal > -1 ? '(' + partnerTotal + '笔)' : '') ">
        <partner-list  @setPartnerTitle="setPartnerTitle"></partner-list>
      </a-tab-pane>
      <a-tab-pane key="4" :tab="'运营订单' + (presidentTotal > -1 ? '(' + presidentTotal + '笔)' : '') ">
        <persident-list  @setPersidentTitle="setPersidentTitle"></persident-list>
      </a-tab-pane>
      <a-tab-pane key="5" :tab="'权益订单' + (rightTotal > -1 ? '(' + rightTotal + '笔)' : '') ">
        <right-list  @setRightTitle="setRightTitle"></right-list>
      </a-tab-pane>
    </a-tabs>
  </page-header-wrapper>
</template>

<script>
import MemberList from "./orderNew/MemberList"
import PartnerList from "./orderNew/PartnerList"
import PersidentList from "./orderNew/PersidentList.vue"
import RightList from "./orderNew/RightList.vue"
import {queryDirectOrder, queryMerchantOrder, queryMerchantOrderNew, queryNoAuditOrder} from "@/api/member-order";
import moment from "moment";

const formatType = 'YYYY-MM-DD HH:mm';
const baseStart = moment().startOf('month').format(formatType);
const baseEnd = moment().format(formatType);

export default {
  name: 'MainList',
  components: {
    MemberList,PartnerList,PersidentList,RightList
  },
  data() {
    return {
      memberTotal: -1,
      partnerTotal:-1,
      presidentTotal:-1,
      rightTotal:-1,
    }
  },
  created() {
    this.handleInitial();
  },
  methods: {
    setMemberTitle(value) {
      this.memberTotal = value
    },
    setPartnerTitle(value) {

      this.partnerTotal = value
    },
    setPersidentTitle(value) {
      this.presidentTotal = value
    },
    setRightTitle(value) {
      this.rightTotal = value
    },

    handleInitial(){
      let arr = this.getDataStartEnd(baseStart);
      //合伙人套餐订单
      queryMerchantOrderNew({current: 1,size: 10,start:arr[0],end:arr[1],orderType:'16'}).then(datum => {
        this.setPartnerTitle(datum.total)
      })

      //运营套餐订单
      queryMerchantOrderNew({current: 1,size: 10,start:arr[0],end:arr[1],orderType:'9'}).then(datum => {
        this.setPersidentTitle(datum.total );
      })

      //权益订单
      queryMerchantOrderNew({current: 1,size: 10,start:arr[0],end:arr[1],orderType:'1'}).then(datum => {
        this.setRightTitle(datum.total);
      })
    },
    getDataStartEnd(tempMonth){
      let start = "";
      let end = "";

      let monthYear = tempMonth.split("-");
      let year = monthYear[0];
      let month = monthYear[1];
      end = this.getMonthFinalDay(year,month);
      start = year + "-" + month + "-" + "01";

      end = end + " 23:59";
      start = start + " 00:00";

      let array = [];
      array.push(start);
      array.push(end);
      return array;
    },
    getMonthFinalDay(year,month){
      let day='';
      if(year == null || year===''){
        year = new Date().getFullYear();
      }
      if(month == null || month===''){
        month = new Date().getMonth()+1;
      }
      day = new Date(new Date(year,month).setDate(0)).getDate();
      return year+"-"+month+"-"+day;

    },

  },
}
</script>
<style lang="less">
.month-discount {
  .ant-tabs-nav-wrap {
    padding: 0 26px;
  }
  .ant-tabs-bar {
    margin: unset !important;
  }
  >.ant-pro-grid-content>.ant-pro-page-header-wrap-children-content {
    margin: unset !important;
  }
}
</style>
