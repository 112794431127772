// eslint-disable-next-line
import { UserLayout, BasicLayout, BlankLayout, PageView, RouteView } from '@/layouts'

export const asyncRouterMap = [
  {
    path: '/',
    name: 'index',
    component: BasicLayout,
    meta: { title: 'menu.home' },
    redirect: '/order/main_list',
    children: [
      // // 主页
      // {
      //   path: '/dashboard/workplace',
      //   name: 'Home',
      //   component: () => import('@/views/dashboard/Analysis'),
      //   meta: { title: '主页', icon: 'dashboard', keepAlive: true, roles: ['admin', 'customer', 'operator', 'marketer'] }
      // },
      //订单管理
      {
        path: '/order',
        name: 'OrderList',
        redirect: '/order/list',
        component: RouteView,
        meta: { title: '订单管理', icon: 'inbox', keepAlive: true, roles: ['admin', 'customer', 'operator', 'marketer'] },
        children: [
          {
            path: '/order/main_list',
            name: 'MainList',
            component: () => import('@/views/order/MainList'),
            meta: { title: '财务核单', keepAlive: true, roles: ['admin', 'customer'] }
          },
          // {
          //   path: '/order/list',
          //   name: 'OrderList',
          //   component: () => import('@/views/order/List'),
          //   meta: { title: '财务核单', keepAlive: true, roles: ['admin', 'customer'] }
          // },
          {
            hidden: true,
            path: '/order/orderNew/OrderDetail',
            name: 'OrderDetail',
            component: () => import('@/views/order/orderNew/OrderDetail'),
            meta: { title: '财务核单详情', keepAlive: true, roles: ['admin', 'customer'] }
          },
          // {
          //   hidden: true,
          //   name: 'DirectOrderEdit',
          //   path: '/order/direct/edit',
          //   component: () => import('@/views/order/direct/Edit'),
          //   meta: { title: '直付订单详情', keepAlive: true, roles: ['admin', 'operator', 'marketer'] }
          // },
          {
            hidden: true,
            path: '/order/detail',
            name: 'OrderDetail',
            component: () => import('@/views/order/Detail'),
            meta: { title: '购销记录详情', keepAlive: true, roles: ['admin', 'customer'] }
          },
          {
            hidden: true,
            path: '/order/counter/list',
            name: 'CounterList',
            component: () => import('@/views/order/counter/List'),
            meta: { title: '台账核单', keepAlive: true, roles: ['admin', 'customer'] }
          },
          {
            path: '/order/audit/list',
            name: 'AuditOrderList',
            component: () => import('@/views/order/audit/List'),
            meta: { title: '业务核单', keepAlive: true, roles: ['admin', 'operator', 'marketer'] }
          },
          {
            hidden: true,
            name: 'AuditOrderEdit',
            path: '/order/audit/edit',
            component: () => import('@/views/order/audit/Edit'),
            meta: { title: '业务核单详情', keepAlive: true, roles: ['admin', 'operator', 'marketer'] }
          },
          {
            hidden: true,
            name: 'AuditMonthDiscountList',
            path: '/order/audit/month_discount/list',
            component: () => import('@/views/order/audit/monthDiscount/List'),
            meta: { title: '本月商品折扣', keepAlive: true, roles: ['admin', 'operator', 'marketer'] }
          },
          {
            hidden: true,
            name: 'AuditDiscountList',
            path: '/order/audit/discount/list',
            component: () => import('@/views/order/audit/discount/List'),
            meta: { title: '实时商品折扣', keepAlive: true, roles: ['admin', 'operator', 'marketer'] }
          },
          {
            hidden: true,
            name: 'AuditMonthCashList',
            path: '/order/audit/month_cash/list',
            component: () => import('@/views/order/audit/monthCash/List'),
            meta: { title: '本月现金折扣', keepAlive: true, roles: ['admin', 'operator', 'marketer'] }
          },
          {
            hidden: true,
            name: 'AuditCashList',
            path: '/order/audit/cash/list',
            component: () => import('@/views/order/audit/cash/List'),
            meta: { title: '实时现金折扣', keepAlive: true, roles: ['admin', 'operator', 'marketer'] }
          },
          {
            hidden: true,
            name: 'prodDiscount',
            path: '/order/audit/prodDiscount/list',
            component: () => import('@/views/order/audit/prodDiscount/List'),
            meta: { title: '商品折扣', keepAlive: true, roles: ['admin', 'operator', 'marketer'] }
          },
          {
            hidden: true,
            name: 'prodDiscount',
            path: '/order/audit/cashDiscount/list',
            component: () => import('@/views/order/audit/cashDiscount/List'),
            meta: { title: '现金折扣', keepAlive: true, roles: ['admin', 'operator', 'marketer'] }
          },
          // {
          //   path: '/order/cash/list',
          //   name: 'InsideCashList',
          //   component: () => import('@/views/order/cash/List'),
          //   meta: { title: '现金折扣', keepAlive: true, roles: ['admin'] }
          // }
          // {
          //   path: '/order/writeoff/list',
          //   name: 'WriteOffList',
          //   component: () => import('@/views/order/writeoff/List'),
          //   meta: { title: '补贴单核销', keepAlive: true, roles: ['admin', 'operator', 'marketer'] }
          // },
          // {
          //   hidden: true,
          //   name: 'WriteOffDetail',
          //   path: '/order/writeoff/detail',
          //   component: () => import('@/views/order/writeoff/Detail'),
          //   meta: { title: '补贴单详情', keepAlive: true, roles: ['admin', 'operator', 'marketer'] }
          // },
          // {
          //   path: '/order/member/list',
          //   name: 'MemberOrderList',
          //   component: () => import('@/views/order/member/List'),
          //   meta: { title: '会员核单', keepAlive: true, roles: ['admin', 'operator', 'marketer'] }
          // },
          // {
          //   hidden: true,
          //   name: 'MemberOrderDetail',
          //   path: '/order/member/detail',
          //   component: () => import('@/views/order/member/Detail'),
          //   meta: { title: '会员核单详情', keepAlive: true, roles: ['admin', 'operator', 'marketer'] }
          // },
          // {
          //   path: '/order/partner/list',
          //   name: 'PartnerOrderList',
          //   component: () => import('@/views/order/partner/List'),
          //   meta: { title: '合伙人核单', keepAlive: true, roles: ['admin', 'operator', 'marketer'] }
          // },
          // {
          //   hidden: true,
          //   name: 'PartnerOrderDetail',
          //   path: '/order/partner/detail',
          //   component: () => import('@/views/order/partner/Detail'),
          //   meta: { title: '合伙人核单详情', keepAlive: true, roles: ['admin', 'operator', 'marketer'] }
          // },
        ]
      },
      // 业绩管理
      {
        path: '/performance',
        name: 'PerformanceAreaList',
        redirect: '/performance/area/list',
        component: RouteView,
        meta: { title: '业绩管理', icon: 'bar-chart', keepAlive: true, roles: ['admin', 'customer', 'operator', 'marketer'] },
        children: [
          {
            path: '/performance/area/list',
            name: 'PerformanceAreaList',
            component: () => import('@/views/performance/area/List'),
            meta: { title: '分公司架构', keepAlive: true, roles: ['admin', 'customer'] }
          },
          {
            hidden: true,
            path: '/performance/area/office/edit',
            name: 'PerformanceAreaEdit',
            component: () => import('@/views/performance/area/office/Edit'),
            meta: { title: '分公司详情', keepAlive: true, roles: ['admin', 'customer'] }
          },
          {
            hidden: true,
            path: '/performance/area/company/edit',
            name: 'PerformanceCompanyEdit',
            component: () => import('@/views/performance/area/company/Edit'),
            meta: { title: '品牌公司详情', keepAlive: true, roles: ['admin', 'customer'] }
          },
          {
            path: '/performance/president/list',
            name: 'PerformancePresidentList',
            component: () => import('@/views/performance/president/List'),
            meta: { title: '运营业绩', keepAlive: true, roles: ['admin', 'customer'] }
          },
          {
            hidden: true,
            path: '/performance/president/edit',
            name: 'PerformancePresidentEdit',
            component: () => import('@/views/performance/president/Edit'),
            meta: { title: '运营业绩详情', keepAlive: true, roles: ['admin', 'customer'] }
          },
          // {
          //   path: '/performance/reward/list',
          //   name: 'PerformanceRewardList',
          //   component: () => import('@/views/performance/reward/List'),
          //   meta: { title: '运营奖励', keepAlive: true, roles: ['admin', 'customer'] }
          // },
          // {
          //   path: '/performance/subsidy/apply/list',
          //   name: 'PerformanceSubsidyApplyList',
          //   component: () => import('@/views/performance/subsidy/apply/List'),
          //   meta: { title: '房租补贴审核', keepAlive: true, roles: ['admin', 'customer'] }
          // },
          // {
          //   path: '/performance/subsidy/list',
          //   name: 'PerformanceSubsidyList',
          //   component: () => import('@/views/performance/subsidy/List'),
          //   meta: { title: '房租补贴', keepAlive: true, roles: ['admin', 'customer'] }
          // },
        ]
      },
      // 商户中心
      {
        path: '/member',
        name: 'MemberList',
        redirect: '/member/apply/list',
        component: RouteView,
        meta: { title: '商户中心', icon: 'user', keepAlive: true, roles: ['admin', 'customer', 'operator', 'marketer'] },
        children: [
          {
            path: '/member/apply/list',
            name: 'MemberApplyList',
            component: () => import('@/views/member/apply/List'),
            meta: { title: '商户审核', keepAlive: true, roles: ['admin', 'operator', 'marketer'] }
          },
          {
            hidden: true,
            name: 'MemberApplyEdit',
            path: '/member/apply/edit',
            component: () => import('@/views/member/apply/Edit'),
            meta: { title: '审核详情', keepAlive: true, roles: ['admin', 'operator', 'marketer'] }
          },
          {
            path: '/member/list',
            name: 'Members',
            component: () => import('@/views/member/List'),
            meta: { title: '商户管理', keepAlive: true, roles: ['admin', 'operator', 'marketer'] }
          },
          {
            hidden: true,
            path: '/member/edit',
            name: 'MemberEdit',
            component: () => import('@/views/member/Edit'),
            meta: { title: '商户详情', keepAlive: true, roles: ['admin', 'operator', 'marketer'] }
          },
          {
            path: '/member/tongzhi',
            name: 'TongZhi',
            component: () => import('@/views/member/tongzhi/List'),
            meta: { title: '消息通知', keepAlive: true, roles: ['admin', 'operator'] }
          },
        ]
      },
      // 仓库管理
      {
        path: '/inside',
        name: 'Inside',
        redirect: '/inside/storehouse/list',
        component: RouteView,
        meta: { title: '仓库管理', icon: 'project', keepAlive: true, roles: ['admin'] },
        children: [
          {
            path: '/inside/storehouse/list',
            name: 'InsideStorehouseList',
            component: () => import('@/views/inside/storehouse/List'),
            meta: { title: '订单出库', keepAlive: true, roles: ['admin'] }
          },
          {
            hidden: true,
            path: '/inside/storehouse/order',
            name: 'InsideStorehouseOrder',
            component: () => import('@/views/inside/storehouse/backup/Order'),
            meta: { title: '发货订单详情', keepAlive: true, roles: ['admin'] }
          },
          {
            path: '/inside/storehouse/detail',
            name: 'InsideStorehouseDetail',
            component: () => import('@/views/inside/storehouse/backup/Detail'),
            meta: { title: '04仓库详情', keepAlive: true, roles: ['admin'] }
          },
          {
            hidden: true,
            path: '/inside/storehouse/edit',
            name: 'InsideStorehouseEdit',
            component: () => import('@/views/inside/storehouse/backup/Edit'),
            meta: { title: '商品入库', keepAlive: true, roles: ['admin'] }
          },
          {
            hidden: true,
            path: '/inside/prod/list',
            name: 'InsideProdList',
            component: () => import('@/views/inside/prod/List'),
            meta: { title: '商品管理', keepAlive: true, roles: ['admin'] }
          },
          {
            path: '/inside/child/list',
            name: 'InsideChildList',
            component: () => import('@/views/inside/child/List'),
            meta: { title: '20仓库详情', keepAlive: true, roles: ['admin'] }
          },
          {
            hidden: true,
            path: '/inside/child/type',
            name: 'InsideChildType',
            component: () => import('@/views/inside/child/Type'),
            meta: { title: '类型详情', keepAlive: true, roles: ['admin'] }
          },
          {
            hidden: true,
            path: '/inside/child/edit',
            name: 'InsideChildList',
            component: () => import('@/views/inside/child/Edit'),
            meta: { title: '出入库记录', keepAlive: true, roles: ['admin'] }
          },
          // {
          //   path: '/inside/special/list',
          //   name: 'InsideSpecialList',
          //   component: () => import('@/views/inside/special/List'),
          //   meta: { title: '特价订单', keepAlive: true, roles: ['admin'] }
          // },
        ]
      },
      //活动管理
      {
        path: '/activity',
        name: 'ActivityList',
        redirect: '/activity/list',
        component: RouteView,
        meta: { title: '活动管理', icon: 'inbox', keepAlive: true, roles: ['admin', 'customer', 'operator', 'marketer'] },
        children: [
          {
            path: '/activity/list',
            name: 'ActivityList',
            component: () => import('@/views/activity/List'),
            meta: { title: '会议管理', keepAlive: true, roles: ['admin', 'customer'] }
          },
          // {
          //   path: '/activity/apply/list',
          //   name: 'ActivityApplyList',
          //   component: () => import('@/views/activity/apply/List'),
          //   meta: { title: '活动申请', keepAlive: true, roles: ['admin', 'customer'] }
          // },
          // {
          //   path: '/activity/star/list',
          //   name: 'ActivityStarList',
          //   component: () => import('@/views/activity/star/List'),
          //   meta: { title: '服务之星', keepAlive: true, roles: ['admin', 'customer'] }
          // },
          {
            path: '/activity/record/list',
            name: 'MemberRecord',
            component: () => import('@/views/activity/record/List'),
            meta: { title: '记录管理', keepAlive: true, roles: ['admin', 'operator', 'marketer'] }
          },
          {
            path: '/activity/proposal/list',
            name: 'ProposalList',
            component: () => import('@/views/activity/proposal/List'),
            meta: { title: '建议与总结', keepAlive: true, roles: ['admin', 'operator', 'marketer'] }
          },
          {
            hidden: true,
            path: '/activity/proposal/edit',
            name: 'ProposalEdit',
            component: () => import('@/views/activity/proposal/Edit'),
            meta: { title: '总结详情', keepAlive: true, roles: ['admin', 'operator', 'marketer'] }
          },
        ]
      },
      // 商品管理
      {
        path: '/production',
        name: 'Production',
        redirect: '/production/list',
        component: RouteView,
        meta: { title: '商品管理', icon: 'skin', keepAlive: true, roles: ['admin'] },
        children: [
          {
            path: '/production/list',
            name: 'ProductionList',
            component: () => import('@/views/production/List'),
            meta: { title: '商品列表', keepAlive: true, roles: ['admin'] }
          },
          {
            hidden: true,
            path: '/production/edit',
            name: 'ProductionEdit',
            component: () => import('@/views/production/Edit'),
            meta: { title: '商品编辑', keepAlive: true, roles: ['admin'] }
          },
          {
            path: '/production/category/list',
            name: 'ProductionCategoryList',
            component: () => import('@/views/production/category/List'),
            meta: { title: '商品分类', keepAlive: true, roles: ['admin'] }
          },
          {
            hidden: true,
            path: '/production/category/edit',
            name: 'ProductionCategoryEdit',
            component: () => import('@/views/production/category/Edit'),
            meta: { title: '商品分类编辑', keepAlive: true, roles: ['admin'] }
          },
          {
            path: '/production/meal/list',
            name: 'ProductionMealList',
            component: () => import('@/views/production/meal/List'),
            meta: { title: '套餐列表', keepAlive: true, roles: ['admin'] }
          },
          {
            hidden: true,
            path: '/production/meal/edit',
            name: 'ProductionMealEdit',
            component: () => import('@/views/production/meal/Edit'),
            meta: { title: '套餐商品编辑', keepAlive: true, roles: ['admin'] }
          },{
            path: '/production/type/list',
            name: 'ProductionMealType',
            component: () => import('@/views/production/type/List'),
            meta: { title: '套餐类型', keepAlive: true, roles: ['admin'] }
          },
          {
            hidden: true,
            path: '/production/type/edit',
            name: 'ProductionMealTypeEdit',
            component: () => import('@/views/production/type/Edit'),
            meta: { title: '奖励设置', keepAlive: true, roles: ['admin'] }
          }
        ]
      },
      // 登录管理
      {
        path: '/limit',
        name: 'Limit',
        redirect: '/limit/position/list',
        component: RouteView,
        meta: { title: '登录管理', icon: 'project', keepAlive: true, roles: ['admin'] },
        children: [
          {
            path: '/limit/position/list',
            name: 'LimitPositionList',
            component: () => import('@/views/limit/position/List'),
            meta: { title: '授权管理', keepAlive: true, roles: ['admin'] }
          },
          {
            hidden: true,
            path: '/limit/position/edit',
            name: 'LimitPositionEdit',
            component: () => import('@/views/limit/position/Edit'),
            meta: { title: '权限编辑', keepAlive: true, roles: ['admin'] }
          },
          {
            path: '/permission/operator',
            name: 'Operators',
            component: () => import('@/views/permission/operator/List'),
            meta: { title: '用户管理', keepAlive: true, roles: ['admin'] }
          },
          {
            hidden: true,
            path: '/permission/whitelist/list',
            name: 'WhiteList',
            component: () => import('@/views/permission/whitelist/List'),
            meta: { title: 'ip白名单', keepAlive: true, roles: ['admin'] }
          },
          {
            hidden: true,
            path: '/permission/operator/edit',
            name: 'OperatorEdit',
            component: () => import('@/views/permission/operator/Edit'),
            meta: { title: '用户编辑', keepAlive: true, roles: ['admin'] }
          },
          {
            path: '/limit/region/list',
            name: 'LimitRegionList',
            component: () => import('@/views/limit/region/List'),
            meta: { title: '登录管理', keepAlive: true, roles: ['admin'] }
          },
        ]
      },
      // 内容管理
      {
        path: '/content',
        name: 'Content',
        redirect: '/content/other',
        component: RouteView,
        meta: { title: '内容管理', icon: 'layout', keepAlive: true, roles: ['admin', 'operator'] },
        children: [
          {
            path: '/content/other',
            name: 'OtherList',
            component: () => import('@/views/content/other/List'),
            meta: { title: '其他内容', keepAlive: true, roles: ['admin', 'operator'] }
          },
          {
            hidden: true,
            path: '/content/other/edit',
            name: 'OtherEdit',
            component: () => import('@/views/content/other/Edit'),
            meta: { title: '其他内容编辑', keepAlive: true, roles: ['admin', 'operator'] }
          },
          {
            path: '/content/campus',
            name: 'CampusList',
            component: () => import('@/views/content/campus/List'),
            meta: { title: '商学院', keepAlive: true, roles: ['admin', 'operator'] }
          },
          {
            hidden: true,
            path: '/content/campus/edit',
            name: 'CampusEdit',
            component: () => import('@/views/content/campus/Edit'),
            meta: { title: '商学院编辑', keepAlive: true, roles: ['admin', 'operator'] }
          },
          {
            path: '/content/introduce',
            name: 'InformList',
            component: () => import('@/views/content/inform/List'),
            meta: { title: '企业文化', keepAlive: true, roles: ['admin', 'operator'] }
          },
          {
            hidden: true,
            path: '/content/introduce/edit',
            name: 'InformEdit',
            component: () => import('@/views/content/inform/Edit'),
            meta: { title: '企业文化编辑', keepAlive: true, roles: ['admin', 'operator'] }
          },
          {
            path: '/content/case',
            name: 'CaseList',
            component: () => import('@/views/content/case/List'),
            meta: { title: '客户见证', keepAlive: true, roles: ['admin', 'operator'] }
          },
          {
            hidden: true,
            path: '/content/case/edit',
            name: 'CaseEdit',
            component: () => import('@/views/content/case/Edit'),
            meta: { title: '客户见证编辑', keepAlive: true, roles: ['admin', 'operator'] }
          },{
            path: '/content/taisui',
            name: 'TaisuiList',
            component: () => import('@/views/content/taisui/List'),
            meta: { title: '太岁', keepAlive: true, roles: ['admin', 'operator'] }
          },
          {
            hidden: true,
            path: '/content/taisui/edit',
            name: 'TaisuiEdit',
            component: () => import('@/views/content/taisui/Edit'),
            meta: { title: '太岁编辑', keepAlive: true, roles: ['admin', 'operator'] }
          },
          {
            path: '/content/class',
            name: 'ClassList',
            component: () => import('@/views/content/class/List'),
            meta: { title: '养生课堂', keepAlive: true, roles: ['admin', 'operator'] }
          },
          {
            hidden: true,
            path: '/content/class/edit',
            name: 'ClassEdit',
            component: () => import('@/views/content/class/Edit'),
            meta: { title: '养生课堂编辑', keepAlive: true, roles: ['admin', 'operator'] }
          },{
            path: '/content/question',
            name: 'QuestionList',
            component: () => import('@/views/content/question/List'),
            meta: { title: '常见问题', keepAlive: true, roles: ['admin', 'operator'] }
          },
          {
            hidden: true,
            path: '/content/question/edit',
            name: 'QuestionEdit',
            component: () => import('@/views/content/question/Edit'),
            meta: { title: '常见问题编辑', keepAlive: true, roles: ['admin', 'operator'] }
          },
          {
            path: '/content/category',
            name: 'ContentCategory',
            component: () => import('@/views/content/category/List'),
            meta: { title: '内容栏目', keepAlive: true, roles: ['admin', 'operator'] }
          },
          {
            hidden: true,
            path: '/content/category/edit',
            name: 'ContentCategoryEdit',
            component: () => import('@/views/content/category/Edit'),
            meta: { title: '内容栏目编辑', keepAlive: true, roles: ['admin', 'operator'] }
          }

        ]
      },
      // 客服管理
      // {
      //   path: '/message',
      //   name: 'Message',
      //   redirect: '/message/list',
      //   component: RouteView,
      //   meta: {
      //     title: '客服管理',
      //     icon: 'customer-service',
      //     keepAlive: true,
      //     roles: ['admin', 'customer', 'operator', 'marketer']
      //   },
      //   children: [
      //     {
      //       path: '/message/list',
      //       name: 'MessageList',
      //       component: () => import('@/views/message/List'),
      //       meta: { title: '留言列表', keepAlive: true, roles: ['admin', 'customer', 'operator', 'marketer'] }
      //     },
      //     {
      //       hidden: true,
      //       path: '/message/edit',
      //       name: 'MessageEdit',
      //       component: () => import('@/views/message/Edit'),
      //       meta: { title: '留言详情', keepAlive: false, roles: ['admin', 'customer', 'operator', 'marketer'] }
      //     }
      //   ]
      // },
      // 健康管理
      // {
      //   name: 'Health',
      //   path: '/health',
      //   redirect: '/health/answer/list',
      //   component: RouteView,
      //   meta: { title: '健康管理', icon: 'medicine-box', keepAlive: true, roles: ['admin', 'customer', 'operator'] },
      //   children: [
      //     // {
      //     //   path: '/health/list',
      //     //   name: 'HealthList',
      //     //   component: () => import('@/views/health/List'),
      //     //   meta: { title: '调查问卷', keepAlive: true, roles: ['admin', 'customer', 'operator'] }
      //     // },
      //     // {
      //     //   hidden: true,
      //     //   path: '/health/edit',
      //     //   name: 'HealthView',
      //     //   component: () => import('@/views/health/Edit'),
      //     //   meta: { title: '问卷详情', keepAlive: false, roles: ['admin', 'customer', 'operator'] }
      //     // },
      //     {
      //       path: '/health/question/list',
      //       name: 'HealthQuestionList',
      //       component: () => import('@/views/health/question/List'),
      //       meta: { title: '问卷管理', keepAlive: true, roles: ['admin', 'customer', 'operator'] }
      //     },
      //     {
      //       hidden: true,
      //       path: '/health/question/edit',
      //       name: 'HealthQuestionEdit',
      //       component: () => import('@/views/health/question/Edit'),
      //       meta: { title: '编辑问卷', keepAlive: false, roles: ['admin', 'customer', 'operator'] }
      //     },
      //     {
      //       path: '/health/answer/list',
      //       name: 'HealthAnswerList',
      //       component: () => import('@/views/health/answer/List'),
      //       meta: { title: '调查问卷', keepAlive: true, roles: ['admin', 'customer', 'operator'] }
      //     },
      //     {
      //       hidden: true,
      //       path: '/health/answer/edit',
      //       name: 'HealthAnswerView',
      //       component: () => import('@/views/health/answer/Edit'),
      //       meta: { title: '问卷详情', keepAlive: false, roles: ['admin', 'customer', 'operator'] }
      //     }
      //   ]
      // },
      // 广告活动
      {
        path: '/operation',
        name: 'Operation',
        redirect: '/operation/advertise',
        component: RouteView,
        meta: { title: '广告活动', icon: 'gift', keepAlive: true, roles: ['admin', 'operator'] },
        children: [
          {
            name: 'Advertise',
            path: '/operation/advertise',
            component: () => import('@/views/operation/advertise/List'),
            meta: { title: '广告管理', keepAlive: true, roles: ['admin', 'operator'], permission: [] }
          },
          {
            hidden: true,
            path: '/operation/advertise/new',
            name: 'AdvertiseNew',
            component: () => import('@/views/operation/advertise/Edit'),
            meta: { title: '新建广告', keepAlive: true, roles: ['admin', 'operator'], permission: [] }
          },
          {
            hidden: true,
            path: '/operation/advertise/edit',
            name: 'AdvertiseEdit',
            component: () => import('@/views/operation/advertise/Edit'),
            meta: { title: '编辑广告', keepAlive: true, roles: ['admin', 'operator'], permission: [] }
          },
          {
            name: 'MiniAdvertise',
            path: '/operation/mini',
            component: () => import('@/views/operation/mini/List'),
            meta: { title: '小程序广告', keepAlive: true, roles: ['admin', 'operator'], permission: [] }
          },
          {
            hidden: true,
            path: '/operation/mini/edit',
            name: 'MiniAdvertiseEdit',
            component: () => import('@/views/operation/mini/Edit'),
            meta: { title: '编辑小程序广告', keepAlive: true, roles: ['admin', 'operator'], permission: [] }
          },
        ]
      },
      // 协议管理
      {
        path: '/protocol',
        name: 'Protocol',
        redirect: '/protocol/list',
        component: RouteView,
        meta: { title: '协议管理', icon: 'file-text', keepAlive: true, roles: ['admin'] },
        children: [
          {
            path: '/protocol/list',
            name: 'ProtocolList',
            component: () => import('@/views/protocol/List'),
            meta: { title: '协议内容', keepAlive: true, roles: ['admin'] }
          },
          {
            hidden: true,
            path: '/protocol/edit',
            name: 'ProtocolEdit',
            component: () => import('@/views/protocol/Edit'),
            meta: { title: '协议编辑', keepAlive: true, roles: ['admin'] }
          },
          {
            path: '/article/list',
            name: 'articleList',
            component: () => import('@/views/protocol/article/List'),
            meta: { title: '发文管理', keepAlive: true, roles: ['admin'] }
          },
        ]
      },
      // 配置中心
      {
        name: 'Config',
        path: '/config',
        redirect: '/config/discount',
        component: RouteView,
        meta: { title: '配置中心', icon: 'setting', keepAlive: true, roles: ['admin', 'operator'] },
        children: [
          {
            path: '/config/discount',
            name: 'ProfitDiscount',
            component: () => import('@/views/config/discount/Index'),
            meta: { title: '商品折扣', keepAlive: true, roles: ['admin', 'operator'] }
          },
          {
            path: '/config/account',
            name: 'Account',
            component: () => import('@/views/config/account/List'),
            meta: { title: '账户配置', keepAlive: true, roles: ['admin', 'operator'] }
          },
          {
            path: '/config/payChannel',
            name: 'PayChannel',
            component: () => import('@/views/config/payChannel/List'),
            meta: { title: '支付渠道配置', keepAlive: true, roles: ['admin', 'operator'] }
          },
          {
            path: '/config/channelAccount',
            name: 'ChannelAccount',
            component: () => import('@/views/config/channelAccount/List'),
            meta: { title: '渠道号管理', keepAlive: true, roles: ['admin', 'operator'] }
          },
          // {
          //   path: '/config/other',
          //   name: 'OtherConfig',
          //   component: () => import('@/views/config/other/Index'),
          //   meta: { title: '其他配置', keepAlive: true, roles: ['admin', 'operator'] }
          // },
        ]
      },

      // Exception
      {
        hidden: true,
        path: '/exception',
        name: 'exception',
        component: RouteView,
        redirect: '/exception/403',
        meta: { title: 'menu.exception', icon: 'warning', permission: ['exception'] },
        children: [
          {
            path: '/exception/403',
            name: 'Exception403',
            component: () => import(/* webpackChunkName: "fail" */ '@/views/exception/403'),
            meta: { title: 'menu.exception.not-permission', permission: ['exception'] }
          },
          {
            path: '/exception/404',
            name: 'Exception404',
            component: () => import(/* webpackChunkName: "fail" */ '@/views/exception/404'),
            meta: { title: 'menu.exception.not-find', permission: ['exception'] }
          },
          {
            path: '/exception/500',
            name: 'Exception500',
            component: () => import(/* webpackChunkName: "fail" */ '@/views/exception/500'),
            meta: { title: 'menu.exception.server-error', permission: ['exception'] }
          }
        ]
      },
      // account
      {
        hidden: true,
        path: '/account',
        component: RouteView,
        redirect: '/account/center',
        name: 'account',
        meta: { title: 'menu.account', icon: 'user', keepAlive: true, permission: ['user'] },
        children: [
          {
            path: '/account/center',
            name: 'center',
            component: () => import('@/views/account/center'),
            meta: { title: 'menu.account.center', keepAlive: true, permission: ['user'] }
          },
          {
            path: '/account/settings',
            name: 'settings',
            component: () => import('@/views/account/settings/Index'),
            meta: { title: 'menu.account.settings', hideHeader: true, permission: ['user'] },
            redirect: '/account/settings/basic',
            hideChildrenInMenu: true,
            children: [
              {
                path: '/account/settings/basic',
                name: 'BasicSettings',
                component: () => import('@/views/account/settings/BasicSetting'),
                meta: { title: 'account.settings.menuMap.basic', hidden: true, permission: ['user'] }
              },
              {
                path: '/account/settings/security',
                name: 'SecuritySettings',
                component: () => import('@/views/account/settings/Security'),
                meta: {
                  title: 'account.settings.menuMap.security',
                  hidden: true,
                  keepAlive: true,
                  permission: ['user']
                }
              },
              {
                path: '/account/settings/custom',
                name: 'CustomSettings',
                component: () => import('@/views/account/settings/Custom'),
                meta: { title: 'account.settings.menuMap.custom', hidden: true, keepAlive: true, permission: ['user'] }
              },
              {
                path: '/account/settings/binding',
                name: 'BindingSettings',
                component: () => import('@/views/account/settings/Binding'),
                meta: { title: 'account.settings.menuMap.binding', hidden: true, keepAlive: true, permission: ['user'] }
              },
              {
                path: '/account/settings/notification',
                name: 'NotificationSettings',
                component: () => import('@/views/account/settings/Notification'),
                meta: {
                  title: 'account.settings.menuMap.notification',
                  hidden: true,
                  keepAlive: true,
                  permission: ['user']
                }
              }
            ]
          }
        ]
      },



    ]
  },
  {
    path: '*',
    redirect: '/404',
    hidden: true
  }
]
// 用于权限管理
export const addRouters = JSON.parse(JSON.stringify(asyncRouterMap))

/**
 * 基础路由
 * @type { *[] }
 */
export const constantRouterMap = [
  {
    path: '/user',
    component: UserLayout,
    redirect: '/user/login',
    hidden: true,
    children: [
      {
        path: 'login',
        name: 'login',
        component: () => import(/* webpackChunkName: "user" */ '@/views/user/Login')
      },
      {
        path: 'register',
        name: 'register',
        component: () => import(/* webpackChunkName: "user" */ '@/views/user/Register')
      },
      {
        path: 'register-result',
        name: 'registerResult',
        component: () => import(/* webpackChunkName: "user" */ '@/views/user/RegisterResult')
      },
      {
        path: 'recover',
        name: 'recover',
        component: undefined
      }
    ]
  },

  {
    path: '/404',
    component: () => import(/* webpackChunkName: "fail" */ '@/views/exception/404')
  }
]
