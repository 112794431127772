<template>
  <page-header-wrapper :title='this.$route.meta.title'>
    <a-row :gutter='24'>
      <a-col :sm='10' :md='4' :xl='5' :style="{ marginBottom: '24px' }">
        <a-card >
          <a-descriptions :column="2" title="已审核">
            <a-descriptions-item label="混用 笔数"><span class="color-red">{{this.dashboard.auditMixCount}}</span></a-descriptions-item>
            <a-descriptions-item label="金额"><span class="color-red">{{this.dashboard.auditMixAmount}}</span></a-descriptions-item>

            <a-descriptions-item label="积分 笔数"><span class="color-red">{{this.dashboard.auditCashCount}}</span></a-descriptions-item>
            <a-descriptions-item label="金额"><span class="color-red">{{this.dashboard.auditCashAmount}}</span></a-descriptions-item>

            <a-descriptions-item label="现金 笔数"><span class="color-red">{{this.dashboard.auditMoneyCount}}</span></a-descriptions-item>
            <a-descriptions-item label="金额"><span class="color-red">{{this.dashboard.auditMoneyAmount}}</span></a-descriptions-item>

            <a-descriptions-item label="转让 积分"><span class="color-red">{{this.dashboard.transferTo}}</span></a-descriptions-item>
            <a-descriptions-item label="受让 积分"><span class="color-red">{{this.dashboard.transferFrom}}</span></a-descriptions-item>
          </a-descriptions>
        </a-card>
      </a-col>

      <a-col :sm='10' :md='4' :xl='5' :style="{ marginBottom: '24px' }">
        <a-card >
          <a-descriptions :column="2" title="待审核">
            <a-descriptions-item label="混用 笔数"><span class="color-red">{{this.dashboard.waitMixCount}}</span></a-descriptions-item>
            <a-descriptions-item label="金额"><span class="color-red">{{this.dashboard.waitMixAmount}}</span></a-descriptions-item>

            <a-descriptions-item label="积分 笔数"><span class="color-red">{{this.dashboard.waitCashCount}}</span></a-descriptions-item>
            <a-descriptions-item label="金额"><span class="color-red">{{this.dashboard.waitCashAmount}}</span></a-descriptions-item>

            <a-descriptions-item label="现金 笔数"><span class="color-red">{{this.dashboard.waitMoneyCount}}</span></a-descriptions-item>
            <a-descriptions-item label="金额"><span class="color-red">{{this.dashboard.waitMoneyAmount}}</span></a-descriptions-item>

            <a-descriptions-item :span="2"/>
          </a-descriptions>
        </a-card>
      </a-col>

      <a-col :sm='10' :md='4' :xl='4' :style="{ marginBottom: '24px' }">
        <a-card  @dblclick="toCash">
          <a-descriptions :column="1" :style="{ marginBottom: '5px' }">
            <a-descriptions-item label="实时可用积分"><span class="color-red">{{ this.dashboard.cashBalance }}</span></a-descriptions-item>
            <a-descriptions-item label="分公司"><span class="color-red">{{ this.dashboard.areaCashBalance }}</span></a-descriptions-item>
            <a-descriptions-item label="运营中心"><span class="color-red">{{ this.dashboard.presidentCashBalance }}</span></a-descriptions-item>
            <a-descriptions-item label="合伙人"><span class="color-red">{{ this.dashboard.partnerCashBalance }}</span></a-descriptions-item>
            <a-descriptions-item label="会员" ><span class="color-red">{{ this.dashboard.memberCashBalance }}</span></a-descriptions-item>
          </a-descriptions>
        </a-card>
      </a-col>

      <a-col :sm='10' :md='4' :xl='8' :style="{ marginBottom: '10px' }">
        <a-card >
          <a-descriptions :column="2" :style="{ marginBottom: '4px' }">
            <a-descriptions-item label="会员订单"></a-descriptions-item>
            <a-descriptions-item ><span class="color-red">{{ this.dashboard.memberOrderCount }}笔</span><span class="color-red" style="margin-left: 30px;">{{this.dashboard.memberOrderAmount}}</span></a-descriptions-item>

            <a-descriptions-item label="合伙人订单"></a-descriptions-item>
            <a-descriptions-item ><span class="color-red">{{ this.dashboard.partnerOrderCount }}笔</span><span class="color-red" style="margin-left: 30px;">{{this.dashboard.partnerOrderAmount}}</span></a-descriptions-item>

            <a-descriptions-item label="运营订单"></a-descriptions-item>
            <a-descriptions-item ><span class="color-red">{{ this.dashboard.presidentOrderCount }}笔</span><span class="color-red" style="margin-left: 30px;">{{this.dashboard.presidentOrderAmount}}</span></a-descriptions-item>

            <a-descriptions-item label="权益订单"></a-descriptions-item>
            <a-descriptions-item ><span class="color-red">{{ this.dashboard.rightsOrderCount }}笔</span><span class="color-red" style="margin-left: 30px;">{{this.dashboard.rightsOrderAmount}}</span></a-descriptions-item>

            <a-descriptions-item label="合计"></a-descriptions-item>
            <a-descriptions-item ><span class="color-red">{{ this.dashboard.totalCount }}笔</span><span class="color-red" style="margin-left: 30px;">{{this.dashboard.totalAmount}}</span></a-descriptions-item>

          </a-descriptions>
        </a-card>
      </a-col>

    </a-row>
    <a-form-model ref='form' :model='form' layout='inline'>
      <a-form-model-item prop='addressUserName'>
        <a-input v-model='form.addressUserName' style="width: 300px" placeholder="购买人名称" />
      </a-form-model-item>
      <a-form-model-item prop='addressUserMobile'>
        <a-input v-model='form.addressUserMobile' style="width: 300px" placeholder="购买人手机号" />
      </a-form-model-item>
<!--      <a-form-model-item prop='masterName'>-->
<!--        <a-input v-model='form.masterName' style="width: 300px" placeholder="商户负责人" />-->
<!--      </a-form-model-item>-->
      <a-form-model-item prop='endTag'>
        <a-select v-model="form.endTag" placeholder="订单尾号" style="width: 250px">
          <a-select-option value="-1">
            全部
          </a-select-option>
          <a-select-option value="H">
            H
          </a-select-option>
<!--          <a-select-option value="HQ">-->
<!--            HQ-->
<!--          </a-select-option>-->
          <a-select-option value="F">
            F
          </a-select-option>
<!--          <a-select-option value="FQ">-->
<!--            FQ-->
<!--          </a-select-option>-->
          <a-select-option value="YY">
            YY
          </a-select-option>
          <a-select-option value="YQ">
            YQ
          </a-select-option>
<!--          <a-select-option value="GS">-->
<!--            GS-->
<!--          </a-select-option>-->
        </a-select>
      </a-form-model-item>
<!--      <a-form-model-item prop='masterName'>-->
<!--        <a-input v-model='form.masterName' style="width: 300px" placeholder="商户负责人" />-->
<!--      </a-form-model-item>-->

      <a-form-model-item prop='id'>
        <a-input v-model='form.id' style="width: 300px" placeholder="订单号" />
      </a-form-model-item>
      <a-form-model-item prop='auditStatus'>
        <a-select v-model="form.auditStatus" placeholder="状态" style="width: 250px" @change="changeAuditStatus">
          <a-select-option :value="0">
            全部
          </a-select-option>
          <a-select-option :value="1">
            待审核
          </a-select-option>
          <a-select-option :value="2">
            已审核
          </a-select-option>
          <!-- <a-select-option :value="3">
            系统审核
          </a-select-option> -->
        </a-select>
      </a-form-model-item>
      <a-form-model-item prop='start'>
        <a-range-picker
          v-model="dateArr"
          :format="[formatType, formatType]"
          :show-time="{
            hideDisabledOptions: true
          }"
          :disabled-date="disabledDate"
          @change="dateChange"
        />
      </a-form-model-item>
      <a-form-model-item>
        <a-button type='primary' @click='handleSubmit'>
          查询
        </a-button>
      </a-form-model-item>
      <a-form-model-item>
        <a-button @click='handleReset'>
          重置
        </a-button>
      </a-form-model-item>
    </a-form-model>
    <div>
      <div class='table-operator' style="margin-top: 20px">
        <a-list :loading="topLoading">
          <a-button type='primary' icon='reload' @click='$refs.table.refresh(true)' style="margin-right: 30px;">刷新</a-button>

          <span style="margin-right: 20px;" v-if="form.auditStatus===1">待审笔数：{{this.culDashboard.total}}</span>
          <span style="margin-right: 20px;" v-if="form.auditStatus===1">待审金额：{{this.culDashboard.ext1}}</span>
          <span style="margin-right: 20px;" v-if="form.auditStatus===1">已用积分：{{this.culDashboard.ext2}}</span>
          <span style="margin-right: 20px;" v-if="form.auditStatus===1">待获得积分：{{this.culDashboard.ext3}}</span>
          <span style="margin-right: 20px;" v-if="form.auditStatus===1">实付金额：{{this.culDashboard.ext4}}</span>

          <span style="margin-right: 20px;" v-if="form.auditStatus===2">已审笔数：{{this.culDashboard.total}}</span>
          <span style="margin-right: 20px;" v-if="form.auditStatus===2">实付金额：{{this.culDashboard.ext4}}</span>
          <span style="margin-right: 20px;" v-if="form.auditStatus===2">已用积分：{{this.culDashboard.ext2}}</span>
          <span style="margin-right: 20px;" v-if="form.auditStatus===2">已获积分：{{this.culDashboard.ext3}}</span>




        </a-list>

      </div>
      <s-table id="table" ref='table' rowKey='id' size='default' :columns='columns' :scroll='{ x: 1500}' :data='loadData'>
          <span slot='purchaseAgreementUrl' slot-scope='text,record'>
            <template v-if="record.purchaseAgreementStatus==0">
                <span class="color-red">待签</span>
            </template>
            <template v-else-if="record.purchaseAgreementStatus==1">
                <a @click='showImg(text)'>查看</a>
            </template>
            <template v-else-if="record.purchaseAgreementStatus==-1">
                无
            </template>
          </span>

          <span slot='orderAgreementUrl' slot-scope='text,record'>
              <template v-if="record.orderAgreementStatus==0">
               <span class="color-red">待签</span>
            </template>
            <template  v-else-if="record.orderAgreementStatus==1">
                <a @click='showImg(text)'>查看</a>
            </template>
            <template  v-else-if="record.orderAgreementStatus==-1">
                无
            </template>
          </span>

        <span slot='id' slot-scope='text, record'>
          <template>
            <a @click='handleEdit(record)'>
              {{ text || "未知" }}
            </a>
          </template>
        </span>
        <span slot='originalMerchantAmount' slot-scope='text,record'>
          <template v-if="record.merchantMember">
            ￥{{text}}
          </template>
          <template v-else>
            ¥{{record.amount}}
          </template>
        </span>
        <span slot='merchantAmount' slot-scope='text, record'>
          <template  v-if="record.merchantMember">
            <span v-if="record.batchSelect===1" style="color: red"> ￥{{text}}</span>
            <span v-else>￥{{text}}</span>
          </template>
          <template v-else>
             <span v-if="record.batchSelect===1" style="color: red"> ￥{{record.consumeAmount}}</span>
              <span v-else>￥{{record.consumeAmount}}</span>
          </template>
        </span>
        <span slot='merchantDiscount' slot-scope='text,record'>
          <template  v-if="record.merchantMember">
            ￥{{text}}
          </template>
          <template v-else>
            ¥{{record.memberDiscount}}
          </template>
        </span>
        <span slot='extraRewardMobile' slot-scope='text'>
          <template>
            {{text && text > 0 ? text : ""}}
          </template>
        </span>

        <span slot='waitRewardIntegral' slot-scope='text,record'>
<!--          <template v-if="form.auditStatus===1">-->
            <template v-if="text===0"><span class="color-red">{{text}}</span></template>
            <template v-else>{{text}}</template>
<!--          </template>-->
<!--          <template v-else>-->
<!--          {{ text }}-->
<!--          </template>-->
        </span>

        <span slot='authCode' slot-scope='text'>
          <template>
            <span v-for="(item, index) in getAuthCodeList(text)" :key="index" :style="{color: index == 1 ? '#1890ff' : ''}">{{item}}</span>
          </template>
        </span>
        <span slot='auditStatus' slot-scope='text'>
          <template>
            {{getAuditStatusText(text)}}
          </template>
        </span>
        <span slot='action' slot-scope='text, record'>
          <!-- <a-space style="margin-right: 20px" v-if="!(record.extraRewardMobile && record.extraRewardMobile > 0)">
            <a @click='initialDeliver1(record)'>添加额外奖励</a>
          </a-space> -->
          <a-popconfirm title="是否要清除此行？" @confirm="handleDelete(record)">
            <a>清除</a>
          </a-popconfirm>
        </span>
      </s-table>
    </div>

    <a-modal title='添加额外奖励' :destroyOnClose='true' :maskClosable='true'
             :visible='deliver1.visible' @cancel='()=>this.deliver1.visible=false'
             ok-text='确认' cancel-text='取消' @ok='handleDeliver1' :confirmLoading='deliver1.loading'>
      <a-form-model ref='form1' :model='deliver1.form' :rules='deliver1.rules' :labelCol='{ span: 6 }' :wrapperCol='{ span: 18 }'>
        <a-form-model-item label='奖励人' prop='type'>
           <a-radio-group name="radioGroup" v-model="deliver1.form.type" @change="onChangeType">
            <a-radio :value="1">
              手机号
            </a-radio>
            <a-radio :value="2">
              商户名称
            </a-radio>
          </a-radio-group>
        </a-form-model-item>
        <template v-if="deliver1.form.type == 1">
          <a-form-model-item label='奖励手机号' prop='extraRewardMobile'>
            <a-input v-model='deliver1.form.extraRewardMobile' @change="handleMobileChange" />
          </a-form-model-item>
          <a-form-model-item label='奖励人姓名' prop='extraRewardName'>
            <a-input disabled v-model='deliver1.form.extraRewardName' />
          </a-form-model-item>
        </template>
        <template v-else>
          <a-form-model-item label='商户名称' prop='extraRewardMerchantName'>
            <a-select
              v-model="deliver1.form.extraRewardMerchantName"
              show-search
              placeholder="请输入商户名称"
              :default-active-first-option="false"
              :show-arrow="false"
              :filter-option="false"
              :not-found-content="null"
              @search="handleNameChange"
              @select="handleSelectMerchant"
            >
              <a-select-option v-for='item in merchantList' :key='item.merchantName'>
                {{ item.merchantName }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label='授权编码' prop='extraRewardAuthCode'>
            <a-input disabled v-model='deliver1.form.extraRewardAuthCode' />
          </a-form-model-item>
        </template>
        <a-form-model-item label='商品折扣' prop='extraRewardQuota'>
          <a-input v-model='deliver1.form.extraRewardQuota' />
        </a-form-model-item>
        <a-form-model-item label='现金折扣' prop='extraRewardCash'>
          <a-input v-model='deliver1.form.extraRewardCash' />
        </a-form-model-item>
      </a-form-model>
    </a-modal>

    <a-modal title='协议预览' :destroyOnClose='true' :maskClosable='true'
             :visible='deliver3.visible' @cancel='()=>this.deliver3.visible=false' :width="800"
             :footer="null">
      <img :src="deliver3.imgUrl" width="100%">

    </a-modal>
  </page-header-wrapper>
</template>

<script>
import { STable, Ellipsis, ChartCard } from '@/components'
import {
  queryMemberOrders,
  removeAuditOrder,
  putOrderExtra,
  getMobileMember,
  getMerchantList,
  getAuditOrderDataV2
} from '@/api/member-order'
import moment from 'moment'
const columns = [
  {
    width: 100,
    title: '订单号',
    dataIndex: 'id',
    scopedSlots: { customRender: 'id' }
  },
  {
    width: 100,
    title: '下单时间',
    dataIndex: 'createTime',
    scopedSlots: { customRender: 'createTime' }
  },
  {
    width: 100,
    title: '审核时间',
    dataIndex: 'auditTime',
    scopedSlots: { customRender: 'auditTime' }
  },
  {
    width: 100,
    title: '购买人',
    dataIndex: 'nickname',
    scopedSlots: { customRender: 'nickname' }
  },
  {
    width: 100,
    title: '购买人手机号',
    dataIndex: 'mobile',
    scopedSlots: { customRender: 'mobile' }
  },
  {
    width: 100,
    title: '推荐人姓名',
    dataIndex: 'spreaderNickname',
    scopedSlots: { customRender: 'spreaderNickname' }
  },
  {
    width: 100,
    title: '运营中心',
    dataIndex: 'servicePresidentName',
    scopedSlots: { customRender: 'servicePresidentName' }
  },
  {
    width: 100,
    title: '应收金额',
    dataIndex: 'originalMerchantAmount',
    scopedSlots: { customRender: 'originalMerchantAmount' }
  },
  {
    width: 100,
    title: '使用积分',
    dataIndex: 'merchantDiscount',
    scopedSlots: { customRender: 'merchantDiscount' }
  },
  {
    width: 100,
    title: '实付金额',
    dataIndex: 'merchantAmount',
    scopedSlots: { customRender: 'merchantAmount' }
  },
  {
    width: 100,
    title: '分公司简称',
    dataIndex: 'memberAreaName',
    scopedSlots: { customRender: 'memberAreaName' }
  },// 分公司名称(分公司名称：areaMasterName)20240424版本
  {
    width: 100,
    title: '获得积分',
    dataIndex: 'waitRewardIntegral',
    key:'waitRewardIntegral',
    scopedSlots: { customRender: 'waitRewardIntegral' }
  },
  {
    width: 100,
    title: '购买协议',
    dataIndex: 'purchaseAgreementUrl',
    scopedSlots: { customRender: 'purchaseAgreementUrl' }
  },
  {
    width: 100,
    title: '订货协议',
    dataIndex: 'orderAgreementUrl',
    scopedSlots: { customRender: 'orderAgreementUrl' }
  },
  {
    width: 100,
    title: '状态',
    dataIndex: 'auditStatus',
    scopedSlots: { customRender: 'auditStatus' }
  },
  {
    width: 150,
    title: '操作',
    fixed: 'right',
    dataIndex: 'action',
    scopedSlots: { customRender: 'action' }
  },
]
const formatType = 'YYYY-MM-DD';
const baseStart = moment().startOf('month').format(formatType);
const baseEnd = moment().format(formatType);

export default {
  name: 'AuditOrderList',
  components: {
    STable, Ellipsis, ChartCard
  },
  data() {
    return {
      // 筛选表单
      topLoading:true,
      showType:0,
      formatType,
      dateArr: [baseStart, baseEnd],
      form: {
        keyword: "",
        auditStatus: 1,
        start: moment(baseStart).startOf('day').format(formatType + " HH:mm"),
        end: moment(baseEnd).endOf('day').format(formatType+ " HH:mm"),
      },
      loading: true,
      dashboard: {}, //订单统计数据
      culDashboard: {}, //订单统计数据
      // 表头
      columns,
      // 加载数据方法 必须为 Promise 对象
      queryParam: {},
      total:0,
      ext4:0,
      ext5:0,
      ext6:0,
      ext7:0,
      ext8:0,
      ext9:0,
      ext10:0,
      loadData: parameter => {
        let myForm = this.form;
        let st = "";
        let ed = "";
        if(myForm.auditStatus===1){
          //设置搜索状态为1：待审批
          this.showType=1;
            st ="1900-01-01 00:00";
            ed="2999-12-31 23:59";
        }
        let parm = Object.assign(parameter, {st:st,ed:ed}, this.form);
        parm.notQueryQuick = 1;//1-不查询 未选购订单，

        if(parm.st!=="" && parm.ed!==""){
          parm.start = parm.st;
          parm.end=parm.ed;
        }
        delete parm.st;
        delete parm.ed;
        parm.noAreaTag = 0;
        return queryMemberOrders(parm).then(datum => {
            // debugger;
            this.culDashboard = datum;
            this.topLoading = false;
            // //已审批
            // this.total = datum.ext5;
            // this.ext4 = datum.ext6;
            // //待审批
            // this.ext5 = datum.ext5;
            // this.ext6 = datum.ext6;
            // this.ext7 = datum.ext7;
            // this.ext8 = datum.ext8;
            // this.ext9 = datum.ext9;
            // this.ext10 = datum.ext10;
            // debugger;
            return datum
          })
      },
      merchantList: [],
      deliver1: { 
        visible: false,
        loading: false,
        form: {}, 
        rules: {
          extraRewardMerchantName: [
            { required: true, message: '请填写商户名称', trigger: 'change' }
          ],
          extraRewardAuthCode: [
            { required: true, message: '商户不存在', trigger: 'change' }
          ],
          extraRewardMobile: [
            { required: true, message: '请填写手机号', trigger: 'change' },
            { pattern: /^1[3456789]\d{9}$/, message: '请填写正确的手机号' }
          ],
          extraRewardName: [
            { required: true, message: '用户不存在', trigger: 'change' }
          ],
          extraRewardQuota: [
            // { required: true, message: '请填写商品折扣', trigger: 'change' },
            { pattern: /^3\d+\/4\d+\/5\d+$/, message: '商品折扣的格式必须为3X/4X/5X，其中X必须为正整数' }
          ],
          // extraRewardCash: [{ required: true, message: '请填写现金折扣', trigger: 'change' }],
        }
      },

      deliver3: { visible: false,loading: false, form: {} },
    }
  },
  created() {
    this.getOrderData();
  },
  methods: {
    changeAuditStatus(){
      let col9 = this.columns[9];
      // debugger;
      if(this.form.auditStatus===1){
        col9.title='待获折扣';
      }else{
        // col9.title='已获积分';
        col9.title='实付金额';
        // this.columns[9].dataIndex='originalMerchantAmount';
      }
      this.handleSubmit();
    },
    moment,
    onChangeType() {
      this.$set(this.deliver1.form, "extraRewardMobile", "");
      this.$set(this.deliver1.form, "extraRewardName", "");
      this.$set(this.deliver1.form, "extraRewardMerchantName", "");
      this.$set(this.deliver1.form, "extraRewardAuthCode", "");
      this.merchantList = [];
    },
    handleSelectMerchant(value) {
      let msg = this.merchantList.find(item => item.merchantName = value);
      if (msg) {
        this.$set(this.deliver1.form, "extraRewardAuthCode", msg.authCode);
        this.$set(this.deliver1.form, "extraRewardMobile", msg.mobile);
      } else {
        this.$set(this.deliver1.form, "extraRewardAuthCode", "");
        this.$set(this.deliver1.form, "extraRewardMobile", "");
      }
    },
    handleNameChange(value){
      if (value) {
        getMerchantList({merchant: value}).then(result => {
          this.merchantList = result;
        })
      }
    },
    handleMobileChange() {
      let mobile = this.deliver1.form.extraRewardMobile;
      if (mobile.length == 11) {
        getMobileMember({phone: mobile}).then(result => {
          this.$set(this.deliver1.form, "extraRewardName", result.nickname);
        })
      } else {
        this.$set(this.deliver1.form, "extraRewardName", "");
      }
    },
    initialDeliver1(record) {
      this.deliver1.form = { id: record.id, type: 1}
      this.deliver1.visible = true
    },
    handleDeliver1() {
      this.$refs.form1.validate((result) => {
        if (result) {
          this.deliver1.loading = true;
          putOrderExtra(this.deliver1.form).then(_result => {
            this.$message.success('操作成功')
            this.$refs.table.refresh(true)
            this.deliver1.visible = false
            this.deliver1.loading = false;
          }).catch(() => {
            this.deliver1.loading = false;
          })
        }
      })
    },
    disabledDate(current) {
      return current && current > moment().endOf('day');
    },
    dateChange() {
      this.form.start =  moment(this.dateArr[0]).startOf('day').format(this.formatType + " HH:mm");
      this.form.end =  moment(this.dateArr[1]).endOf('day').format(this.formatType+ " HH:mm");
    },
    handleReset() {
      this.dateArr = [baseStart, baseEnd];
      this.form.start = moment(baseStart).startOf('day').format(this.formatType + " HH:mm");
      this.form.end = moment(baseEnd).endOf('day').format(this.formatType + " HH:mm");
      this.$refs.form.resetFields()
      this.$refs.table.refresh(true)
    },
    handleSubmit() {
      this.getOrderData();
      this.$refs.table.refresh(true)
    },
    getOrderData() {
      //顶部统计数据
      if(this.form.endTag === "-1"){
        //尾号搜索过滤全部，去掉条件等于搜索全部
        delete this.form.endTag;
      }
      this.topLoading = true;
      let params = Object.assign({}, this.form);
      params.noAreaTag = 0;
      params.notQueryQuick = 1;//1-不查询 未选购订单，
      getAuditOrderDataV2(params).then(result => {
        let totalCount = result.memberOrderCount+result.presidentOrderCount+result.partnerOrderCount+result.rightsOrderCount;
        let totalAmount = result.memberOrderAmount+result.presidentOrderAmount+result.partnerOrderAmount+result.rightsOrderAmount
        result.totalCount = totalCount
        result.totalAmount = totalAmount
        this.dashboard = result;
        this.loading = false;
      })
    },
    handleEdit(record) {
      this.$router.push({ path: '/order/audit/edit', query: { id: record.id } })
    },
    handleDelete(record) {
      removeAuditOrder({ id: record.id }).then(result => {
        this.$message.success('操作成功')
        this.$refs.table.refresh(true)
      })
    },
    getAuditStatusText(status) {
      if (status == 1) {
        return "待审核"
      } else if (status == 2) {
        return "已审核"
      } else if (status == 3) {
        return "已审核"
      } else if (status == 4) {
        return "已删除"
      } else {
        return "未知"
      }
    },
    getAuthCodeList(authCode) {
        if (authCode) {
          let index = authCode.indexOf("-");
          if (index < 0) {
            index = authCode.length - 1;
          } else {
            index--;
          }
          let ownAuthCode = authCode.slice(index - 3, index + 1);
          if (ownAuthCode) {
            const authCodeList = authCode.split(ownAuthCode);
            authCodeList.splice(1, 0, ownAuthCode);
            return authCodeList;
          }
        }
        return [];
    },
    toDiscount() {
      this.$router.push("/order/audit/prodDiscount/list");
    },
    toMonthCash() {
      this.$router.push("/order/audit/month_cash/list");
    },
    toCash() {
      this.$router.push("/order/audit/cashDiscount/list");
    },
    getMonthFinalDay(year,month){
      let day='';
      if(year == null || year===''){
        year = new Date().getFullYear();
      }
      if(month == null || month===''){
        month = new Date().getMonth()+1;
      }
      day = new Date(new Date(year,month).setDate(0)).getDate();
      return year+"-"+month+"-"+day;

    },
    showImg(url){
      this.deliver3.visible=true;
      this.deliver3.imgUrl = url;
    },
    getDataByMonth(tabs){
      let start = "";
      let end = "";
      if(tabs === "1"){
        //本月
        let year = new Date().getFullYear();
        let month = new Date().getMonth()+1;
        end = this.getMonthFinalDay(year,month);
        start = year + "-" + month + "-" + "01";
      }else{
        //上月
        let lastMonth = this.getLastMonth();
        let monthYear = lastMonth.split("-");
        let year = monthYear[0];
        let month = monthYear[1];
        end = this.getMonthFinalDay(year,month);
        start = year + "-" + month + "-" + "01";
      }
      end = end + " 23:59";
      start = start + " 00:00";
      let arr={};
      arr.start=start;
      arr.end =end;
      return arr;
    }
  }
}
</script>
<style lang="less" scoped>
/deep/.order_count .ant-card-body {
  padding: 16px 24px 10px !important;
  display: flex;
  flex-wrap: wrap;
}
.card_title {
    width: 100%;
    color: rgba(0, 0, 0, 0.45);
    font-size: 14px;
    line-height: 22px;
    display: flex;
}
.card_content {
  width: 100%;
  font-size: 18px;
  line-height: 30px;
  height: 30px;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  white-space: nowrap;
  margin-top: 4px;
  color: #000;
  .card_content_title {
    color: rgba(0, 0, 0, 0.45);
    font-size: 14px;
  }
}
.order_count .card_content {
  width: 50%;
}
.tableHiddle {
  display: none;
}
.tableShow{
  display: revert;
}

/deep/.ant-card-body{
  padding: 15px;
}
//
///deep/ .ant-descriptions-row > td {
//  padding-bottom: 1px;
//}
///deep/.ant-descriptions-item-label{
//  padding: 10px 5px;
//}
</style>
