<template>
  <page-header-wrapper style="margin: unset;">
    <a-form-model ref='form' :model='form' layout='inline'>
      <a-form-model-item prop='orderType'>
        <a-select v-model="form.orderType" placeholder="订单分类" style="width: 250px">
          <a-select-option value="">
            全部
          </a-select-option>
          <a-select-option value="32">
            会员订单
          </a-select-option>
          <a-select-option value="11">
            权益订单
          </a-select-option>
          <a-select-option value="1">
            原价订单
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item prop='bussType'>
        <a-select v-model="form.bussType" placeholder="发货方式" style="width: 250px">
          <a-select-option value="">
            发货方式
          </a-select-option>
          <a-select-option value="deliver">
            物流发货
          </a-select-option>
          <a-select-option value="draw">
            自提现货
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item prop='id'>
        <a-input v-model='form.id' style="width: 300px" placeholder="订单号" />
      </a-form-model-item>
      <a-form-model-item prop='masterName'>
        <a-input v-model='form.masterName' style="width: 300px" placeholder="商户负责人" />
      </a-form-model-item>
      <a-form-model-item prop='addressUserName'>
        <a-input v-model='form.addressUserName' style="width: 350px" placeholder="收货人" />
      </a-form-model-item>
<!--      <a-form-model-item prop='addressUserMobile'>-->
<!--        <a-input v-model='form.addressUserMobile' style="width: 350px" placeholder="手机号" />-->
<!--      </a-form-model-item>-->
      <a-form-model-item prop='outStatus'>
        <a-select v-model="form.outStatus" placeholder="状态" style="width: 250px">
          <a-select-option value="">
            全部
          </a-select-option>
          <a-select-option :value="1">
            待完成
          </a-select-option>
          <a-select-option :value="2">
            已完成
          </a-select-option>
          <a-select-option :value="3">
            待核销
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item prop='start'>
        <a-range-picker
          v-model="dateArr"
          :format="[formatType, formatType]"
          :show-time="{
            hideDisabledOptions: true,
          }"
          :disabled-date="disabledDate"
          @change="dateChange"
        />
      </a-form-model-item>
      <a-form-model-item>
        <a-button type='primary' @click='handleSubmit'>
          查询
        </a-button>
      </a-form-model-item>
      <a-form-model-item>
        <a-button @click='handleReset'>
          重置
        </a-button>
      </a-form-model-item>
    </a-form-model>
    <div>
      <div class='table-operator' style="margin-top: 20px">
        <a-button type='primary' icon='reload' @click='$refs.table.refresh(true)'>刷新</a-button>
        <a-button type='primary' icon='delete' @click='handleDeleteAll'>删除</a-button>
        <a-button type='primary' icon='table' @click='exportToExcel'>导出订单</a-button>
        <!-- <a-button type='primary' icon='swap' @click='initialDeliver2'>订单转移</a-button> -->
        <span>物流金额：￥{{dashboard.logisticsAmount}}</span>
      </div>

      <s-table id="table" ref='table' rowKey='id' size='default' :columns='columns' :scroll='{ x: 1500}' :data='loadData' :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }">
<!--        <span slot='purchaseAgreementUrl' slot-scope='text,record'>-->
<!--          <template v-if="record.purchaseAgreementStatus==0">-->
<!--              待签-->
<!--          </template>-->
<!--          <template v-else-if="record.purchaseAgreementStatus==1">-->
<!--              <a @click='showImg(text)'>查看</a>-->
<!--          </template>-->
<!--          <template v-else-if="record.purchaseAgreementStatus==-1">-->
<!--              无-->
<!--          </template>-->
<!--        </span>-->

<!--        <span slot='orderAgreementUrl' slot-scope='text,record'>-->
<!--            <template v-if="record.orderAgreementStatus==0">-->
<!--              待签-->
<!--          </template>-->
<!--          <template  v-else-if="record.orderAgreementStatus==1">-->
<!--              <a @click='showImg(text)'>查看</a>-->
<!--          </template>-->
<!--          <template  v-else-if="record.orderAgreementStatus==-1">-->
<!--              无-->
<!--          </template>-->
<!--        </span>-->

        <span slot='orderType' slot-scope='text, record'>
            <template v-if="getOrderType(record)=='1'">
              原价单品订单
            </template>
            <template v-if="getOrderType(record)=='11'">
              权益单品订单
            </template>
            <template v-if="getOrderType(record)=='32'">
              会员订单
            </template>

        </span>

        <span slot='addressUserName' slot-scope='text, record'>
            <template v-if="record.bussType=='deliver'">
             {{text }}
            </template>
            <template v-else>
             {{record.drawName}}
            </template>

        </span>

        <span slot='addressUserMobile' slot-scope='text, record'>
            <template v-if="record.bussType=='deliver'">
             {{text }}
            </template>
            <template v-else>
             {{record.drawMobile}}
            </template>

        </span>


        <span slot='id' slot-scope='text, record'>
           <a @click='handleEdit(record)' >
                  {{ text }}
           </a>
<!--            <template v-if="record.orderType==1||record.orderType==11">-->
<!--                 <a @click='handleEdit(record)' >-->
<!--                  {{ text }}-->
<!--                </a>-->
<!--            </template>-->

<!--            <template v-else>-->
<!--              {{ text }}-->
<!--            </template>-->


        </span>
        <span slot='orderAmount' slot-scope='text,record'>
          <template v-if="record.status == 'finished'">
            {{text}}
          </template>
        </span>
        <span slot='bussType' slot-scope='text,record'>
          <template v-if="text == 'deliver'">
            {{record.regionOneName}} {{record.regionTwoName}} {{record.regionThreeName}} {{record.address}}
          </template>
          <template v-else>
            自提现货：{{record.depositoryName}}
          </template>
        </span>
        <span slot='bussType1' slot-scope='text,record'>
          <template v-if="record.bussType == 'deliver'">
            物流发货
          </template>
          <template v-else>
            自提现货
          </template>
        </span>
        <span slot='status' slot-scope='text, record'>
          <template>
            <template v-if="record.checkStatus=='1'">
                    <a-badge dot v-if='text=="paying"' status='processing' :text='"待核销"' />
            </template>
            <template v-else>
                      <a-badge dot v-if='text=="paying"' status='processing' :text='"待支付"' />
                      <a-badge dot v-else-if='text=="delivering"' status='processing' :text='record.bussType == "draw" ? "待提货" : "待发货"' />
                      <a-badge dot v-else-if='text=="receiving"' status='warning' :text='"待收货"' />
                      <a-badge dot v-else-if='text=="finished"' status='success' :text='"已完成"' />
                      <a-badge dot v-else-if='text=="closed"' status='error' :text='"已取消"' />
                      <a-badge dot v-else status='default' :text='"其他"' />
            </template>

          </template>
        </span>
<!--        <span slot='paymentMethod' slot-scope='text'>-->
<!--          <template>-->
<!--            <a-badge dot v-if='text=="money"' status='processing' :text='"微信"' />-->
<!--            <a-badge dot v-else-if='text=="trans"' status='warning' :text='"转账"' />-->
<!--            <a-badge dot v-else-if='text=="cash"' status='warning' :text='"现金"' />-->
<!--            <a-badge dot v-else status='default' :text='"其他"' />-->
<!--          </template>-->
<!--        </span>-->
        <span slot='action' slot-scope='text, record'>
          <template>
            <a-space>
              <a @click='initialDeliverFirst(record)'>打印出库单</a>
              <a v-if='record.status=="paying"' @click='handleCancel(record)'>取消订单</a>
<!--              <a v-if='(record.orderType=="1"||record.orderType=="11"||record.orderType=="3"||record.orderType=="5" ||record.orderType=="32") && record.bussType=="deliver" && record.status=="delivering"' @click='initialDeliver(record)'>确认发货</a>-->

              <a  v-if="record.status=='delivering'" @click='initialDeliver(record)'>确认发货</a>
              <a v-if='(getOrderType(record)!="32") && record.bussType=="deliver" && (!record.logisticsProvider ||!record.logisticsAmount ||!record.logisticsNo)' @click='initialDeliver1(record)'>
                    物流金额
              </a>
              <a-popconfirm title='确定删除吗？' ok-text='确认' cancel-text='取消'
                            @confirm='handleDelete(record)'>
                <a href='#'>删除</a>
              </a-popconfirm>
            </a-space>
          </template>
        </span>
      </s-table>
    </div>

    <a-modal title='确认发货' :destroyOnClose='true' :maskClosable='true'
             :visible='deliver.visible' @cancel='()=>this.deliver.visible=false'
             ok-text='确认' cancel-text='取消' @ok='handleDeliver' :confirmLoading='deliver.loading' :width="800">
      <a-form-model ref='form' :model='deliver.form' :rules='rules' :labelCol='{ span: 4 }' :wrapperCol='{ span: 20 }'>
        <a-form-model-item label='总需发货'>
          <s-table ref='table1' rowKey='id' size='default' :columns='columns1' :data='loadData1'>
            <span slot='stitle' slot-scope='text'>
              <template>
                  <Ellipsis :lines='2' tooltip :length='20'>{{ text }}</Ellipsis>
              </template>
            </span>
          </s-table>
        </a-form-model-item>
        <a-form-model-item label='订单号'>
          {{deliver.form.id}}
        </a-form-model-item>
        <template v-if="deliver.form.address">
          <a-form-model-item label='收货人'>
            {{deliver.form.name}}
          </a-form-model-item>
          <a-form-model-item label='联系电话'>
            {{deliver.form.mobile}}
          </a-form-model-item>
          <a-form-model-item label='详细地址'>
            {{ deliver.form.regionOneName }}
            {{ deliver.form.regionTwoName }}
            {{ deliver.form.regionThreeName }}
            {{ deliver.form.address }}
          </a-form-model-item>
        </template>

      </a-form-model>
    </a-modal>

    <a-modal title='物流金额' :destroyOnClose='true' :maskClosable='true'
            :visible='deliver1.visible' @cancel='()=>this.deliver1.visible=false'
            ok-text='确认' cancel-text='取消' @ok='handleDeliver1' :confirmLoading='deliver1.loading' :width="800">
      <a-form-model ref='form1' :model='deliver1.form' :rules='deliver1.rules' :labelCol='{ span: 4 }' :wrapperCol='{ span: 20 }'>
        <a-form-model-item label='物流名称' prop='logisticsProvider'>
          <a-select v-model="deliver1.form.logisticsProvider" placeholder="请选择">
            <a-select-option :value="item" v-for="(item, index) in expressList" :key="index">
              {{item}}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label='物流单号' prop='logisticsNo'>
          <a-input v-model='deliver1.form.logisticsNo' />
        </a-form-model-item>
        <a-form-model-item label='物流金额' prop='logisticsAmount'>
          <a-input v-model='deliver1.form.logisticsAmount' />
        </a-form-model-item>
        <a-form-model-item label='付费方式' prop='logisticsMethod'>
          <a-radio-group name="radioGroup" v-model="deliver1.form.logisticsMethod">
            <a-radio :value="1">
              到付
            </a-radio>
            <a-radio :value="2">
              寄付
            </a-radio>
          </a-radio-group>
        </a-form-model-item>
      </a-form-model>
    </a-modal>

    <a-modal title='订单转移' :destroyOnClose='true' :maskClosable='true'
            :visible='deliver2.visible' @cancel='()=>this.deliver2.visible=false'
            ok-text='确认' cancel-text='取消' @ok='handleDeliver2' :confirmLoading='deliver2.loading' :width="800">
      <a-form-model ref='form2' :model='deliver2.form' :rules='deliver2.rules' :labelCol='{ span: 4 }' :wrapperCol='{ span: 20 }'>
        <a-form-model-item label='订单号' prop='id'>
          <a-input v-model='deliver2.form.id' />
        </a-form-model-item>
        <a-form-model-item label='被转移手机号' prop='newMobile'>
          <a-input v-model='deliver2.form.newMobile' />
        </a-form-model-item>
        <a-form-model-item label='被转移人姓名' prop='newName'>
          <a-input v-model='deliver2.form.newName' />
        </a-form-model-item>
        <a-form-model-item label='订单转移原因' prop='reason'>
          <a-input v-model='deliver2.form.reason' />
        </a-form-model-item>
        <a-form-model-item label='备注'>  
          转移订单仅变更下单人，名额、业绩、归属商户不变
        </a-form-model-item>
      </a-form-model>
    </a-modal>

    <a-modal title='协议预览' :destroyOnClose='true' :maskClosable='true'
             :visible='deliver3.visible' @cancel='()=>this.deliver3.visible=false' :width="800"
             :footer="null">
          <img :src="deliver3.imgUrl" width="100%">

    </a-modal>

    <a-modal  :destroyOnClose='true' :maskClosable='true' :footer = "null"
              :visible='deliver4.visible'
              @cancel='()=>this.deliver4.visible=false'
              @ok='()=>this.deliver4.visible=false'
              cancel-text='关闭'
              :confirmLoading='deliver4.loading'
              width="99%"
    >
      <!--        <a-button type='primary' @click='exportImg(order)'>-->
      <!--          导出-->
      <!--        </a-button>-->

      <div id="printContainer" >
        <div style="margin: 20px">
          <template >
            <a-descriptions title="商品物流出库发货单" class="diyTableStyle_no_Border" style="text-align: center">
            </a-descriptions>
            <a-descriptions :column="4" class="diyTableStyle_no_Border">
              <a-descriptions-item label="订单号" :span="3">{{ deliver4.form.id }}</a-descriptions-item>
              <a-descriptions-item label="下单时间" :contentStyle="{textAlign:'right'}">{{ deliver4.form.showCreateTime }}</a-descriptions-item>
            </a-descriptions>
            <a-descriptions bordered  :column="2"   class="diyTableStyle">
              <a-descriptions-item label="订单商品" :span="2" >{{ deliver4.form.depositoryProductionDesc }}</a-descriptions-item>


              <a-descriptions-item label="收货人" >{{ deliver4.form.addressUserName }}</a-descriptions-item>
              <a-descriptions-item label="联系电话" >{{ deliver4.form.showAddressUserMobile }}</a-descriptions-item>

              <a-descriptions-item label="收货地址" :span="2">
                {{ address.regionOneName }}
                {{ address.regionTwoName }}
                {{ address.regionThreeName }}
                {{ address.address }}
              </a-descriptions-item>

              <a-descriptions-item label="卖方" :span="2">{{ deliver4.form.areaName }}</a-descriptions-item>

              <a-descriptions-item label="物流公司" >{{ deliver4.form.logisticsProvider }}</a-descriptions-item>
              <a-descriptions-item label="物流单号" >{{ deliver4.form.logisticsNo }}</a-descriptions-item>

              <a-descriptions-item label="物流金额" >寄付月结</a-descriptions-item>
              <!--                    <a-descriptions-item label="物流金额" >{{ address.logisticsAmount }}</a-descriptions-item>-->
              <a-descriptions-item label="出库时间" >{{ deliver4.form.sendTime }}</a-descriptions-item>
            </a-descriptions>

            <a-descriptions :column="8" class="diyTableStyle_no_Border">
              <a-descriptions-item label="打印人员" :span="5">{{ deliver4.form.opUserName }}</a-descriptions-item>
              <a-descriptions-item label="打印时间" >{{ this.printTime }}</a-descriptions-item>
            </a-descriptions>
          </template>
        </div>
      </div>
    </a-modal>

    <a-modal  :destroyOnClose='true' :maskClosable='true' :footer = "null"
              :visible='deliver5.visible' @cancel='()=>this.deliver5.visible=false' @ok='()=>this.deliver5.visible=false'
              cancel-text='关闭'  :confirmLoading='deliver5.loading'
              width="99%">
      <template>
        <a-descriptions title="商品提现货出库单" class="diyTableStyle_no_Border" style="text-align: center;">
        </a-descriptions>
        <a-descriptions class="diyTableStyle_no_Border" :column="4">
          <a-descriptions-item label="订单号" :span="3">{{ deliver5.form.id }}</a-descriptions-item>
          <a-descriptions-item label="下单时间" >{{ deliver5.form.showCreateTime }}</a-descriptions-item>
        </a-descriptions>
        <a-descriptions bordered :column="2" class="diyTableStyle">
          <a-descriptions-item label="订单商品" :span="2" >{{ deliver5.form.depositoryProductionDesc }}</a-descriptions-item>

          <!--          <a-descriptions-item label="下单时间" >{{ order.createTime }}</a-descriptions-item>-->
          <!--          <a-descriptions-item v-if="order.orderType =='1'" label="支付时间" >{{ order.auditTime }}</a-descriptions-item>-->
          <!--          <a-descriptions-item v-if="order.orderType =='2'" label="支付时间" >{{ order.merchantPaymentTime }}</a-descriptions-item>-->

          <a-descriptions-item label="提货人" >{{ deliver5.form.drawName }}</a-descriptions-item>

          <a-descriptions-item label="提货人手机号" >{{ deliver5.form.showdrawMobile }}</a-descriptions-item>

          <a-descriptions-item label="卖方" :span="2">{{ deliver5.form.areaName }}</a-descriptions-item>

          <a-descriptions-item label="出库人" >{{ deliver5.form.outMemberName }}</a-descriptions-item>
          <a-descriptions-item label="出库时间" >{{ deliver5.form.sendTime }}</a-descriptions-item>


          <a-descriptions-item label="提货人签字确认"></a-descriptions-item>
          <a-descriptions-item label="日期"></a-descriptions-item>
        </a-descriptions>

        <a-descriptions :column="8" class="diyTableStyle_no_Border">
          <a-descriptions-item label="打印人员" :span="5">{{ deliver5.form.opUserName }}</a-descriptions-item>
          <a-descriptions-item label="打印时间" >{{ this.printTime }}</a-descriptions-item>
        </a-descriptions>
      </template>
    </a-modal>


  </page-header-wrapper>
</template>

<script>
import { STable, Ellipsis, ChartCard } from '@/components'
import {
  queryMemberOrders,
  deliverMemberOrder,
  putMemberOrderAddress,
  cancelMemberOrder,
  getMemberOrderAddress,
  getMemberOrderProductions,
  deleteMemberOrder,
  getDepositoryStatistics,
  putMemberOrderTransfer,
  queryMemberOrdersNew, getMemberOrder
} from '@/api/member-order'
import { mapGetters } from 'vuex'
import FileSaver from 'file-saver'
import XLSX from 'xlsx'
import moment from 'moment'


const columns = [
  {
    width: 100,
    title: '订单分类',
    dataIndex: 'orderType',
    scopedSlots: { customRender: 'orderType' }
  },
  {
    width: 100,
    title: '订单号',
    dataIndex: 'id',
    scopedSlots: { customRender: 'id' }
  },
  {
    width: 100,
    title: '下单时间',
    dataIndex: 'createTime',
    scopedSlots: { customRender: 'createTime' }
  },
  {
    width: 100,
    title: '商品',
    dataIndex: 'orderProductionDesc',
    scopedSlots: { customRender: 'orderProductionDesc' }
  },
  {
    width: 100,
    title: '出库时间',
    dataIndex: 'sendTime',
    scopedSlots: { customRender: 'sendTime' }
  },
  {
    width: 100,
    title: '商户负责人',
    dataIndex: 'masterName',
    scopedSlots: { customRender: 'masterName' }
  },
  {
    width: 80,
    title: '订单金额',
    dataIndex: 'amount',
    scopedSlots: { customRender: 'amount' }
  },
  {
    width: 100,
    title: '收货人',
    dataIndex: 'addressUserName',
    scopedSlots: { customRender: 'addressUserName' }
  },
  {
    width: 100,
    title: '收货人手机号',
    dataIndex: 'addressUserMobile',
    scopedSlots: { customRender: 'addressUserMobile' }
  },
  // {
  //   width: 100,
  //   title: '购买协议',
  //   dataIndex: 'purchaseAgreementUrl',
  //   scopedSlots: { customRender: 'purchaseAgreementUrl' }
  // },
  // {
  //   width: 100,
  //   title: '订货协议',
  //   dataIndex: 'orderAgreementUrl',
  //   scopedSlots: { customRender: 'orderAgreementUrl' }
  // },
  {
    width: 80,
    title: '发货方式',
    dataIndex: 'bussType1',
    scopedSlots: { customRender: 'bussType1' }
  },
  {
    width: 80,
    title: '状态',
    dataIndex: 'status',
    scopedSlots: { customRender: 'status' }
  },

  {
    width: 200,
    title: '操作',
    dataIndex: 'action',
    scopedSlots: { customRender: 'action' }
  }
]

const exportColums = [
  {
    width: 120,
    title: '订单号',
    dataIndex: 'id',
    scopedSlots: { customRender: 'id' }
  },
  {
    width: 150,
    title: '账户名称',
    dataIndex: 'bankAccountName',
    scopedSlots: { customRender: 'bankAccountName' }
  },
  {
    width: 150,
    title: '付款时间',
    dataIndex: 'merchantPaymentTime',
    scopedSlots: { customRender: 'merchantPaymentTime' }
  },
  {
    width: 150,
    title: '下单时间',
    dataIndex: 'createTime',
    scopedSlots: { customRender: 'createTime' }
  },
  {
    width: 100,
    title: '收货人',
    dataIndex: 'addressUserName',
    scopedSlots: { customRender: 'addressUserName' }
  },
  {
    width: 150,
    title: '发货方式',
    dataIndex: 'bussType',
    scopedSlots: { customRender: 'bussType' }
  },
  {
    width: 150,
    title: '联系电话',
    dataIndex: 'addressUserMobile',
    scopedSlots: { customRender: 'addressUserMobile' }
  },
  {
    width: 100,
    title: '商品内容',
    dataIndex: 'orderProductionDesc',
    scopedSlots: { customRender: 'orderProductionDesc' }
  },
  {
    width: 100,
    title: '物流金额',
    dataIndex: 'logisticsAmount',
    scopedSlots: { customRender: 'logisticsAmount' }
  },
  {
    width: 150,
    title: '商户负责人',
    dataIndex: 'masterName',
    scopedSlots: { customRender: 'masterName' }
  },
  {
    width: 150,
    title: '支付方式',
    dataIndex: 'merchantPaymentMethodName',
    scopedSlots: { customRender: 'merchantPaymentMethodName' }
  },
  {
    width: 150,
    title: '录单人',
    dataIndex: 'operatorNickname',
    scopedSlots: { customRender: 'operatorNickname' }
  },
  {
    width: 150,
    title: '实付金额',
    dataIndex: 'merchantAmount',
    scopedSlots: { customRender: 'merchantAmount' }
  },
  {
    width: 150,
    title: '订单留言',
    dataIndex: 'remark',
    scopedSlots: { customRender: 'remark' }
  },
]

const columns1 = [
  {
    width: 150,
    title: '产品名称',
    dataIndex: 'title',
    scopedSlots: { customRender: 'stitle' }
  },
  {
    width: 100,
    title: '购买数量',
    dataIndex: 'quantity',
    scopedSlots: { customRender: 'quantity' }
  },
]

const formatType = 'YYYY-MM-DD HH:mm';
const baseStart = moment().startOf('month').format(formatType);
// const baseEnd = moment().format(formatType);
const baseEnd = moment().startOf('day').format('YYYY-MM-DD')+ ' 23:59';

export default {
  name: 'InsideStorehouseOrderList',
  components: {
    STable, Ellipsis, ChartCard
  },
  data() {
    return {
      printTime:'',
      dashboard: {},
      address: {},
      selectedRowKeys: [],
      // 筛选表单
      formatType,
      dateArr: [baseStart, baseEnd],
      form: {
        keyword: "",
        bussType: "",
        outStatus: 1,
        start: baseStart,
        end: baseEnd,
        merchantOrderStatus: 2,
        // depositoryTag: 0,
      },
      loading: true,
      total: 0,
      // 表头
      columns,
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        const form = Object.assign({}, this.form);

        // switch (Number(form.customStatus)) {
        //   case 1:
        //     form.statusIn = ["delivering"];
        //     form.depositoryTag = 0;
        //     delete form.start;
        //     delete form.end;
        //     break;
        //   case 4:
        //     form.statusIn = [ "receiving", "finished"];
        //     form.depositoryTag = 1;
        //     break;
        //   default:
        //     delete form.statusIn;
        //     form.depositoryTag = 0;
        //     break;
        // }
        // if (form.customStatus == 10) {
        //   delete form.statusIn;
        //   form.depositoryTag = 0;
        //   form.checkStatus = 1;
        // } else {
        //   delete form.checkStatus
        // }

        this.getDashboard(form);
        let params = Object.assign(parameter, form);
        // params.notQueryQuick = 1;//1-不查询 未选购订单，

        return queryMemberOrdersNew(params)
          .then(datum => {
            this.total = datum.total;
            this.$emit("setOrderTitle", this.total);
            return datum;
          })
      },
      rules: {
        logisticsNo: [{ required: true, message: '请填写物流单号', trigger: 'change' }],
        logisticsProvider: [{ required: true, message: '请填写物流名称', trigger: 'change' }],
        logisticsAmount: [{ required: true, message: '请填写物流金额', trigger: 'change' }]
      },
      houseList: [],
      prodList: [],
      staffList: [],
      deliver: { visible: false,loading: false, form: {} },
      deliver1: { visible: false,loading: false, form: {},
        rules: {
          logisticsNo: [{ required: true, message: '请填写物流单号', trigger: 'change' }],
          logisticsProvider: [{ required: true, message: '请填写物流名称', trigger: 'change' }],
          logisticsAmount: [{ required: true, message: '请填写物流金额', trigger: 'change' }]
        }, 
      },
      deliver2: { visible: false,loading: false, form: {},
        rules: {
          id: [{ required: true, message: '请填写订单号', trigger: 'change' }],
          newMobile: [{ required: true, message: '请填写被转移人手机', trigger: 'change' }],
          newName: [{ required: true, message: '请填写被转移人姓名', trigger: 'change' }],
          reason: [{ required: true, message: '请填写订单转移原因', trigger: 'change' }],
        }, 
      },
      deliver3: { visible: false,loading: false, form: {} },
      deliver4: {
        visible: false,
        loading: false,
        form:{},
      },
      deliver5: {
        visible: false,
        loading: false,
        form:{},
      },
      columns1,
      loadData1: parameter => {
        let queryParam = {};
        if (this.deliver.form.id) {
          queryParam.order = this.deliver.form.id;
        }
        return getMemberOrderProductions(Object.assign(parameter, queryParam))
          .then(datum => {
            return datum
          })
      },
    }
  },
  computed: {
    ...mapGetters(["expressList"])
  },
  created() {},
  methods: {
    moment,
    showImg(url){
        this.deliver3.visible=true;
        this.deliver3.imgUrl = url;
    },

    disabledDate(current) {
      return current && current > moment().endOf('day');
    },
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys;
    },
    handleDeleteAll() {
      const requestList = [];
      this.selectedRowKeys.forEach(item => {     
         requestList.push(deleteMemberOrder({ id: item }))
      });
      if (requestList.length == 0) {
        this.$message.warning('至少选择一个订单');
        return;
      }
      Promise.all(requestList).then(result => {
        this.$message.success('操作成功');
        this.handleInitial();
      })

    },
    filter(inputValue, path) {
      return path.some(option => option.name.toLowerCase().indexOf(inputValue.toLowerCase()) > -1);
    },
    handleChange(e) {
      if (e) {
        let msg = this.houseList.find(item => item.id == e)
        if (msg) {
          this.deliver.form.depositoryName = msg.depositoryName;
        }
      } else {
        this.deliver.form.depositoryName = "";
      }
    },
    dateChange() {
      this.form.start =  this.dateArr[0] ? moment(this.dateArr[0]).format(this.formatType) : "";
      this.form.end =  this.dateArr[1] ? moment(this.dateArr[1]).format(this.formatType) : "";
    },
    handleReset() {
      this.dateArr = [baseStart, baseEnd];
      this.form.start = baseStart;
      this.form.end = baseEnd;
      this.$refs.form.resetFields()
      this.$refs.table.refresh(true)
    },
    handleSubmit() {
      this.$refs.table.refresh(true)
    },
    exportToExcel () {
      this.columns = exportColums
      this.$nextTick(() => {
        let et = XLSX.utils.table_to_book(document.querySelector('#table'),{ raw: true }); //此处传入table的DOM节点
        let etout = XLSX.write(et, {
          bookType: 'xlsx',
          bookSST: true,
          type: 'array'
        });
        try {
            FileSaver.saveAs(new Blob([etout], {
                type: 'application/octet-stream'
            }), '普通订单.xlsx');   //trade-publish.xlsx 为导出的文件名
        } catch (e) {
            console.log(e, etout) ;
        }
        this.columns = columns
        return etout;
      })
    },
    handleDetail() {
      this.$router.push({ path: '/inside/storehouse/detail'})
    },
    handleEdit(record) {
      this.$router.push({ path: '/inside/storehouse/order', query: { id: record.id } })
    },
    handleCancel(record) {
      cancelMemberOrder({ id: record.id }).then(result => {
        this.$message.success('操作成功')
        this.$refs.table.refresh(true)
      })
    },
    handleDelete(record) {
      deleteMemberOrder({ id: record.id }).then(result => {
        this.$message.success('操作成功')
        this.$refs.table.refresh(true)
      })
    },
    initialDeliver2() {
        this.deliver2.form = {};
        this.deliver2.visible = true
    },
    handleDeliver2() {
      this.$refs.form2.validate((result) => {
        if (result) {
          this.deliver2.loading = true;
          putMemberOrderTransfer(this.deliver2.form).then(_result => {
            this.$message.success('操作成功')
            this.$refs.table.refresh(true)
            this.deliver2.visible = false
            this.deliver2.loading = false;
          }).catch(() => {
            this.deliver2.loading = false;
          })
        }
      })
    },
    initialDeliver1(record) {
      // let logisticsProvider =record.logisticsProvider;
        this.deliver1.form = {
          order:record.id,
          id: record.orderAddressId,
          logisticsMethod: record.logisticsMethod||2,
          logisticsAmount:record.logisticsAmount,
          logisticsNo:record.logisticsNo,
          logisticsProvider:record.logisticsProvider,
        };
        // debugger
        this.deliver1.visible = true
    },
    handleDeliver1() {
      this.$refs.form1.validate((result) => {
        if (result) {
          this.deliver1.loading = true;
          let params = Object.assign(this.deliver1.form);
          putMemberOrderAddress(params).then(_result => {
            this.$message.success('操作成功')
            this.$refs.table.refresh(true)
            this.deliver1.visible = false
            this.deliver1.loading = false;
          }).catch(() => {
            this.deliver1.loading = false;
          })
        }
      })
    },
    initialDeliver(record) {
      getMemberOrderAddress({ order: record.id }).then(result => {

        // this.deliver.form = { id: record.id, address: Object.assign({}, this.deliver.form.address, result)}
        let params = Object.assign({id: record.id}, result);
        params.id = record.id;
        this.deliver.form = params
        this.deliver.visible = true
        this.$nextTick(() => {
          this.$refs.table1.refresh(true);
        })
      })
    },
    handleDeliver() {
      this.$refs.form.validate((result) => {
        if (result) {
          this.deliver.loading = true;
          let params = Object.assign(this.deliver.form)
          deliverMemberOrder(params).then(_result => {
            this.$message.success('操作成功')
            this.$refs.table.refresh(true)
            this.deliver.visible = false
            this.deliver.loading = false;
          }).catch(() => {
            this.deliver.loading = false;
          })
        }
      })
    },
    getDashboard(form) {
      let params = Object.assign(form);
      params.notQueryQuick = 1;//1-不查询 未选购订单，
      getDepositoryStatistics(params).then(result => {
        this.dashboard = result;
      })
    },
    initialDeliverFirst(ord){
      let params = {
        id: ord.id
      }
      getMemberOrder(params).then(order => {
        debugger
        if(order.bussType=='deliver'){
          this.initialPrintDeliver(order);
        }else{
          this.initialPrintDraw(order);
        }
          getMemberOrderAddress({ order: order.id }).then(result => {
            this.address = Object.assign({}, this.address, result);
          });
      });

    },
    //物流打印
    initialPrintDeliver(order) {

      let merchantMember = order.merchantMember;
      let areaName = "";

      // 1）用户支付给商户，商户再支付给公司，卖方显示商户所属分公司名称；（有merchantMember）取merchantAreaName;
      // 2）用户/备选/商户下单直接支付给公司的，卖方显示自己所属分公司名称；（没有merchantMember）取memberAreaName;
      if(merchantMember){
        areaName = order.merchantAreaName;
      }else{
        areaName = order.memberAreaName;
      }
      let createTime = order.createTime;
      let addressUserMobile = order.addressUserMobile;
      let tempString = addressUserMobile.toString().substring(0,8)+"***";

      order.showCreateTime = this.getCurrentTime(createTime);
      order.showAddressUserMobile =tempString;
      order.areaName = areaName;

      this.printTime =moment().format(formatType);
      this.deliver4.form = Object.assign(order);
      this.deliver4.loading = false
      this.deliver4.visible = true
    },
    //自提打印
    initialPrintDraw(order) {
      let createTime = order.createTime;
      let drawMobile = order.drawMobile;
      if(drawMobile && drawMobile.length>8 ){
        order.showdrawMobile =drawMobile.toString().substring(0, 8) + "***";
      }else{
        order.showdrawMobile =drawMobile;
      }

      let merchantMember = order.merchantMember;
      let areaName = "";

      // 1）用户支付给商户，商户再支付给公司，卖方显示商户所属分公司名称；（有merchantMember）取merchantAreaName;
      // 2）用户/备选/商户下单直接支付给公司的，卖方显示自己所属分公司名称；（没有merchantMember）取memberAreaName;
      if(merchantMember){
        areaName = order.merchantAreaName;
      }else{
        areaName = order.memberAreaName;
      }


      order.showCreateTime = this.getCurrentTime(createTime);

      order.areaName = areaName;

      this.printTime =moment().format(formatType);

      this.deliver5.form = Object.assign(order);
      this.deliver5.loading = false
      this.deliver5.visible = true
    },
    getCurrentTime(createTime) {
      const now = new Date(createTime);
      const hours = now.getHours();
      const minutes = now.getMinutes();
      const seconds = now.getSeconds();

      return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    },
    //订单类型
    getOrderType(order){
      //(memberPartnerType大于0,说明是单品权益订单,否则就是普通单品订
      if(order.orderType=='1') {////1、单品订单；2-套餐订单;4-补贴套餐,8-体验套餐,9-运营套餐,16-合伙人订单,32-会员订单,
        // 单品
        if(order.memberPartnerType>0){
            return '11';//权益单品订单
          }else{
            return '1';//原价单品订单
          }
      }else if(order.orderType=='32'){//单品
        return '32';//会员单品

      }
    }
  }
}
</script>
<style lang="less" scoped>
/deep/.total {
  font-size: 20px;
}
</style>

