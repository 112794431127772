<template>
  <page-header-wrapper style="margin: unset;" >
    <a-row :gutter='24'>
      <a-col :sm='12' :md='6' :xl='6' :style="{ marginBottom: '24px' }">
        <a-card >
          <div class="card_title">本月订单实收：</div>
          <div class="card_content color-red">{{dashboard.monthOrder}}笔</div>
          <div class="card_content color-red">￥{{dashboard.monthAmount}}
          </div>
        </a-card>
      </a-col>

      <a-col :sm='12' :md='6' :xl='8' :style="{ marginBottom: '24px' }">
        <a-card class="order_count" >
          <div class="card_title">
            <a-row :style="{ marginBottom: '10px',marginTop:'30px' }">
              <a-col :span="12">现金订单笔数：<span class="color-red">{{dashboard.cashOrderCount}}笔</span></a-col>
              <a-col :span="12">商品金额：<span class="color-red">{{dashboard.cashOrderAmount}}</span></a-col>
            </a-row>
            <a-row :style="{ marginBottom: '10px' }">
              <a-col :span="12">积分订单笔数：<span class="color-red">{{dashboard.integralOrderCount}}笔</span></a-col>
              <a-col :span="12">商品金额：<span class="color-red">{{dashboard.integralOrderAmount}}</span></a-col>
            </a-row>
            <a-row :style="{ marginBottom: '9px' }">
              <a-col :span="12">混用订单笔数：<span class="color-red">{{dashboard.mixOrderCount}}笔</span></a-col>
              <a-col :span="12">商品金额：<span class="color-red">{{dashboard.mixOrderAmount}}</span></a-col>
            </a-row>
            <a-row :style="{ marginBottom: '10px' }">
              <a-col :span="12"></a-col>
              <a-col :span="12"></a-col>
            </a-row>
          </div>
        </a-card>
      </a-col>
      <a-col :sm='12' :md='6' :xl='4' :style="{ marginBottom: '24px' }">
        <a-card>
          <div class="card_title">待收货款订单：</div>
          <div class="card_content color-red">{{dashboard.waitOPaidOrder}}笔</div>
          <div class="card_content color-red">￥{{dashboard.waitOPaidAmount}}</div>
        </a-card>
      </a-col>
    </a-row>
    <a-form-model ref='form' :model='form' layout='inline'>
      <a-form-model-item prop='id'>
        <a-input v-model='form.id' style="width: 200px" placeholder="订单号" />
      </a-form-model-item>
      <a-form-model-item prop='userName'>
        <a-input v-model='form.userName' placeholder="购买人姓名" />
      </a-form-model-item>
      <a-form-model-item prop='userMobile'>
        <a-input v-model='form.userMobile' placeholder="购买人手机号" />
      </a-form-model-item>
      <a-form-model-item prop='spreaderName'>
        <a-input v-model='form.spreaderName' placeholder="推荐人" />
      </a-form-model-item>
      <a-form-model-item prop='payStatus'>
        <a-select v-model="form.payStatus" placeholder="状态" style="width: 150px">
          <a-select-option value="">
            状态
          </a-select-option>
          <a-select-option :value="1">
            待支付
          </a-select-option>
          <a-select-option :value="2">
            已支付
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item prop='start'>
        <a-range-picker
          v-model="dateArr"
          :format="['YYYY-MM-DD HH:mm', 'YYYY-MM-DD HH:mm']"
          :show-time="{
            hideDisabledOptions: true,
          }"
          :disabled-date="disabledDate"
          @change="dateChange"
        />
      </a-form-model-item>
      <a-form-model-item>
        <a-button type='primary' @click='handleSubmit'>
          查询
        </a-button>
      </a-form-model-item>
      <a-form-model-item>
        <a-button @click='handleReset'>
          重置
        </a-button>
      </a-form-model-item>
    </a-form-model>
    <div>
      <div class='table-operator' style="margin-top: 20px">
        <a-button type='primary' icon='reload' @click='$refs.table.refresh(true)'>刷新</a-button>
<!--        <a-button type='primary' icon='plus' @click='initialDeliver1'>手动录单</a-button>-->
        <!--        <a-button type='primary' icon='menu' @click='initialDeliver2'> 商免订单 {{  this.noAuditTotal  }}</a-button>-->
      </div>
      <a-spin :spinning="spinning">
        <s-table id="table" ref='table' rowKey='id' size='default' :columns='columns' :scroll='{ x: 1500}' :data='loadData' :loading="true">
              <span slot='id' slot-scope='text, record'>
                <template>
                  <a :class="{'color-red': record.activityId}" @click='handleDetail(record)'>
                    {{ text }}
                  </a>
                </template>
              </span>
          <span slot='amount' slot-scope='text, record'>
                <span v-if="getOrderType1 (record)=='1' || getOrderType1 (record)=='2'">
                  {{record.originalMerchantAmount}}
                </span>
                <span v-if="getOrderType1 (record)=='3'">
                  {{record.amount}}
                </span>
              </span>
          <span slot='memberDiscount' slot-scope='text, record'>
                <span v-if="getOrderType1 (record)=='1' || getOrderType1 (record)=='2'">
                  {{record.merchantDiscount}}
                </span>
                <span v-if="getOrderType1 (record)=='3'">
                  {{record.memberDiscount}}
                </span>
              </span>
          <span slot='consumeAmount' slot-scope='text, record'>
                <span v-if="getOrderType1 (record)=='1' || getOrderType1 (record)=='2'">
                  {{record.merchantAmount}}
                </span>
                <span v-if="getOrderType1 (record)=='3'">
                  {{record.consumeAmount}}
                </span>
              </span>
<!--          <span slot='memberName' slot-scope='text, record'>-->
<!--                <span v-if="getOrderType1 (record)=='1' || getOrderType1 (record)=='2'">-->
<!--                  {{record.merchantName}}-->
<!--                </span>-->
<!--                <span v-if="getOrderType1 (record)=='3'">-->
<!--                  {{record.memberName}}-->
<!--                </span>-->
<!--              </span>-->
<!--          <span slot='mobile' slot-scope='text, record'>-->
<!--                <span v-if="getOrderType1 (record)=='1' || getOrderType1 (record)=='2'">-->
<!--                  {{record.merchantMobile}}-->
<!--                </span>-->
<!--                <span v-if="getOrderType1 (record)=='3'">-->
<!--                  {{record.mobile}}-->
<!--                </span>-->
<!--              </span>-->

          <span slot='payStatus' slot-scope='text, record'>
                 <span v-if="getOrderType1 (record)=='1' || getOrderType1 (record)=='2'">
                    <span v-if="record.merchantOrderStatus=='1'">待支付</span>
                    <span v-if="record.merchantOrderStatus=='2'">已支付</span>
                </span>
                <span v-if="getOrderType1 (record)=='3'">
                    <span v-if="record.status =='paying'">待支付</span>
                    <span v-else>已支付</span>
                </span>

<!--               -{{getOrderType1 (record)}}-->
              </span>

          <span slot='action' slot-scope='text, record'>
                  <a-space>
                      <span v-if="getOrderType1 (record)=='1' || getOrderType1 (record)=='2'">
                          <a-popconfirm v-if="record.merchantOrderStatus=='1'" ok-text='确认' cancel-text='取消' title="是否确认收款？" @confirm='initialEnsure(record)'>
                            <a>确认收款</a>
                         </a-popconfirm>
                      </span>
                      <span v-if="getOrderType1 (record)=='3'">
                          <a-popconfirm v-if="record.status=='paying'" ok-text='确认' cancel-text='取消' title="是否确认收款？" @confirm='initialEnsure(record)'>
                            <a>确认收款</a>
                         </a-popconfirm>
                      </span>

                      <span>
                        <a-popconfirm title="是否要清除此行？" ok-text='确认' cancel-text='取消' @confirm="handleDelete(record)">
                          <a>删除</a>
                        </a-popconfirm>
                      </span>
                  </a-space>
              </span>
        </s-table>
      </a-spin>
    </div>

    <!--    <a-modal title='手动录单' :destroyOnClose='true' :maskClosable='true'-->
    <!--             :visible='deliver1.visible' @cancel='()=>this.deliver1.visible=false' width="30%"-->
    <!--             ok-text='确认' cancel-text='取消' @ok='handleDeliver1' :confirmLoading='deliver1.loading'>-->
    <!--      <a-form-model ref='form1' :model='deliver1.form' :rules='deliver1.rules' :labelCol='{ span: 8 }' :wrapperCol='{ span: 16 }'>-->
    <!--        <a-form-model-item label='下单账号（手机）' prop='mobile' :validateStatus="deliver1.mobileStatus" :help="deliver1.mobileHelp">-->
    <!--          <a-input v-model='deliver1.form.mobile' @change="onChangeMobile" style="width: 75%; margin-right: 8px;" />-->
    <!--          <a-button type='primary' @click='handleSignSubmit'>-->
    <!--            注册-->
    <!--          </a-button>-->
    <!--        </a-form-model-item>-->
    <!--        <a-form-model-item label='下单人姓名' prop='nickname'>-->
    <!--          <a-input v-model='deliver1.form.nickname' />-->
    <!--        </a-form-model-item>-->
    <!--        <a-form-model-item label="下单时间" prop='createTime'>-->
    <!--          <a-date-picker :valueFormat="createFormatType" v-model="deliver1.form.createTime" showTime placeholder="选择日期时间"/>-->
    <!--        </a-form-model-item>-->
    <!--        <a-form-model-item label='推荐人手机号' prop='spreaderMobile'>-->
    <!--          <a-input v-model='deliver1.form.spreaderMobile' @change="onChangeSpreaderMobile" style="width: 75%; margin-right: 8px;" />-->
    <!--          <a-button type='primary' @click='handleSpreaderSubmit'>-->
    <!--            保存-->
    <!--          </a-button>-->
    <!--        </a-form-model-item>-->
    <!--        <a-form-model-item label='推荐人'>-->
    <!--          <a-input v-model='deliver1.form.spreaderName' disabled />-->
    <!--        </a-form-model-item>-->
    <!--        <a-form-model-item label='所属商户' prop='masterName'>-->
    <!--          <a-input v-model='deliver1.form.masterName' disabled />-->
    <!--        </a-form-model-item>-->
    <!--        <template v-if="deliver1.form.orderType == 1">-->
    <!--          <a-card style="width: 90%;margin-left: auto;margin-right: auto">-->
    <!--            <a-descriptions :column="6" v-if="this.deliver1.form.prodList.length>0" >-->
    <!--              <template v-for="(item, index) in this.deliver1.form.prodList">-->
    <!--                <a-descriptions-item style="padding-bottom: 0;" :key="index" :label='item.title' span="3"></a-descriptions-item>-->
    <!--                <a-descriptions-item style="padding-bottom: 0;" :key="index" label='数量' span="2">-->
    <!--                  <a-input-number @change="onChangeCount" :min='0' v-model='item.count' />-->
    <!--                </a-descriptions-item>-->
    <!--                <a-descriptions-item style="padding-bottom: 0;padding-left: 20px;" :key="index" label='金额'  >{{ item.showPrice}}</a-descriptions-item>-->
    <!--              </template>-->
    <!--            </a-descriptions>-->
    <!--          </a-card>-->
    <!--        </template>-->
    <!--        <a-descriptions :column="3" style="width: 80%;margin-left: auto;margin-right: auto">-->
    <!--          <a-descriptions-item > <a-checkbox v-model='deliver1.form.systemPoints' >系统赠送积分</a-checkbox></a-descriptions-item>-->
    <!--          <a-descriptions-item :span="2"> 共计<span class="color-red">{{this.deliver1.form.count}}</span>件产品，合计：<span class="color-red">{{this.deliver1.form.amount}}</span></a-descriptions-item>-->
    <!--        </a-descriptions>-->


    <!--        <a-form-model-item label=' ' :colon="false" prop='isPay'  >-->
    <!--          <a-descriptions :column="1" v-if="rewardList.length>0">-->
    <!--            <template v-for="(item, index) in this.rewardList">-->
    <!--              <a-descriptions-item style="padding-bottom: 0;" :key="index" :label='item.remark'>{{item.memberName}}: {{ item.value }}</a-descriptions-item>-->
    <!--            </template>-->
    <!--          </a-descriptions>-->
    <!--        </a-form-model-item>-->
    <!--        <a-form-model-item label='转账方式' prop='otherPay'>-->
    <!--          <a-radio-group name="radioGroup" v-model="deliver1.form.otherPay">-->
    <!--            <a-radio value="true">-->
    <!--              本人付-->
    <!--            </a-radio>-->
    <!--            <a-radio value="false">-->
    <!--              他人代付-->
    <!--            </a-radio>-->

    <!--          </a-radio-group>-->
    <!--          <a-input v-if="deliver1.form.otherPay==='false'" v-model='deliver1.form.bankTransBuyerName' style="width: 100px;"/>-->
    <!--        </a-form-model-item>-->

    <!--        <a-form-model-item label='转账凭证：' prop='voucherList'>-->
    <!--          <a-upload-->
    <!--            list-type='picture-card'-->
    <!--            :multiple="true"-->
    <!--            :file-list="deliver1.form.voucherList"-->
    <!--            :customRequest='(event) => {handleUpload(event, "voucherList")}'-->
    <!--            :remove='handleListRemove'>-->
    <!--            <div v-if="!deliver1.form.voucherList  || (deliver1.form.voucherList && deliver1.form.voucherList.length < 5)">-->
    <!--              <a-icon :type="imgLoading ? 'loading' : 'plus'" />-->
    <!--              <div class="ant-upload-text">上传</div>-->
    <!--            </div>-->
    <!--          </a-upload>-->
    <!--        </a-form-model-item>-->

    <!--        <a-form-model-item label='发货方式' prop='bussType'>-->
    <!--          <a-radio-group name="radioGroup" v-model="deliver1.form.bussType">-->
    <!--            <a-radio value="draw">-->
    <!--              自提现货-->
    <!--            </a-radio>-->
    <!--            <a-radio value="deliver">-->
    <!--              物流发货-->
    <!--            </a-radio>-->
    <!--          </a-radio-group>-->
    <!--        </a-form-model-item>-->

    <!--        <template v-if="deliver1.form.bussType == 'draw'">-->
    <!--          <a-form-model-item label='提货人' prop='drawName'>-->
    <!--            <a-input v-model='deliver1.form.drawName' />-->
    <!--          </a-form-model-item>-->
    <!--          <a-form-model-item label='提货人手机号' prop='drawMobile'>-->
    <!--            <a-input v-model='deliver1.form.drawMobile' />-->
    <!--          </a-form-model-item>-->
    <!--        </template>-->
    <!--        <template v-else>-->
    <!--          <a-form-model-item label='收货人' prop='name'>-->
    <!--            <a-input v-model='deliver1.form.name' />-->
    <!--          </a-form-model-item>-->
    <!--          <a-form-model-item label='收货人手机号' prop='addressMobile'>-->
    <!--            <a-input v-model='deliver1.form.addressMobile' />-->
    <!--          </a-form-model-item>-->
    <!--          <a-form-model-item label='地区' prop='region'>-->
    <!--            <a-cascader-->
    <!--              :field-names="{ label: 'name', value: 'adcode', children: 'districts' }"-->
    <!--              :options="region"-->
    <!--              :show-search="{ filter }"-->
    <!--              :allowClear="false"-->
    <!--              placeholder="请选择"-->
    <!--              @change="onChange"-->
    <!--            />-->
    <!--          </a-form-model-item>-->
    <!--          <a-form-model-item label='详细地址' prop='address'>-->
    <!--            <a-input v-model='deliver1.form.address' />-->
    <!--          </a-form-model-item>-->
    <!--        </template>-->
    <!--      </a-form-model>-->
    <!--    </a-modal>-->

    <a-modal title='确认收款' :destroyOnClose='true' :maskClosable='true'
             :visible='deliver2.visible' @cancel='()=>this.deliver2.visible=false'
             ok-text='确认' cancel-text='取消' @ok='handleEnsure' :confirmLoading='deliver2.loading'>
      <a-form-model ref='form2' :model='deliver2.form' :labelCol='{ span: 6 }' :wrapperCol='{ span: 18 }'>
        <a-form-model-item label='订单号' prop='orderNo'>
          {{deliver2.form.orderNo}}
        </a-form-model-item>
        <a-form-model-item label='总销' prop='merchantAmount'>
          {{deliver2.form.totalSall}}
        </a-form-model-item>
        <a-form-model-item label='商户名称' prop='merchantName'>
          <a-input disabled v-model='deliver2.form.merchantName' />
        </a-form-model-item>
        <a-form-model-item label='应收金额' prop='originalMerchantAmount'>
          <a-input v-model='deliver2.form.originalMerchantAmount' @change="changeAmount"/>
        </a-form-model-item>
        <a-form-model-item label='剩余可用积分' prop='merchantDiscount'>
          <template>
            <a-input disabled v-model='deliver2.form.merchantDiscount' />
          </template>
        </a-form-model-item>

        <a-form-model-item label=' ' :colon="false">
          <a-radio-group v-model='deliver2.form.isUseds' @change="handleUseChange" :disabled="deliver2.form.orderDiscount==0 && deliver2.form.merchantPaymentMethod != ''">
            <a-radio value='0'>使用</a-radio>
            <a-radio value='1'>不使用</a-radio>
          </a-radio-group>
        </a-form-model-item>

        <a-form-model-item label='使用可用积分' prop='userCashBalance' v-if="deliver2.form.isUseds==='0' && !deliver2.form.orderDiscount && deliver2.form.merchantPaymentMethod=='' ">
          <a-input :disabled="!deliver2.form.canModUsesCashBalance" v-model='deliver2.form.userCashBalance' @change="handleChangeCashBalance" />
        </a-form-model-item>

        <template v-if="deliver2.form.orderDiscount">
          <a-form-model-item label='订单已用折扣' prop='orderDiscount' v-if="deliver2.form.isUseds==='0'">
            <a-input disabled  v-model='deliver2.form.orderDiscount' />
          </a-form-model-item>
        </template>

        <a-form-model-item label='实收金额' prop='merchantAmount'>
          <a-input disabled v-model='deliver2.form.merchantAmount' :class="font_class"/>
        </a-form-model-item>
        <a-form-model-item label=' ' prop='payMethod' :colon="false">
          <a-checkbox style="float: right;" @change="(e) => deliver2.form.checkStatus = e.target.checked ? 1 : 0">
            待核销
          </a-checkbox>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </page-header-wrapper>
</template>

<script>
import {ChartCard, Ellipsis, STable} from '@/components'
import {queryProductions} from '@/api/production'
import {queryMeal, queryMealCategories} from '@/api/meal'
import {
  addMemberOrder, deleteMemberOrder, getMemberOrder,
  getMerchantOrderData,
  getMobileMember,
  getMobileMerchant, payMemberOrder,
  putBindMerchant,
  queryMerchantOrder, queryMerchantOrderNew,
  queryNoAuditOrder,
  queryRewardList
} from '@/api/member-order'
import {getMember, getMerchantInfo, putMobileMember} from '@/api/member'
import region from '@/config/region'
import moment from 'moment'
import client from "@/config/oss.config";
import { getOrderType } from '@/utils/util';


const columns = [
  {
    width: 120,
    title: '订单号',
    dataIndex: 'id',
    scopedSlots: { customRender: 'id' }
  },
  {
    width: 100,
    title: '应付金额',
    dataIndex: 'amount',
    scopedSlots: { customRender: 'amount' }
  },
  {
    width: 100,
    title: '使用积分',
    dataIndex: 'memberDiscount',
    scopedSlots: { customRender: 'memberDiscount' }
  },
  {
    width: 100,
    title: '实付金额',
    dataIndex: 'consumeAmount',
    scopedSlots: { customRender: 'consumeAmount' }
  },
  {
    width: 100,
    title: '购买人',
    dataIndex: 'memberName',
    scopedSlots: { customRender: 'memberName' }
  },
  {
    width: 100,
    title: '购买人手机号',
    dataIndex: 'mobile',
    scopedSlots: { customRender: 'mobile' }
  },
  {
    width: 100,
    title: '推荐人',
    dataIndex: 'spreaderNickname',
    scopedSlots: { customRender: 'spreaderNickname' }
  },
  {
    width: 100,
    title: '分公司简称',
    dataIndex: 'memberAreaName',
    scopedSlots: { customRender: 'memberAreaName' }
  },
  {
    width: 100,
    title: '订单时间',
    dataIndex: 'createTime',
    scopedSlots: { customRender: 'createTime' }
  },
  //   {
  //   width: 100,
  //   title: '待付',
  //   dataIndex: 'waitPaidAmount',
  //   scopedSlots: { customRender: 'waitPaidAmount' },
  //     customRender: (text, record, index) => {
  //         return {
  //           attrs:{},
  //           props:{},
  //           style:{color:'red'},
  //           children: text
  //         }
  //     }
  // },
  {
    width: 100,
    title: '状态',
    dataIndex: 'payStatus',
    scopedSlots: { customRender: 'payStatus' },
  },
  {
    width: 150,
    title: '操作',
    dataIndex: 'action',
    scopedSlots: { customRender: 'action' },
  },
]


const formatType = 'YYYY-MM-DD HH:mm';
const formatMonthType = 'YYYY-MM';
const month = moment().format(formatMonthType);
const baseStart = moment().startOf('month').format(formatType);
// const baseEnd = moment().format(formatType);
const baseEnd = moment().startOf('day').format('YYYY-MM-DD')+ ' 23:59';

const createFormatType = 'YYYY-MM-DD HH:mm:ss';//录单时候页用到，不要乱改

export default {
  name: 'OrderList',
  components: {
    STable, Ellipsis, ChartCard
  },
  data() {
    return {
      spinning:false,
      font_class:"",
      wasCheck:false,
      // 筛选表单
      formatType,
      createFormatType,
      formatMonthType,
      dateArr: [baseStart, baseEnd],
      dateArr2: month,
      form: {
        keyword: "",
        start: baseStart,
        end: baseEnd,
      },
      loading: true,
      imgLoading: false,
      dashboard: {}, //订单统计数据
      // 表头
      columns,
      // columns2,
      // noAuditTotal:0,
      noAuditDashboard:{},
      // 加载数据方法 必须为 Promise 对象
      queryParam: {},
      loadData: parameter => {
        let param = Object.assign(parameter, this.queryParam, this.form);
        param.orderType = "1"//权益订单

        return queryMerchantOrderNew(param).then(datum => {
          this.$emit("setRightTitle", datum.total);
          return datum
        })
      },

      checkPoints:false,
      piLiangDisable:true,
      // prodList: [],
      mealTypeList: [],
      mealList: [],
      rewardList:[],
      memberInfo: {},
      deliver1: {
        visible: false,
        loading: false,
        form: {
          voucherList:[],
        },
        rules: {
          mobile: [
            { required: true, message: '请填写下单账号（手机）', trigger: 'change' },
            // { pattern: /^1[3456789]\d{9}$/, message: '请填写正确的手机号' }
          ],
          nickname: [{ required: true, message: '下单人姓名', trigger: 'change' }],
          spreaderMobile: [
            { required: true, message: '请填写推荐人手机号', trigger: 'change' },
            // { pattern: /^1[3456789]\d{9}$/, message: '请填写正确的手机号' }
          ],
          createTime: [{ required: true, message: '请选择', trigger: 'change' }],
          operator: [{ required: true, message: '请选择套餐类型', trigger: 'change' }],
          production: [{ required: true, message: '请选择商品', trigger: 'change' }],
          count: [
            { required: true, message: '请填写商品数量', trigger: 'change' },
            { type: 'integer', message: '商品数量填写整数', trigger: 'change' }
          ],
          amount: [{ required: true, message: '请填写订单金额', trigger: 'change' }],
          isUseMing:[{ required: false, message: '请填写订单金额', trigger: 'click'}],
          piLiang:[],
          otherPay: [{ required: true, }],
          otherName: [{ required: true, message: '请输入代付人姓名', trigger: 'change' }],
          bussType: [{ required: true, message: '请选择发货方式', trigger: 'change' }],
          name: [{ required: true, message: '请填写收货人', trigger: 'change' }],
          addressMobile: [{ required: true, message: '请填写收货人手机号', trigger: 'change' }],
          region: [{ required: true, message: '请选择地区', trigger: 'change' }],
          address: [{ required: true, message: '请填写地址', trigger: 'change' }],
          drawName: [{ required: true, message: '请填写提货人', trigger: 'change' }],
          drawMobile: [{ required: true, message: '请填写提货人手机号', trigger: 'change' }]
        },
      },
      deliver2: { visible: false, form: {}, loading: false },
      region: region,
    }
  },
  created() {
    this.getOrderData();
    this.$emit("setDirectTitle");//更新直付数量
  },
  methods: {
    handleDelete(record){
      deleteMemberOrder({ id: record.id }).then(result => {
        this.$message.success('操作成功')
        this.order = {}
        this.$refs.table.refresh(true)
      })
    },
    getOrderType1(msg){
      return  getOrderType(msg);
    },
    moment,
    // toTableList() {
    //   this.$router.push("/order/table/list");
    // },
    disabledDate(current) {
      return current && current > moment().endOf('day');
    },
    dateChange() {
      this.form.start =  this.dateArr[0] ? moment(this.dateArr[0]).format(this.formatType) : "";
      this.form.end =  this.dateArr[1] ? moment(this.dateArr[1]).format(this.formatType) : "";
    },
    getMerchantInfo() {
      getMerchantInfo({ mobile: this.deliver1.form.mobile }).then(result => {
        this.$set(this.deliver1.form, "masterName", result.merchantName);
      })
    },
    getMobileMember() {
      getMobileMember({phone: this.deliver1.form.mobile}).then(result => {
        this.$set(this.deliver1.form, "member", result.id);
        this.$set(this.deliver1.form, "nickname", result.nickname);
        this.$set(this.deliver1.form, "oldSpreaderMobile", result.spreaderMobile);
        this.$set(this.deliver1.form, "spreaderMobile", result.spreaderMobile);
        this.$set(this.deliver1.form, "spreaderName", result.spreaderName);
      })
    },
    getSpreaderMember() {
      getMobileMerchant({mobile: this.deliver1.form.spreaderMobile}).then(result => {
        this.$set(this.deliver1.form, "masterName", result.masterName);
        this.$set(this.deliver1.form, "spreaderName", result.merchantName);
      }).catch(err=>{
        if(err.message){
          this.$message.info(err.message)
        }

      })
    },
    onChangeSpreaderMobile() {

      if (this.deliver1.form.spreaderMobile.length >= 9) {
        this.getSpreaderMember();
      } else {
        this.$set(this.deliver1.form, "masterName", "");
        this.$set(this.deliver1.form, "spreaderName", "");
      }
    },
    onChangeMobile() {
      delete this.deliver1.mobileHelp;
      delete this.deliver1.mobileStatus;

      if (this.deliver1.form.mobile.length >= 9) {
        this.getMerchantInfo();
        this.getMobileMember();
        // debugger
        if (this.deliver1.form.orderType == 1) {
          // this.handleSearch1()
          this.onChangeCount()
        }
        // else {
        //   this.handleSearch2()
        //   this.handleSearch3()
        //   this.onChangeCount()
        // }
      } else {
        this.$set(this.deliver1.form, "masterName", "");
        this.$set(this.deliver1.form, "oldSpreaderMobile", "");
        this.$set(this.deliver1.form, "spreaderMobile", "");
        this.$set(this.deliver1.form, "spreaderName", "");
      }
    },

    onChangeCount(value) {
      // debugger;
      // if(value&&value>=0){
      //重置价格
      this.deliver1.form.amount = 0;
      this.deliver1.form.count = 0;

      let productList = this.deliver1.form.prodList;
      let prod = [];
      for(let i in productList){
        let p = productList[i];
        p.showPrice = p.count * p.price;
        if(p.count>0){
          this.deliver1.form.count = this.deliver1.form.count +  p.count;
          this.deliver1.form.amount = this.deliver1.form.amount +  p.showPrice;
          let pro={production:0,quantity:0};
          pro.production = p.id;
          pro.quantity = p.count;
          prod.push(pro);
        }
      }
      //设置商品id


      if (this.deliver1.form.count>0) {
        //显示奖励列表
        let spreaderMobile = this.deliver1.form.spreaderMobile;
        if(!spreaderMobile){
          this.$message.error('请先绑定推荐人！');
          return;
        }
        if(this.deliver1.form.mobile){
          // debugger;
          this.$set(this.deliver1.form, "productionList", prod);
          this.showRewardList();
        }else{
          this.$message.info('请输入手机号码，获取奖励详情')
        }
      }
      // }

    },
    showRewardList(){
      //输入了电话号码才能访问奖励列表

      let mobile = this.deliver1.form.mobile;
      let amount = this.deliver1.form.amount;
      let param = Object.assign({mobile:mobile,amount:amount});
      return queryRewardList(param).then(datum => {
        // debugger;
        this.rewardList = datum;
      })
      // }

    },
    onChange(value, selectedOptions) {
      this.$set(this.deliver1.form, "region", selectedOptions)
    },
    filter(inputValue, path) {
      return path.some(option => option.name.toLowerCase().indexOf(inputValue.toLowerCase()) > -1);
    },

    handleSearch1(keywords) {
      let _this = this;
      let prams = { current: 1, size: 1000,categoryName:'全部商品', status: 'online', mobile: this.deliver1.form.mobile };
      // debugger;
      queryProductions(prams).then(result => {
        // _this.deliver1.form.prodList = result.records;
        let pl = result.records;
        for(let p in pl){
          pl[p].count = 0;
          pl[p].showPrice = 0;
        }
        _this.deliver1.form = {
          otherPay:'true',
          createTime: moment().format(createFormatType),
          bussType: "deliver",
          paymentMethod: "bank_trans",//bank_trans  trans
          orderType: 1,
          prodList:result.records,
        }
        _this.deliver1.loading = false
        _this.deliver1.visible = true
        this.deliver1.form.count =0;
        this.deliver1.form.amount = 0;
      })
    },
    handleChange2(e) {
      this.$set(this.deliver1.form, "operator", e)
      this.$set(this.deliver1.form, "production", "")
    },
    handleSearch2(keywords) {
      queryMealCategories({ current: 1, size: 1000, isEmployee: 1 }).then(result => {
        this.mealTypeList = result.records
      })
    },
    handleChange3(e) {
      this.$set(this.deliver1.form, "production", e)
      this.onChangeCount()
    },
    handleSearch3(keywords) {
      queryMeal({ type: 10, current: 1, size: 1000, status: 'online', mobile: this.deliver1.form.mobile }).then(result => {
        this.mealList = result.records
      })
    },
    handleReset() {
      this.dateArr = [baseStart, baseEnd];
      this.form.start = baseStart;
      this.form.end = baseEnd;
      this.$refs.form.resetFields()
      this.$refs.table.refresh(true)
    },
    handleSubmit() {
      this.getOrderData();
      this.$refs.table.refresh(true)
    },
    handleSignSubmit() {
      // if (!/^1[3456789]\d{9}$/.test(this.deliver1.form.mobile)) {
      //   this.$message.warning('请输入正确手机号')
      //   return;
      // }
      putMobileMember({mobile: this.deliver1.form.mobile}).then(_result => {
        this.$message.success('注册成功')
        this.$set(this.deliver1, "mobileHelp", "注册成功")
        this.$set(this.deliver1, "mobileStatus", "error")
        this.getMobileMember();
        if (this.deliver1.form.orderType == 1) {
          this.handleSearch1()
          this.onChangeCount(this.deliver1.form.count)
        } else {
          this.handleSearch2()
          this.handleSearch3()
          this.onChangeCount()
        }
      }).catch((e) => {
        this.$set(this.deliver1, "mobileHelp", e.data.errorMsg)
        this.$set(this.deliver1, "mobileStatus", "error")
      })
    },
    handleSpreaderSubmit() {
      // if (!/^1[3456789]\d{9}$/.test(this.deliver1.form.spreaderMobile)) {
      //   this.$message.warning('请输入正确手机号')
      //   return;
      // }
      let member = this.deliver1.form.member;
      let spreaderMobile =  this.deliver1.form.spreaderMobile;
      if(member&&spreaderMobile){
        putBindMerchant({id: this.deliver1.form.member, mobile: this.deliver1.form.spreaderMobile}).then(_result => {
          this.$message.success('绑定成功');
          this.getMerchantInfo();
          this.getMobileMember();
        })
      }else{
        this.$message.error("绑定失败！必须输入下单手机号和推荐人手机号！")
      }

    },
    getOrderData() {
      getMerchantOrderData(Object.assign({}, this.form)).then(result => {
        this.dashboard = result;
        this.loading = false;
      })

    },
    handleDetail(record) {
      // debugger;
      this.$router.push({ path: '/order/orderNew/OrderDetail', query: { id: record.id } })
      // this.$router.push({ path: '/order/edit/'+record.id })
    },
    initialDeliver1() {
      // this.deliver1.form= {otherPay:'true'};
      this.handleSearch1()
    },
    handleDeliver1() {
      // console.log("提交了！！！"+this.deliver1.form.piLiang);
      // debugger;
      this.$refs.form1.validate((result) => {
        let otherPay = this.deliver1.form.otherPay;
        if (result) {
          if(otherPay=='false'){
            let otherName = this.deliver1.form.bankTransBuyerName;
            if(!otherName){
              this.$message.error("选择代付，必须要输入代付人姓名");
              return;
            }
          }

          if (this.deliver1.form.oldSpreaderMobile != this.deliver1.form.spreaderMobile) {
            this.deliver1.loading = true;
            let param = Object.assign({id: this.deliver1.form.member, mobile: this.deliver1.form.spreaderMobile});

            putBindMerchant(param).then(_result => {
              this.$message.success('绑定成功')
              this.deliver1.loading = false;
              this.addOrder();

            }).catch(() => {
              this.deliver1.loading = false;
            })
          } else {
            this.addOrder();
          }
        }
      })
    },
    // initialDeliver2() {
    //   this.deliver2.loading=true;
    //   this.deliver2.visible = true;
    // },
    addOrder() {
      if (this.deliver1.form.region && this.deliver1.form.region.length > 0) {
        this.deliver1.form.regionOneCode = this.deliver1.form.region[0].adcode;
        this.deliver1.form.regionOneName = this.deliver1.form.region[0].name;
        this.deliver1.form.regionTwoCode = this.deliver1.form.region[1].adcode;
        this.deliver1.form.regionTwoName = this.deliver1.form.region[1].name;
        this.deliver1.form.regionThreeCode = this.deliver1.form.region[2].adcode;
        this.deliver1.form.regionThreeName = this.deliver1.form.region[2].name;

      }


      //不使用名额，1表示不使用，0表示使用
      if(this.deliver1.form.useQuota){
        this.deliver1.form.useQuota = 1;
      }else{
        this.deliver1.form.useQuota = 0;
      }

      if(this.deliver1.form.piLiang){
        this.deliver1.form.batchSelect = 1;
      }else{
        this.deliver1.form.batchSelect = 0;
      }

      //系统积分赠送
      let form = this.deliver1.form;

      if(form.systemPoints){
        this.deliver1.form.rewardList = this.rewardList;
      }

      const parameter = JSON.parse(JSON.stringify(this.deliver1.form));

      // debugger;

      let prl = this.deliver1.form.productionList;
      if(!prl ||this.deliver1.form.count<1){
        this.$message.error("选择先选择商品");
        return;
      }


      delete parameter.region;
      this.deliver1.loading = true;
      let merchantPaymproentProof = [];

      if(this.deliver1.form.voucherList){
        for(let item of this.deliver1.form.voucherList){
          merchantPaymproentProof.push(item.url);
        }
        if(merchantPaymproentProof && merchantPaymproentProof.length>0){
          parameter.merchantPaymentProof = merchantPaymproentProof.join(',');
        }
      }

      debugger;


      if (parameter.orderType === 1) {
        addMemberOrder(parameter).then(_result => {
          // debugger;
          this.$emit("setDirectTitle");//更新直付数量
          this.$message.success('操作成功')
          this.$refs.table.refresh(true)
          this.deliver1.visible = false
          this.deliver1.loading = false;

        }).catch(() => {
          this.deliver1.loading = false;
        })

      }
    },
    async handleUpload(event, key) {
      this.imgLoading = true
      let result = await client.put('order/' + event.file.uid, event.file)
      let list =  this.deliver1.form.voucherList;
      if(!list){
        this.deliver1.form.voucherList = [];//如果不存在则初始化。
      }
      this.deliver1.form.voucherList.push({
        url: result.url,
        uid: 0 - (this.deliver1.form.voucherList.length + 1),
        name: result.url,
        status: 'done',
      })
      this.imgLoading = false;
    },
    handleListRemove(event) {
      try {
        let index = this.deliver1.form.voucherList.findIndex(item => item.url == event.url);
        this.deliver1.form.voucherList.splice(index, 1);
        return true;
      } catch (error) {
        this.$message.warning('操作失败')
        return false
      }
    },
    toCounterList() {
      this.$router.push("/order/counter/list");
    },

    initialEnsure(re) {
      let type = this.getOrderType1 (re);
      let mid = 1;
      if(type == '1' || type=='2'){//用商 or 商直 -> 取商户
        mid = re.merchantMember;
      }else if(type == '3'){// 用直 ->取用户
        mid = re.member;
      }
      this.spinning = true;
      getMember({ id: mid }).then(result => {
        let  profile = Object.assign({}, result);
        getMemberOrder({ id: re.id }).then(res => {
          let  record = Object.assign({}, res);
          let backRecord = record.operatorNickname=='后台录单';//是否为后台录单
          let tempAmount = 0;//应收金额
          let tempDiscount = 0;//折扣金额


          if(type=='1'||type=='2'){//取商户
            tempAmount = parseFloat(record.originalMerchantAmount) || 0;//应收金额
            tempDiscount = parseFloat(record.merchantDiscount);
          }else{//取用户
            tempAmount = parseFloat(record.amount) || 0;//应收金额
            tempDiscount = parseFloat(record.memberDiscount);
          }
          let cashBalance = parseFloat(profile.cashBalance);
          let merchantAmount = 0;
          let canModUsesCashBalance = false;//是否允许修改用户积分
          let isUseds = 1;//默认选择不允许使用
          let userCashBalance = 0;//显示实际使用积分
          if(backRecord){
            // 2、后台给用户和商户录单，录单后需确认收款。此场景可正常修改。
            isUseds = '1';
            canModUsesCashBalance = true;
            merchantAmount = tempAmount;
          }else{
            //1、商户小程序支付给公司时选择转账或扫码支付，提交到后台确认收款。此场景不能修改使用积分。
            //首先判断订单是否有折扣，有折扣就直接使用订单折扣，没有就开放给用户使用折扣
            if(tempDiscount>0){
              //订单有折扣
              if (tempAmount > tempDiscount) {
                merchantAmount = tempAmount - tempDiscount;
              } else {
                merchantAmount = 0;//等于直接抵扣
              }
              userCashBalance = tempDiscount;
              isUseds = '0';
              canModUsesCashBalance = false;
            }else{
              //订单没有折扣，开放给用户使用帐户积分，可以选择，但不能修改
              isUseds = '1';
              canModUsesCashBalance = true;
              merchantAmount = tempAmount;
            }
          }

          let merchantPaymentMethod = record.merchantPaymentMethod;

          this.deliver2.form = {
            orderNo:record.id,
            id: record.id,
            totalSall:record.wealthQuotaMemberName?record.wealthQuotaMemberName:'无',//总销名称
            // payMethod: record.merchantPaymentMethod||record.paymentMethod,//||"bank_trans"
            merchantName: record.merchantName?record.merchantName:'无',
            merchantDiscount: cashBalance,//现金折扣
            originalMerchantAmount: tempAmount,
            tempOriginalMerchantAmount:tempAmount,
            merchantAmount: merchantAmount,//实收金额
            userCashBalance:0,//使用现金折扣初始化
            orderDiscount:userCashBalance,//订单原本折扣
            tempUserCashBalance:cashBalance,//帐户余额临时存放
            isUseds:isUseds,
            tempDiscount:tempDiscount,
            canModUsesCashBalance : canModUsesCashBalance,//是否允许使用
            merchantPaymentMethod:merchantPaymentMethod,//支付方式，这个为空才能允许使用折扣
          }
          this.tempDiscountUseValue = tempDiscount;
          this.deliver2.visible = true;
          this.spinning = false;
        }).catch(e=>{
          this.$message.error('获取失败');
          this.spinning = false;
        });
      });
    },
    /***************************************************************************************确认收款模块**************************************************************************************/
    handleEnsure() {
      this.$refs.form2.validate((result) => {
        if (result) {

          if(!this.deliver2.form.orderDiscount && this.deliver2.form.userCashBalance>this.deliver2.form.merchantDiscount){
            this.$message.error("不能大于剩余可用积分")
            return;
          }
          if(this.deliver2.form.isUseds=='0' && !this.deliver2.form.orderDiscount && !this.deliver2.form.userCashBalance){
            this.$message.error("使用积分不能为空")
            return;
          }

          let isUsed = this.deliver2.form.isUseds;

          this.deliver2.loading = true;
          let params = Object.assign({
            id: this.deliver2.form.id, payMethod: this.deliver2.form.payMethod,
            checkStatus: this.deliver2.form.checkStatus || 0,
            useCash:isUsed,
            originalMerchantAmount:this.deliver2.form.originalMerchantAmount,
          })


          if( isUsed =='0' && !this.deliver2.form.orderDiscount){
            //只有允许修改的时候才能传
            params.cashValue = this.deliver2.form.userCashBalance;
          }
          payMemberOrder(params).then(_result => {
            this.$message.success('操作成功')
            this.$refs.table.refresh(true)
            this.deliver2.visible = false
            this.deliver2.loading = false;
            this.order = {}

          }).catch(() => {
            this.deliver2.loading = false;
          })
        }
      })
    },
    changeAmount(e){
      const regex = /^[0-9]\d*$/;
      const value = event.target.value;
      if (!regex.test(value)) {
        debugger;
        this.deliver2.form.originalMerchantAmount = this.deliver2.form.tempOriginalMerchantAmount;
        let tempAmount = this.initValue() ;
        this.deliver2.form.merchantAmount =tempAmount;
        this.$message.info('只能输入正整数！');
        return;
      }

      let _form = this.deliver2.form;
      if(_form.isUseds=='1'){
        this.deliver2.form.userCashBalance = 0;
      }else{
        //勾选了使用
        if(this.deliver2.form.tempDiscount>0){
          this.deliver2.form.userCashBalance = this.deliver2.form.tempDiscount;
        }else{
          this.deliver2.form.userCashBalance = this.deliver2.form.tempUserCashBalance;
        }
      }
      let totalAmount = this.initValue();
      this.deliver2.form.merchantAmount = totalAmount;
    },
    handleUseChange(e){
      if(this.deliver2.form.orderDiscount==0 && this.deliver2.form.merchantPaymentMethod != ''){
        this.$message.error("由于本订单类型属于扫描或者转账支付，用户已经付过款了，不能使用现金折扣");
        return;
      }

      let _form = this.deliver2.form;
      if(_form.isUseds=='1'){
        this.deliver2.form.userCashBalance = 0;
        this.isChecked = 1;
      }else{
        //勾选了使用
        this.isChecked = 0;
        if(this.deliver2.form.tempDiscount>0){
          this.deliver2.form.userCashBalance = this.deliver2.form.tempDiscount;
        }else{
          this.deliver2.form.userCashBalance = this.deliver2.form.tempUserCashBalance;
        }
      }
      this.deliver2.form.merchantAmount = this.initValue();
    },
    //更改剩余积分时候触发
    handleChangeCashBalance(e){
      const regex = /^[0-9]\d*$/;
      const value = event.target.value;
      if (!regex.test(value)) {
        let amount = this.initValue();
        this.deliver2.form.merchantAmount = amount - this.deliver2.form.userCashBalance;
        this.deliver2.form.userCashBalance = "";
        this.$message.info('只能输入正整数！');
        return;
      }
      // debugger;
      if( this.deliver2.form.merchantDiscount < this.deliver2.form.userCashBalance){
        this.$message.info('积分不能大于可用积分');
        this.deliver2.form.userCashBalance = this.deliver2.form.tempUserCashBalance;

        let totalAmount = this.initValue();
        this.deliver2.form.merchantAmount = totalAmount;//更新应收金额
        return;
      }
      if(this.deliver2.form.originalMerchantAmount <= this.deliver2.form.userCashBalance){
        //如果可用积分大于实付价格，那么将使用可用积分设置为实收金额
        this.deliver2.form.userCashBalance = this.deliver2.form.originalMerchantAmount;
      }
      //计算实付金额
      this.deliver2.form.merchantAmount = this.deliver2.form.originalMerchantAmount - this.deliver2.form.userCashBalance;
    },
    //超过重制值
    initValue(){
      let totalAmount = 0
      this.deliver2.form.userCashBalance = this.deliver2.form.userCashBalance? this.deliver2.form.userCashBalance:0;
      if(this.deliver2.form.originalMerchantAmount > this.deliver2.form.userCashBalance){
        totalAmount = this.deliver2.form.originalMerchantAmount -  this.deliver2.form.userCashBalance;
        // this.deliver2.form.userCashBalance = totalAmount;
      }else{
        totalAmount = 0;
        this.deliver2.form.userCashBalance = this.deliver2.form.originalMerchantAmount;
      }
      return totalAmount;
    },
    /***************************************************************************************确认收款模块**************************************************************************************/
  }
}
</script>
<style lang="less" scoped>
/deep/.order_count .ant-card-body {
  padding: 0 24px 3px !important;
  display: flex;
  flex-wrap: wrap;
}
.card_title {
  width: 100%;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  line-height: 22px;
}
.card_content {
  width: 100%;
  font-size: 18px;
  line-height: 30px;
  height: 30px;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  white-space: nowrap;
  margin-top: 4px;
  color: #000;
}
.order_count .card_content {
  width: 50%;
}
/deep/.total {
  font-size: 20px;
}
/deep/.ant-page-header{
  display: none;
}
</style>
