<template>
  <page-header-wrapper style="margin: unset;">    
    <a-form-model ref='form' :model='form' layout='inline'>
      <a-form-model-item prop='keyword'>
        <a-input v-model='form.keyword' style="width: 300px" placeholder="负责人/手机号" />
      </a-form-model-item>
      <a-form-model-item prop='start'>
        <a-range-picker
          v-model="dateArr"
          :format="[formatType, formatType]"
          :show-time="{
            hideDisabledOptions: true
          }"
          :disabled-date="disabledDate"
          @change="dateChange"
        />
      </a-form-model-item>
      <a-form-model-item>
        <a-button type='primary' @click='handleSubmit'>
          查询
        </a-button>
      </a-form-model-item>
      <a-form-model-item>
        <a-button @click='handleReset'>
          重置
        </a-button>
      </a-form-model-item>
    </a-form-model>
    <div>
      <div class='table-operator' style="margin-top: 20px">
        <a-button type='primary' icon='reload' @click='$refs.table.refresh(true)'>刷新</a-button>
        <span style="margin-left: 30px;"></span>
        <span style="margin-right: 20px;">服务业绩合计：{{this.ext1}}</span>
      </div>

      <s-table id="table" ref='table' rowKey='id' size='default' :columns='columns' :scroll='{ x: 1500}' :data='loadData'>
        <span slot='status' slot-scope='text,record'>
          <template>
            <a-badge dot v-if='text=="1"' :status='"success"' :text='"是"' @click="showMemberList(record)" style="cursor: pointer;"></a-badge>
            <a-badge dot v-else-if='text=="0"' :status='"error"' :text='"否"' @click="showMemberList(record)" style="cursor: pointer;"></a-badge>
          </template>
        </span>
      </s-table>
    </div>

    <a-modal title='订单时间' :destroyOnClose='true' :maskClosable='true'
             :visible='deliver1.visible' @cancel='()=>this.deliver1.visible=false' :width="900"
             ok-text='确认' cancel-text='取消'  :confirmLoading='deliver1.loading'>
      <a-form-model ref='form1' :model='deliver1.form' :rules='deliver1.rules' :labelCol='{ span: 8 }' :wrapperCol='{ span: 16 }'>
        <a-table :dataSource="loadUserData" :columns="columns12" :pagination="false" :loading="tableLoading">
           <span slot='name' slot-scope='text, record'>
              <template>
                <a v-if="record.memberList.length>0" @click='showMemberChildList(record.memberList)'>
                  {{ text || "未知" }}({{record.memberList.length}})
                </a>
                <span v-else >
                  {{ text || "未知" }}({{record.memberList.length}})
                </span>
              </template>
        </span>
        </a-table>
      </a-form-model>
      <template slot="footer">
        <a-button  @click="closeBigWin">关闭</a-button>
      </template>
    </a-modal>

    <a-modal title='订单时间' :destroyOnClose='true' :maskClosable='true'
             :visible='deliver2.visible'  :width="900" :confirmLoading='deliver2.loading'>
      <a-form-model ref='form2'  :rules='deliver2.rules'  :labelCol='{ span: 8 }' :wrapperCol='{ span: 16 }'>
        <a-table :dataSource="loadUserData2" :columns="columns12" :pagination="false" :loading="tableLoading2">
          <span slot='name' slot-scope='text, record'>
              <template>
                {{ text || "未知" }}
              </template>
        </span>
        </a-table>
      </a-form-model>
      <template slot="footer">
        <a-button  @click="closeWin">返回上一级</a-button>
      </template>
    </a-modal>
  </page-header-wrapper>
</template>

<script>
import { STable, Ellipsis, ChartCard } from '@/components'
import {getLocalUserData, queryPerformancePresident} from '@/api/performance.js'
import moment from 'moment'


const columns = [
  {
    width: 150,
    title: '商户简称',
    dataIndex: 'merchantShortName',
    scopedSlots: { customRender: 'merchantShortName' }
  },
  {
    width: 150,
    title: '负责人',
    dataIndex: 'masterName',
    scopedSlots: { customRender: 'masterName' }
  },
  {
    width: 150,
    title: '手机号',
    dataIndex: 'mobile',
    scopedSlots: { customRender: 'mobile' }
  },
  // {
  //   width: 100,
  //   title: '储备晋升',
  //   dataIndex: 'status',
  //   scopedSlots: { customRender: 'status' }
  // },
  {
    width: 150,
    title: '权益业绩',
    dataIndex: 'totalAmount',
    scopedSlots: { customRender: 'totalAmount' }
  },
  {
    width: 150,
    title: '运营中心',
    dataIndex: 'servicePresidentName',
    scopedSlots: { customRender: 'servicePresidentName' }
  },
  {
    width: 100,
    title: '分公司简称',
    dataIndex: 'areaShortName',
    scopedSlots: { customRender: 'areaShortName' }
  },
]

const columns12 = [
  {
    width: 150,
    title: '姓名',
    dataIndex: 'masterName',
    scopedSlots: { customRender: 'name' }
  },
  {
    width: 150,
    title: '授权编号',
    dataIndex: 'authCode',
    scopedSlots: { customRender: 'authCode' }
  },
  {
    width: 150,
    title: '订单时间',
    dataIndex: 'orderTime',
    scopedSlots: { customRender: 'orderTime' }
  }
]

const formatType = 'YYYY-MM-DD';
const baseStart = moment().startOf('month').format(formatType);
const baseEnd = moment().format(formatType);

export default {
  name: 'PerformanceCenterList',
  components: {
    STable, Ellipsis, ChartCard
  },
  data() {
    return {
      showMemberList(record){
        this.deliver1.visible = true

        getLocalUserData({id:record.id}).then(result => {
          debugger;
          this.tableLoading = false;
          this.loadUserData =   result;

        });
      },
      // 筛选表单
      formatType,
      dateArr: [baseStart, baseEnd],
      form: {
        keyword: "",
        start: moment(baseStart).startOf('day').format(formatType),
        end: moment(baseEnd).endOf('day').format(formatType),
      },
      loading: true,
      dashboard: {}, //订单统计数据
      // 表头
      columns,
      columns12,
      total: 0,
      deliver1: {
        visible: false,
        loading: false
      },
      deliver2: {
        visible: false,
        loading: false
      },
      tableLoading: true,
      tableLoading2:true,
      // 加载数据方法 必须为 Promise 对象
      queryParam: {
        type: 'center'
      },
      ext1:0,//服务业绩合计
      loadData: parameter => {
        parameter.pageNum = parameter.current;
        parameter.pageSize = parameter.size;
        return queryPerformancePresident(Object.assign(parameter, this.queryParam, this.form))
          .then(datum => {
            this.ext1 = datum.ext1;
            this.total = datum.total;
            this.$emit("setCenterTitle", this.total);
            return datum
          })
      },
      loadUserData:[],
      loadUserData2:[],
    }
  },
  created() {
  },
  methods: {
    closeWin(){
      this.deliver1.visible=true;
      this.deliver2.visible=false;
      this.tableLoading2 = true;
    },
    closeBigWin(){
      this.deliver1.visible=false;
    },

    showMemberChildList(record){
      debugger;
      this.deliver1.visible=false;
      this.deliver2.visible=true;
      this.loadUserData2 =   record;
      this.tableLoading2 = false;
    },
    moment,
    disabledDate(current) {
      return current && current > moment().endOf('day');
    },
    dateChange() {
      this.form.start =  moment(this.dateArr[0]).startOf('day').format(this.formatType);
      this.form.end =  moment(this.dateArr[1]).endOf('day').format(this.formatType);
    },
    handleReset() {
      this.dateArr = [baseStart, baseEnd];
      this.form.start = moment(baseStart).startOf('day').format(this.formatType);
      this.form.end = moment(baseEnd).endOf('day').format(this.formatType);
      this.$refs.form.resetFields()
      this.$refs.table.refresh(true)
    },
    handleSubmit() {
      this.$refs.table.refresh(true)
    },
    handleDeliver1(){

    }
  }
}
</script>
<style lang="less" scoped>
/deep/.ant-page-header{
  display: none;
}
/deep/.order_count .ant-card-body {
  padding: 0 24px 3px !important;
  display: flex;
  flex-wrap: wrap;
}
.card_title {
    width: 100%;
    color: rgba(0, 0, 0, 0.45);
    font-size: 14px;
    line-height: 22px;
}
.card_content {
  width: 100%;
  font-size: 18px;
  line-height: 30px;
  height: 30px;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  white-space: nowrap;
  margin-top: 4px;
  color: #000;
}
.order_count .card_content {
  width: 50%;
}
</style>
