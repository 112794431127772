<template>
  <page-header-wrapper :title='this.$route.meta.title'>
    <a-row :gutter='24'>
      <a-col :sm='24' :md='24' :xl='24' :style="{ marginBottom: '24px' }">
        <a-card>
          <div class="card_title">
            <a-row>
              <a-col :span="3">订单笔数：<span class="color-red">{{ dashboard.ledgerOrderCount }}笔</span></a-col>
              <a-col :span="3">纯现金：<span class="color-red">{{ dashboard.ledgerOrderOnlyCount }}笔</span></a-col>
              <a-col :span="3">纯折扣：<span class="color-red">{{ dashboard.ledgerOrderOnlyDiscountCount }}笔</span></a-col>
              <a-col :span="6">混合：<span class="color-red">{{ dashboard.ledgerOrderMixCount }}笔</span></a-col>
              <a-col :span="3">异常：<span class="color-red">{{ dashboard.abnormalOrderCount  }}笔</span></a-col>
              <a-col :span="3">获得折扣：<span class="color-red">{{dashboard.ledgerOrderDiscountCount}}笔</span></a-col>
              <a-col :span="3">订货单：<span class="color-red">{{dashboard.batchSelectCount}}笔</span></a-col>
            </a-row>
          </div>
          <div class="card_title" style="padding-top: 10px;"></div>
          <!-- <div class="card_content" style="line-height: 30px;">
            <a-row>
              <a-col :span="5">{{ dashboard.ledgerOrderCount }}笔</a-col>
              <a-col :span="5">{{ dashboard.ledgerOrderOnlyDiscountCount }}笔</a-col>
              <a-col :span="9">{{ dashboard.ledgerOrderMixCount }}笔</a-col>
              <a-col :span="5">{{ dashboard.ledgerOrderOnlyCount }}笔</a-col>
            </a-row>
          </div> -->
          <div class="card_content">
            <a-row>
              <a-col :span="3">¥{{ dashboard.ledgerOrderAmount }}</a-col>
              <a-col :span="3">¥{{ dashboard.ledgerOrderOnlyAmount }}</a-col>
              <a-col :span="3">¥{{ dashboard.ledgerOrderOnlyDiscountAmount }}</a-col>
              <a-col :span="3"><span class="card_title">混折：</span>¥{{ dashboard.ledgerOrderMixDiscount }}</a-col>
              <a-col :span="3"><span class="card_title">混现：</span>¥{{ dashboard.ledgerOrderMixAmount }}</a-col>
              <a-col :span="3"><span class="card_title"></span>¥{{ dashboard.abnormalOrderAmount }}</a-col>
              <a-col :span="3"><span class="card_title"></span>￥{{dashboard.ledgerOrderDiscountAmount}}</a-col>
              <a-col :span="3"><span class="card_title"></span>￥{{dashboard.batchSelectAmount}}</a-col>
            </a-row>
          </div>
        </a-card>
      </a-col>
    </a-row>
    <a-form-model ref='form' :model='form' layout='inline'>
      <a-form-model-item prop='keyword'>
        <a-input v-model='form.keyword' style="width: 300px" placeholder="订单号" />
      </a-form-model-item>
      <a-form-model-item prop='merchantName'>
        <a-input v-model='form.merchantName' style="width: 300px" placeholder="商户公司名称" />
      </a-form-model-item>
      <a-form-model-item prop='merchantPaymentType'>
        <a-select v-model="form.merchantPaymentType" placeholder="收款方式" style="width: 250px">
          <a-select-option :value="undefined">
            收款方式
          </a-select-option>
          <a-select-option :value="1">
            纯折扣
          </a-select-option>
          <a-select-option :value="2">
            混合
          </a-select-option>
          <a-select-option :value="3">
            纯现金
          </a-select-option>
          <a-select-option :value="4">
            订货单
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item prop='bankAccountId'>
        <a-select v-model="form.bankAccountId" placeholder="总销公司" style="width: 250px">
          <a-select-option :value="undefined">
            总销公司
          </a-select-option>
          <a-select-option v-for="(item, index) in bankAccountList" :key="index" :value="item.id">
            {{item.name}}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item prop='ledgerStatus'>
        <a-select v-model="form.ledgerStatus" placeholder="审核状态" style="width: 250px" value="0">
          <a-select-option :value="undefined">
            审核状态
          </a-select-option>
          <a-select-option :value="0" >
            待审
          </a-select-option>
          <a-select-option :value="1">
            正常
          </a-select-option>
          <a-select-option :value="2">
            异常
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item prop='start'>
        <a-range-picker
          v-model="dateArr"
          :format="[formatType, formatType]"
          :show-time="{
            hideDisabledOptions: true
          }"
          :disabled-date="disabledDate"
          @change="dateChange"
        />
      </a-form-model-item>
      <a-form-model-item>
        <a-button type='primary' @click='handleSubmit'>
          查询
        </a-button>
      </a-form-model-item>
      <a-form-model-item>
        <a-button @click='handleReset'>
          重置
        </a-button>
      </a-form-model-item>
    </a-form-model>
    <div>
      <div class='table-operator' style="margin-top: 20px">
        <a-button type='primary' icon='reload' @click='$refs.table.refresh(true)'>刷新</a-button>
        <a-button type='primary' @click='initialDeliver2'>账户余额</a-button>
      </div>

      <s-table id="table" ref='table' rowKey='id' size='default' :columns='columns' :scroll='{ x: 1500}' :data='loadData'>
        <span slot='ledgerStatus' slot-scope='text, record'>
          <template v-if="text == 0">
            <a-space>
              <a @click='initialDeliver1(record)'>待审</a>
            </a-space>
          </template>
          <template v-else>
            {{ text == 2 ? "异常" : text == 1 ? "正常" : "未知" }}
          </template>
        </span>
      </s-table>
    </div>

    <a-modal title='审核' :destroyOnClose='true' :maskClosable='true'
             :visible='deliver1.visible' @cancel='()=>this.deliver1.visible=false'
             ok-text='确认' cancel-text='取消' @ok='handleDeliver1'>
      <a-form-model ref='form1' :model='deliver1.form' :rules='deliver1.rules' :labelCol='{ span: 6 }' :wrapperCol='{ span: 18 }'>
        <a-form-model-item label='审核结果' prop='ledgerStatus'>
          <a-radio-group name="radioGroup" v-model="deliver1.form.ledgerStatus">
            <a-radio :value="1">
              正常
            </a-radio>
            <a-radio :value="2">
              异常
            </a-radio>
          </a-radio-group>
        </a-form-model-item>
        <a-form-model-item v-if="deliver1.form.ledgerStatus == 2" label='异常说明' prop='ledgerRemark'>
          <a-input v-model='deliver1.form.ledgerRemark' />
        </a-form-model-item>
      </a-form-model>
    </a-modal>

    <a-modal title='账户余额' :footer="null" :destroyOnClose='true' :maskClosable='true'
            :visible='deliver2.visible' @cancel='()=>this.deliver2.visible=false'>
      <a-descriptions :column="10">
        <template v-for="(item, index) in deliver2.form.accountList">
          <a-descriptions-item :key="index + '_1'" :span="7">{{item.bankAccountName}}</a-descriptions-item>
          <a-descriptions-item :key="index + '_2'" :span="3">余额：{{item.amount}}</a-descriptions-item>
        </template>
      </a-descriptions>
    </a-modal>
  </page-header-wrapper>
</template>

<script>
import { STable, Ellipsis, ChartCard } from '@/components'
import { getOrderLedgerStatistics, queryMemberOrders, putMemberOrder, getOrderLedgerAccount } from '@/api/member-order'
import { queryAccount } from '@/api/account'
import moment from 'moment'
import { getUserTypeName } from '@/utils/util'


const columns = [
  {
    width: 150,
    title: '订单号',
    dataIndex: 'id',
    scopedSlots: { customRender: 'id' }
  },  
  {
    width: 180,
    title: '商户公司名称',
    dataIndex: 'merchantName',
    scopedSlots: { customRender: 'merchantName' }
  },
  {
    width: 100,
    title: '应收金额',
    dataIndex: 'originalMerchantAmount',
    scopedSlots: { customRender: 'originalMerchantAmount' }
  },
  {
    width: 100,
    title: '现金',
    dataIndex: 'merchantAmount',
    scopedSlots: { customRender: 'merchantAmount' }
  },
  {
    width: 100,
    title: '折扣',
    dataIndex: 'merchantDiscount',
    scopedSlots: { customRender: 'merchantDiscount' }
  },
  {
    width: 100,
    title: '收款方式',
    dataIndex: 'ledgerPaymentName',
    scopedSlots: { customRender: 'ledgerPaymentName' }
  },
  {
    width: 100,
    title: '获得折扣',
    dataIndex: 'ledgerOrderDiscountAmount',
    scopedSlots: { customRender: 'ledgerOrderDiscountAmount' }
  },
  {
    width: 180,
    title: '总销公司',
    dataIndex: 'bankAccountName',
    scopedSlots: { customRender: 'bankAccountName' }
  },
  {
    width: 150,
    title: '收款时间',
    dataIndex: 'merchantPaymentTime',
    scopedSlots: { customRender: 'merchantPaymentTime' }
  },
  {
    width: 100,
    title: '审核状态',
    dataIndex: 'ledgerStatus',
    scopedSlots: { customRender: 'ledgerStatus' }
  },
  {
    width: 180,
    title: '异常说明',
    dataIndex: 'ledgerRemark',
    scopedSlots: { customRender: 'ledgerRemark' }
  },
]

const formatType = 'YYYY-MM-DD';
const baseStart = moment().startOf('month').format(formatType);
const baseEnd = moment().format(formatType);

export default {
  name: 'MonthCashUseList',
  components: {
    STable, Ellipsis, ChartCard
  },
  data() {
    return {
      // 筛选表单
      formatType,
      dateArr: [baseStart, baseEnd],
      form: {
        keyword: "",
        ledgerStatus:0,
        start: moment(baseStart).startOf('day').format(formatType + " HH:mm"),
        end: moment(baseEnd).endOf('day').format(formatType+ " HH:mm"),
      },
      loading: true,
      dashboard: {}, //订单统计数据
      // 表头
      columns,
      // 加载数据方法 必须为 Promise 对象
      queryParam: {merchantOrderStatus: 2},
      loadData: parameter => {
        let status =this.form.ledgerStatus;
        let st=this.form.start;
        let ed = this.form.end;
        let parm = Object.assign(parameter,{}, this.form);
        // if(status === 0){
        //   delete  parm.start;
        //   delete  parm.end;
        // }else{
        //   if(parm.start <'2023-09-01 00:00'){
        //     parm.start = '2023-09-01 00:00';
        //   }
        // }
        if(parm.start <'2023-09-01 00:00'){
          parm.start = '2023-09-01 00:00';
          if(parm.end<'2023-09-01 00:00'){
            parm.end='2023-09-01 00:00';
          }
        }
        //台账都是已经支付过的订单
        parm.merchantOrderStatus=2;
        return queryMemberOrders(parm)
          .then(datum => {
            return datum
          })
      },
      bankAccountList: [],
      deliver1: { visible: false, form: {}, rules: {
        ledgerStatus: [{ required: true, message: '请选择审核结果', trigger: 'change' }],
      }},
      deliver2: { 
        visible: false, 
        loading: false,
        form: {},
        rules: {},
      },
    }
  },
  created() {
    this.getOrderData();
    queryAccount({pageNum: 1, pageSize: 9999})
      .then(datum => {
        this.bankAccountList = datum
      })
  },
  methods: {
    moment,
    getUserTypeName,
    disabledDate(current) {
      return current && current > moment().endOf('day');
    },
    dateChange() {
      this.form.start =  moment(this.dateArr[0]).startOf('day').format(this.formatType + " HH:mm");
      this.form.end =  moment(this.dateArr[1]).endOf('day').format(this.formatType+ " HH:mm");
    },
    handleReset() {
      this.dateArr = [baseStart, baseEnd];
      this.form.start = moment(baseStart).startOf('day').format(this.formatType + " HH:mm");
      this.form.end = moment(baseEnd).endOf('day').format(this.formatType + " HH:mm");
      this.$refs.form.resetFields()
      this.$refs.table.refresh(true)
    },
    handleSubmit() {
      this.getOrderData();
      this.$refs.table.refresh(true)
    },
    getOrderData() {
      // debugger;
      // this.dateChange();


      let status =this.form.ledgerStatus;
      let st=this.form.start;
      let ed = this.form.end;
      let parm = Object.assign({}, this.form);
      // if(status === 0){
      //   delete  parm.start;
      //   delete  parm.end;
      // }else{
      //   if(parm.start <'2023-09-01 00:00'){
      //     parm.start = '2023-09-01 00:00';
      //   }
      // }
      if(parm.start <'2023-09-01 00:00'){
        parm.start = '2023-09-01 00:00';
        if(parm.end<'2023-09-01 00:00'){
          parm.end='2023-09-01 00:00';
        }
      }
      //台账都是已经支付过的订单
      parm.merchantOrderStatus=2;

      getOrderLedgerStatistics(parm).then(result => {
      // getOrderLedgerStatistics({}).then(result => {
        this.dashboard = result;
        this.loading = false;
      })
    },
    async initialDeliver2() {
      const result = await getOrderLedgerAccount()
      console.log(result);
      if (result && result.length > 0) {
        this.deliver2.form = {
          accountList: result
        }
        this.deliver2.loading = false
        this.deliver2.visible = true
      } else {
        this.$message.warning('暂无账号');
      }
    },
    initialDeliver1(record) {
      this.deliver1.form = {
        id: record.id,
        ledgerStatus: 1
      }
      this.deliver1.visible = true
      this.deliver1.loading = false
    },
    handleDeliver1() {
      this.$refs.form1.validate((result) => {
        console.log(result)
        if (result) {
          if (this.deliver1.form.ledgerStatus == 1) {
            this.deliver1.form.ledgerRemark = ""
          }
          this.deliver1.loading = true;
          putMemberOrder(this.deliver1.form).then(_result => {
            this.$message.success('操作成功')
            this.$refs.table.refresh(true)
            this.deliver1.visible = false
            this.deliver1.loading = false;
          }).catch(() => {
            this.deliver1.loading = false;
          })
        }
      })
    },
    getMonthFinalDay(year,month){
      let day='';
      if(year == null || year===''){
        year = new Date().getFullYear();
      }
      if(month == null || month===''){
        month = new Date().getMonth()+1;
      }
      day = new Date(new Date(year,month).setDate(0)).getDate();
      return year+"-"+month+"-"+day;

    },
    getDataByMonth(tabs){
      let start = "";
      let end = "";
      if(tabs === "1"){
        //本月
        let year = new Date().getFullYear();
        let month = new Date().getMonth()+1;
        end = this.getMonthFinalDay(year,month);
        start = year + "-" + month + "-" + "01";
      }else{
        //上月
        let lastMonth = this.getLastMonth();
        let monthYear = lastMonth.split("-");
        let year = monthYear[0];
        let month = monthYear[1];
        end = this.getMonthFinalDay(year,month);
        start = year + "-" + month + "-" + "01";
      }
      end = end + " 23:59";
      start = start + " 00:00";
      let arr={};
      arr.start=start;
      arr.end =end;
      return arr;
    }
  }
}
</script>
<style lang="less" scoped>
/deep/.order_count .ant-card-body {
  padding: 0 24px 3px !important;
  display: flex;
  flex-wrap: wrap;
}
.card_title {
  width: 100%;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  line-height: 22px;
}
.card_content {
  width: 100%;
  color: #E62734;
  font-size: 18px;
  line-height: 22px;
}
</style>
