<template>
  <page-header-wrapper style="margin: unset;">    
    <a-form-model ref='form' :model='form' layout='inline'>
      <a-form-model-item prop='keyword'>
        <a-input v-model='form.keyword' style="width: 300px" placeholder="负责人/手机号" />
      </a-form-model-item>
      <a-form-model-item prop='areaShortName'>
        <a-input v-model='form.areaShortName' style="width: 300px" placeholder="分公司简称" />
      </a-form-model-item>
      <a-form-model-item prop='start'>
        <a-range-picker
          v-model="dateArr"
          :format="[formatType, formatType]"
          :show-time="{
            hideDisabledOptions: true
          }"
          :disabled-date="disabledDate"
          @change="dateChange"
        />
      </a-form-model-item>
      <a-form-model-item>
        <a-button type='primary' @click='handleSubmit'>
          查询
        </a-button>
      </a-form-model-item>
      <a-form-model-item>
        <a-button @click='handleReset'>
          重置
        </a-button>
      </a-form-model-item>
    </a-form-model>
    <div>
      <div class='table-operator' style="margin-top: 20px">
        <a-button type='primary' icon='reload' @click='$refs.table.refresh(true)'>刷新</a-button>
        <a-button type='primary' icon='table' :loading="exportLoading" @click='exportToExcelAll'>导出全部</a-button>
        <span style="margin-left: 30px;"></span>
        <span style="margin-right: 20px;">权益业绩合计：{{this.ext1}}</span>
      </div>

      <s-table id="table" ref='table' rowKey='id' size='default' :columns='columns' :scroll='{ x: 1500}' :data='loadData'>
        <span slot='merchantShortName' slot-scope='text, record'>
          <template>
            <a @click='handleEdit(record)'>
              {{ text || "未知" }}
            </a>
          </template>
        </span>
        <span slot='masterName' slot-scope='text, record'>
          <template>
            <span class="color-red" v-if="record.shareholder=='1'" >{{ text }}</span>
            <span v-else>{{ text }}</span>
          </template>
        </span>
<!--        <span slot='status' slot-scope='text,record'>-->
<!--          <template>-->
<!--            <a-badge dot v-if='text=="1"' :status='"success"' :text='"稳定"' @click="showMemberList(record)" style="cursor: pointer;"></a-badge>-->
<!--            <a-badge dot v-else-if='text=="0"' :status='"error"' :text='"不稳定"' @click="showMemberList(record)" style="cursor: pointer;"></a-badge>-->
<!--          </template>-->
<!--        </span>-->
      </s-table>
    </div>

    <a-modal title='订单时间' :destroyOnClose='true' :maskClosable='true'
             :visible='deliver1.visible' @cancel='()=>this.deliver1.visible=false' :width="900"
             ok-text='确定' cancel-text='取消'  :confirmLoading='deliver1.loading'>
      <a-form-model ref='form1'  :rules='deliver1.rules'  :labelCol='{ span: 8 }' :wrapperCol='{ span: 16 }'>
        <a-table :dataSource="loadUserData" :columns="columns12" :pagination="false" :loading="tableLoading">
          <span slot='masterName' slot-scope='text, record'>
              <template>

                <a v-if="record.memberList.length>0" @click='showMemberChildList(record.memberList)'>
                  {{ text || "未知" }}({{record.memberList.length}})
                </a>
                <span v-else >
                  {{ text || "未知" }}({{record.memberList.length}})
                </span>
              </template>
        </span>
        </a-table>
      </a-form-model>
      <template slot="footer">
        <a-button  @click="closeBigWin">关闭</a-button>
      </template>
    </a-modal>

    <a-modal title='订单时间' :destroyOnClose='true' :maskClosable='true'
             :visible='deliver2.visible'  :width="900" :confirmLoading='deliver2.loading'>
      <a-form-model ref='form2'  :rules='deliver2.rules'  :labelCol='{ span: 8 }' :wrapperCol='{ span: 16 }'>
        <a-table :dataSource="loadUserData2" :columns="columns12" :pagination="false" :loading="tableLoading2">
          <span slot='masterName' slot-scope='text, record'>
              <template v-if="record.shareholder=='1'">
                <span class="color-red">{{ text || "未知" }} </span>
              </template>
              <template v-else>
                <span>{{ text || "未知" }} </span>
              </template>
        </span>
        </a-table>
      </a-form-model>
      <template slot="footer">
        <a-button  @click="closeWin">返回上一级</a-button>
      </template>
    </a-modal>
  </page-header-wrapper>
</template>

<script>
import { STable, Ellipsis, ChartCard } from '@/components'
import { queryPerformancePresident,getLocalUserData } from '@/api/performance.js'
import moment from 'moment'
import FileSaver from 'file-saver'
import XLSX from 'xlsx'


const columns = [
  {
    width: 150,
    title: '商户简称',
    dataIndex: 'merchantShortName',
    scopedSlots: { customRender: 'merchantShortName' }
  },
  {
    width: 100,
    title: '负责人姓名',
    dataIndex: 'masterName',
    scopedSlots: { customRender: 'masterName' }
  },
  {
    width: 150,
    title: '手机号',
    dataIndex: 'mobile',
    scopedSlots: { customRender: 'mobile' }
  },
  {
    width: 100,
    title: '权益业绩',
    dataIndex: 'totalAmount',
    scopedSlots: { customRender: 'totalAmount' }
  },
  // {
  //   width: 100,
  //   title: '服务商',
  //   dataIndex: 'spreaderMasterName',
  //   scopedSlots: { customRender: 'spreaderMasterName' }
  // },
  // {
  //   width: 100,
  //   title: '管理商',
  //   dataIndex: 'spreaderMasterName1',
  //   scopedSlots: { customRender: 'spreaderMasterName1' }
  // },
  // {
  //   width: 100,
  //   title: '运营商',
  //   dataIndex: 'spreaderMasterName2',
  //   scopedSlots: { customRender: 'spreaderMasterName2' }
  // },
  {
    width: 100,
    title: '分公司简称',
    dataIndex: 'areaShortName',
    scopedSlots: { customRender: 'areaShortName' }
  },
]

const columns12= [
  {
    title: '姓名',
    dataIndex: 'masterName',
    key: 'masterName',
    scopedSlots: { customRender: 'masterName' }
  },
  {
    title: '授权编号',
    dataIndex: 'authCode',
    key: 'authCode',
  },
  {
    title: '订单时间',
    dataIndex: 'orderTime',
    key: 'orderTime',
  },
]



const formatType = 'YYYY-MM-DD';
const baseStart = moment().startOf('month').format(formatType);
const baseEnd = moment().format(formatType);

export default {
  name: 'PerformancePresidentList',
  components: {
    STable, Ellipsis, ChartCard
  },
  data() {
    return {
      // 筛选表单
      formatType,
      dateArr: [baseStart, baseEnd],
      form: {
        keyword: "",
        start: moment(baseStart).startOf('day').format(formatType),
        end: moment(baseEnd).endOf('day').format(formatType),
      },
      loading: true,
      dashboard: {}, //订单统计数据
      // 表头
      columns,
      columns12,
      total: 0,
      deliver1: {
        visible: false,
        loading: false
      },
      deliver2: {
        visible: false,
        loading: false
      },
      tableLoading: true,
      tableLoading2:true,
      ext1:0,//运营业绩合计
        // 加载数据方法 必须为 Promise 对象
      queryParam: {},
      loadData: parameter => {
        parameter.pageNum = parameter.current;
        parameter.pageSize = parameter.size;
        return queryPerformancePresident(Object.assign(parameter, this.queryParam, this.form))
          .then(datum => {
            this.ext1 = datum.ext1;
            this.total = datum.total;
            this.$emit("setPresidentTitle", this.total);
            return datum
          })
      },
      loadUserData:[],
      loadUserData2:[],
      exportLoading: false,
    }
  },
  created() {

  },
  methods: {
    closeWin(){
      this.deliver1.visible=true;
      this.deliver2.visible=false;
      this.tableLoading2 = true;
    },
    closeBigWin(){
      this.deliver1.visible=false;
    },
    showMemberChildList(record){

      this.deliver1.visible=false;
      this.deliver2.visible=true;
      this.loadUserData2 =   record;
      this.tableLoading2 = false;
    },
    showMemberList(record){
      this.deliver1.visible = true

      getLocalUserData({id:record.id}).then(result => {
        // debugger;
          this.tableLoading = false;
          this.loadUserData =   result;

      });

    },
    moment,
    disabledDate(current) {
      return current && current > moment().endOf('day');
    },
    dateChange() {
      this.form.start =  moment(this.dateArr[0]).startOf('day').format(this.formatType);
      this.form.end =  moment(this.dateArr[1]).endOf('day').format(this.formatType);
    },
    handleReset() {
      this.dateArr = [baseStart, baseEnd];
      this.form.start = moment(baseStart).startOf('day').format(this.formatType);
      this.form.end = moment(baseEnd).endOf('day').format(this.formatType);
      this.$refs.form.resetFields()
      this.$refs.table.refresh(true)
    },
    handleSubmit() {
      this.$refs.table.refresh(true)
    },
    handleEdit(record) {
      this.$router.push({ path: '/performance/president/edit', query: { id: record.id, start: this.form.start, end: this.form.end,
          spreader:record.spreader,
          spreader1:record.spreader1,
          spreader2:record.spreader2,
          totalAmount:record.totalAmount,
          shareholder:record.shareholder,
          masterName:record.masterName,
          spreaderMasterName:record.spreaderMasterName,
          spreaderMasterName1:record.spreaderMasterName1,
          spreaderMasterName2:record.spreaderMasterName2 } })
    },
    exportToExcelAll () {
      this.exportLoading = true;
      queryPerformancePresident(Object.assign({pageNum: 1, pageSize: 9999}, this.queryParam, this.form)).then(result => {
        if (result.records.length > 0) {
          const header = {
            'merchantShortName': '商户简称',
            'masterName': '负责人',
            'mobile': '手机号',
            'totalAmount': '运营业绩',
            'spreaderMasterName': '服务商',
            'spreaderMasterName1': '运营商',
          };
          
          const data = result.records.map(item => {
            const msg = Object.assign({}, header);
            for (const key in msg) {
              if (Object.hasOwnProperty.call(msg, key)) {
                msg[key] = item[key];
              }
            }
            return msg
          })
          data.unshift(header);
          let wb = XLSX.utils.book_new();
          let ws = XLSX.utils.json_to_sheet(data, { header: Object.keys(header), skipHeader: true });
          wb.SheetNames = ['Sheet1'];
          wb.Sheets['Sheet1'] = ws;
          let etout = XLSX.write(wb, {
            bookType: 'xlsx',
            bookSST: true,
            type: 'array'
          });
          try {
              FileSaver.saveAs(new Blob([etout], {
                  type: 'application/octet-stream'
              }), '运营业绩.xlsx');   //trade-publish.xlsx 为导出的文件名
          } catch (e) {
              console.log(e, etout) ;
          }
        } else {
          this.$message.warning('暂无数据')
        }
        this.exportLoading = false;
      });
    },
  }
}
</script>
<style lang="less" scoped>
/deep/.ant-page-header{
  display: none;
}
/deep/.order_count .ant-card-body {
  padding: 0 24px 3px !important;
  display: flex;
  flex-wrap: wrap;
}
.card_title {
    width: 100%;
    color: rgba(0, 0, 0, 0.45);
    font-size: 14px;
    line-height: 22px;
}
.card_content {
  width: 100%;
  font-size: 18px;
  line-height: 30px;
  height: 30px;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  white-space: nowrap;
  margin-top: 4px;
  color: #000;
}
.order_count .card_content {
  width: 50%;
}
</style>
