<template>
  <page-header-wrapper :title='this.$route.meta.title' >
    <a-card title="商户信息" :bordered='false'>
      <span slot='extra'>
        <template>
          <a-space style="margin-left: 20px">
            <a @click='initialDeliver2(profile)'>备注</a>
          </a-space>
          <a-space style="margin-left: 20px">
            <a @click='initialDeliver(profile)'>修改</a>
          </a-space>
        </template>
      </span>
      <a-descriptions>
        <a-descriptions-item label='商户简称'>{{ profile.merchantShortName }}</a-descriptions-item>
        <a-descriptions-item label='商户名称'>{{ profile.merchantName }}</a-descriptions-item>
        <a-descriptions-item label='营业执照号码'>{{ profile.businessLicenseNo }}</a-descriptions-item>
        <a-descriptions-item label='授权编号'>{{profile.authCode}}</a-descriptions-item>
        <a-descriptions-item label='负责人'>{{profile.masterName}}</a-descriptions-item>
        <a-descriptions-item label='性别'>{{ profile.gender == "male" ? "男" : profile.gender == "female" ? "女" : "" }}</a-descriptions-item>
        <a-descriptions-item label='身份证号'>{{ profile.identifier }}</a-descriptions-item>
        <a-descriptions-item label='手机号'>{{ profile.mobile }}</a-descriptions-item>
        <a-descriptions-item label='审批时间'>{{ profile.merchantAuditTime }}</a-descriptions-item>
        <a-descriptions-item label='联系地址'>{{ profile.address }}</a-descriptions-item>
        <a-descriptions-item label='地区'>{{ profile.location }}</a-descriptions-item>
        <a-descriptions-item label='总销'>{{profile.merchantBankAccountName}}</a-descriptions-item>
        <a-descriptions-item label='代理期限'>{{profile.merchantStart ? (profile.merchantStart + ' 至 ' + profile.merchantEnd) : ''}}</a-descriptions-item>
        <a-descriptions-item label='股东身份'>
          <a-badge dot v-if='profile.shareholder == 1' :status='"success"' :text='"开启"'></a-badge>
          <a-badge dot v-else :status='"error"' :text='"关闭"'></a-badge>
        </a-descriptions-item>
        <a-descriptions-item label='仓库权限'>
          <a-badge dot v-if='profile.depositoryPermission == 1' :status='"success"' :text='"开启"'></a-badge>
          <a-badge dot v-else :status='"error"' :text='"关闭"'></a-badge>
        </a-descriptions-item>
        <a-descriptions-item label='订单跟踪权限'>
          <a-badge dot v-if='profile.followPermission == 1' :status='"success"' :text='"开启"'></a-badge>
          <a-badge dot v-else :status='"error"' :text='"关闭"'></a-badge>
        </a-descriptions-item>
        <a-descriptions-item label='活动权限'>
          <a-badge dot v-if='profile.activityPermission == 1' :status='"success"' :text='"开启"'></a-badge>
          <a-badge dot v-else :status='"error"' :text='"关闭"'></a-badge>
        </a-descriptions-item>
        <a-descriptions-item label='活动申请'>
          <a-badge dot v-if='profile.applyActivityPermission == 1' :status='"success"' :text='"开启"'></a-badge>
          <a-badge dot v-else :status='"error"' :text='"关闭"'></a-badge>
        </a-descriptions-item>
        <a-descriptions-item label='报名明细'>
          <a-badge dot v-if='profile.signOrderPermission == 1' :status='"success"' :text='"开启"'></a-badge>
          <a-badge dot v-else :status='"error"' :text='"关闭"'></a-badge>
        </a-descriptions-item>
        <a-descriptions-item label='身份证正面'>
          <img v-if='profile.idFront' :src='profile.idFront' width='100px' height="100px" preview="0">
        </a-descriptions-item>
        <a-descriptions-item label='身份证反面'>
          <img v-if='profile.idBack' :src='profile.idBack' width='100px' height="100px" preview="1">
        </a-descriptions-item>
        <a-descriptions-item label='负责人照片'>
          <img v-if='profile.masterImg' :src='profile.masterImg' width='100px' height="100px" preview="2">
        </a-descriptions-item>
        <a-descriptions-item label='营业执照正面'>
          <img v-if='profile.businessLicenseImg' :src='profile.businessLicenseImg' width='100px' height="100px" preview="3">
        </a-descriptions-item>
        <a-descriptions-item label='食品经营许可证'>
          <img v-if='profile.foodLicenseImg' :src='profile.foodLicenseImg' width='100px' height="100px" preview="4">
        </a-descriptions-item>
        <a-descriptions-item label='授权证书'>
          <img v-if='profile.authLicenseImg' :src='profile.authLicenseImg' width='100px' height="100px" preview="5">
        </a-descriptions-item>
        <a-descriptions-item label='公章'>
          <img v-if='profile.merchantSeal' :src='profile.merchantSeal' width='100px' height="100px" preview="5">
        </a-descriptions-item>
      </a-descriptions>
      <s-table id="table" ref='table' rowKey='id' size='default' :columns='columns' :scroll='{ x: 1000}' :data='loadData' :showPagination="false" :pageSize="9999">
      </s-table>
    </a-card>
<!--    <a-card v-if="profile.modifyRecordList.length > 0" :bordered='false' style="margin-top: 20px;">-->
<!--      <a-descriptions title='账号更换'>-->
<!--        <a-descriptions-item label='原账号'>{{profile.modifyRecordList[0].oldMobile}}</a-descriptions-item>-->
<!--        <a-descriptions-item label='更换原因'>{{profile.modifyRecordList[0].reason}}</a-descriptions-item>-->
<!--        <a-descriptions-item label='更换时间'>{{ profile.modifyRecordList[0].createTime }}</a-descriptions-item>-->
<!--      </a-descriptions>-->
<!--    </a-card>-->
    <a-card title='支付渠道' style="margin-top: 20px;" :bordered='false'>
      <span slot='extra'>
        <template>
          <a-space style="margin-left: 20px">
                <template v-if="profile.paymentChannelType">
                    <a-popconfirm title='确定解除绑定吗' ok-text='确认' cancel-text='取消'
                                  @confirm='unBindMechant(profile)'>
                        <a href='#'>解绑</a>
                    </a-popconfirm>
                </template>
             <a @click='initialDeliver5(profile)' style="margin-right: 20px;">绑定</a>
          </a-space>
        </template>
      </span>
      <a-descriptions>
<!--        <a-descriptions-item label='微信商户号'>{{ profile.weixinPaymentNo }}</a-descriptions-item>-->
        <a-descriptions-item label='账户名称'>{{ profile.paymentChannelName }}</a-descriptions-item>
        <a-descriptions-item label='支付渠道'>
          <span v-if="profile.paymentChannelType=='1'">微信</span>
          <span v-if="profile.paymentChannelType=='2'">乐刷门店</span>
          <span v-if="profile.paymentChannelType=='3'">乐刷渠道号</span>
        </a-descriptions-item>
        <a-descriptions-item label='商户号'>{{ profile.weixinPaymentNo }}</a-descriptions-item>
        <!-- <a-descriptions-item label='小程序appSecrect'>{{ profile.weixinPaymentSecret }}</a-descriptions-item> -->
      </a-descriptions>
    </a-card>
    <a-card title='商户协议' style="margin-top: 20px;" :bordered='false' :loading="xuQianLoading">
      <span slot='extra'>
        <template>
          <a-space style="margin-right: 20px;">
<!--            <a v-if="profile.merchantStatus == 5 || profile.merchantStatus == 4 || profile.merchantStatus == 3" @click='handleStatus(2)'>续签-->
             <a v-if="profile.merchantStatus == 5 || profile.merchantStatus == 4 || profile.merchantStatus == 3" >
                 <a-popconfirm title='确定续签吗？' ok-text='确认' cancel-text='取消'  @confirm='handleStatus(2)' :disabled="xuQianDis">
                  续签
                 </a-popconfirm>
            </a>
          </a-space>
        </template>
      </span>
      <a-descriptions :column="5">
        <a-descriptions-item label='代理状态'>{{ getMerchantStatus(profile.merchantStatus) }}</a-descriptions-item>
        <a-descriptions-item label='代理期限' :span="4">{{profile.merchantStart ? (profile.merchantStart + ' 至 ' + profile.merchantEnd) : ''}}</a-descriptions-item>
        <a-descriptions-item label='经销协议'>
          <img v-if='profile.merchantContractImg' :src='profile.merchantContractImg' width='100px' height="100px" preview="6">
        </a-descriptions-item>
        <a-descriptions-item label='经营承诺'>
          <img v-if='profile.managementCommitmentImg' :src='profile.managementCommitmentImg' width='100px' height="100px" preview="7">
        </a-descriptions-item>
        <a-descriptions-item label='网上商城服务协议'>
          <img v-if='profile.serviceAgreementImg' :src='profile.serviceAgreementImg' width='100px' height="100px" preview="8">
        </a-descriptions-item>
        <a-descriptions-item label='规范准则'>
          <img v-if='profile.normativeCriteriaImg' :src='profile.normativeCriteriaImg' width='100px' height="100px" preview="9">
        </a-descriptions-item>
        <a-descriptions-item label='业务授权函'>
          <img v-if='profile.businessAuthImg' :src='profile.businessAuthImg' width='100px' height="100px" preview="10">
        </a-descriptions-item>
      </a-descriptions>

      <a-descriptions :column="5" style="margin-top: 30px;width: 20%">
      <template v-for="(item, index) in record">
        <a-descriptions-item v-if="item.opUsername" :key="'code' + index" label='续签时间' :span="3">{{item.updateTime}}</a-descriptions-item>
        <a-descriptions-item v-if="item.opUsername" :key="'name' + index" label='操作人' :span="2">{{item.opUsername}}</a-descriptions-item>
      </template>
      </a-descriptions>
    </a-card>
    <a-card title='扫码转账收款' style="margin-top: 20px;" :bordered='false'>
      <span slot='extra'>
        <template>
          <a-space style="margin-left: 20px">
            <a @click='initialDeliver3(profile)' style="margin-right: 20px;">修改</a>
          </a-space>
        </template>
      </span>
      <a-descriptions :column="4">
        <a-descriptions-item label='收款二维码'>
          <img v-if='profile.merchantQrImgUrl' :src='profile.merchantQrImgUrl' width='100px' height="100px" preview="11">
        </a-descriptions-item>
        <a-descriptions-item label='银行账号'>{{ profile.merchantAccountNo||'' }}</a-descriptions-item>
        <a-descriptions-item label='开户行'>{{ profile.merchantAccountBank||'' }}</a-descriptions-item>
        <a-descriptions-item label='户名'>{{ profile.merchantAccountName||'' }}</a-descriptions-item>
      </a-descriptions>
    </a-card>

    <a-modal title='修改信息' :destroyOnClose='true' :maskClosable='true'
             :visible='deliver.visible' @cancel='()=>this.deliver.visible=false'
             ok-text='确认' cancel-text='取消' @ok='handleDeliver' :width='800'>
      <a-form-model ref='form' :model='deliver.form' :rules='deliver.rules' :labelCol='{ span: 6 }' :wrapperCol='{ span: 18 }'>
        <a-form-model-item label='商户简称' prop='merchantShortName'>
          <a-input v-model='deliver.form.merchantShortName' />
        </a-form-model-item>
        <a-form-model-item label='商户名称' prop='merchantName'>
          <a-input v-model='deliver.form.merchantName' />
        </a-form-model-item>
        <a-form-model-item label='营业执照号码' prop='businessLicenseNo'>
          <a-input v-model='deliver.form.businessLicenseNo' />
        </a-form-model-item>
        <a-form-model-item label='授权编号' prop='authCode' :validateStatus="deliver.authCodeStatus" :help="deliver.authCodeHelp">
          <a-input v-model='deliver.form.authCode' @change="onAuthCodeChange" @blur="onAuthCodeBlur" />
        </a-form-model-item>
        <a-form-model-item label='负责人' prop='masterName'>
          <a-input v-model='deliver.form.masterName' />
        </a-form-model-item>
        <a-form-model-item label='性别' prop='gender'>
          <a-select v-model="deliver.form.gender" placeholder="请选择">
            <a-select-option value="male">
              男
            </a-select-option>
            <a-select-option value="female">
              女
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label='身份证号' prop='identifier'>
          <a-input v-model='deliver.form.identifier' />
        </a-form-model-item>
        <a-form-model-item label='身份证地址' prop='idAddress'>
          <a-input v-model='deliver.form.idAddress' />
        </a-form-model-item>
        <a-form-model-item label='地区' prop='location'>
          <a-input v-model='deliver.form.location' />
        </a-form-model-item>
        <a-form-model-item label='总销' prop='merchantBankAccountId'>
          <a-select v-model='deliver.form.merchantBankAccountId' placeholder="请选择">
            <a-select-option v-for='item in acountList' :key='item.id' :value="item.id">
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label='股东身份' prop='shareholder' v-if="deliver.form.type=='president' || deliver.form.type=='center'">
          <a-switch :checked="deliver.form.shareholder == 1" @change="(value) => deliver.form.shareholder = value ? 1 : 0" />

        </a-form-model-item>

        <a-form-model-item label='仓库权限' prop='depositoryPermission'>
          <a-switch :checked="deliver.form.depositoryPermission == 1" @change="(value) => deliver.form.depositoryPermission = value ? 1 : 0" />
<!--          {{deliver.form.type}}111-->
        </a-form-model-item>
        <a-form-model-item label='订单跟踪权限' prop='followPermission'>
          <a-switch :checked="deliver.form.followPermission == 1" @change="(value) => deliver.form.followPermission = value ? 1 : 0" />
        </a-form-model-item>
        <a-form-model-item label='活动权限' prop='activityPermission'>
          <a-switch :checked="deliver.form.activityPermission == 1" @change="(value) => deliver.form.activityPermission = value ? 1 : 0" />
        </a-form-model-item>
        <a-form-model-item label='活动申请' prop='applyActivityPermission'>
          <a-switch :checked="deliver.form.applyActivityPermission == 1" @change="(value) => deliver.form.applyActivityPermission = value ? 1 : 0" />
        </a-form-model-item>
        <a-form-model-item label='报名明细' prop='signOrderPermission'>
          <a-switch :checked="deliver.form.signOrderPermission == 1" @change="(value) => deliver.form.signOrderPermission = value ? 1 : 0" />
        </a-form-model-item>


        <a-row>
          <a-col :span="12">
            <a-form-model-item :labelCol='{ span: 12 }' :wrapperCol='{ span: 12 }' label='授权证书' prop='authLicenseImg'>
              <a-upload
                list-type='picture-card'
                :file-list="deliver.form.authLicenseImg ? [{
                  uid: '-1',
                  name: 'authLicenseImg',
                  status: 'done',
                  url: deliver.form.authLicenseImg,
                }] : []"
                :customRequest='(event) => {handleUpload(event, "authLicenseImg")}'
                :remove='() => deliver.form.authLicenseImg = ""'>
                <div v-if="!deliver.form.authLicenseImg">
                  <a-icon :type="loading ? 'loading' : 'plus'" />
                  <div class="ant-upload-text">上传</div>
                </div>
              </a-upload>
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item :labelCol='{ span: 12 }' :wrapperCol='{ span: 12 }' label='负责人照片' prop='masterImg'>
              <a-upload
                list-type='picture-card'
                :file-list="deliver.form.masterImg ? [{
                  uid: '-1',
                  name: 'masterImg',
                  status: 'done',
                  url: deliver.form.masterImg,
                }] : []"
                :customRequest='(event) => {handleUpload(event, "masterImg")}'
                :remove='() => deliver.form.masterImg = ""'>
                <div v-if="!deliver.form.masterImg">
                  <a-icon :type="loading ? 'loading' : 'plus'" />
                  <div class="ant-upload-text">上传</div>
                </div>
              </a-upload>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="12">
            <a-form-model-item :labelCol='{ span: 12 }' :wrapperCol='{ span: 12 }' label='身份证正面' prop='idFront'>
              <a-upload
                list-type='picture-card'
                :file-list="deliver.form.idFront ? [{
                  uid: '-1',
                  name: 'idFront',
                  status: 'done',
                  url: deliver.form.idFront,
                }] : []"
                :customRequest='(event) => {handleUpload(event, "idFront")}'
                :remove='() => deliver.form.idFront = ""'>
                <div v-if="!deliver.form.idFront">
                  <a-icon :type="loading ? 'loading' : 'plus'" />
                  <div class="ant-upload-text">上传</div>
                </div>
              </a-upload>
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item :labelCol='{ span: 12 }' :wrapperCol='{ span: 12 }' label='身份证反面' prop='idBack'>
              <a-upload
                list-type='picture-card'
                :file-list="deliver.form.idBack ? [{
                  uid: '-1',
                  name: 'idBack',
                  status: 'done',
                  url: deliver.form.idBack,
                }] : []"
                :customRequest='(event) => {handleUpload(event, "idBack")}'
                :remove='() => deliver.form.idBack = ""'>
                <div v-if="!deliver.form.idBack">
                  <a-icon :type="loading ? 'loading' : 'plus'" />
                  <div class="ant-upload-text">上传</div>
                </div>
              </a-upload>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="12">
            <a-form-model-item :labelCol='{ span: 12 }' :wrapperCol='{ span: 12 }' label='营业执照' prop='businessLicenseImg'>
              <a-upload
                list-type='picture-card'
                :file-list="deliver.form.businessLicenseImg ? [{
                  uid: '-1',
                  name: 'businessLicenseImg',
                  status: 'done',
                  url: deliver.form.businessLicenseImg,
                }] : []"
                :customRequest='(event) => {handleUpload(event, "businessLicenseImg")}'
                :remove='() => deliver.form.businessLicenseImg = ""'>
                <div v-if="!deliver.form.businessLicenseImg">
                  <a-icon :type="loading ? 'loading' : 'plus'" />
                  <div class="ant-upload-text">上传</div>
                </div>
              </a-upload>
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item :labelCol='{ span: 12 }' :wrapperCol='{ span: 12 }' label='食品经营许可证' prop='foodLicenseImg'>
              <a-upload
                list-type='picture-card'
                :file-list="deliver.form.foodLicenseImg ? [{
                  uid: '-1',
                  name: 'foodLicenseImg',
                  status: 'done',
                  url: deliver.form.foodLicenseImg,
                }] : []"
                :customRequest='(event) => {handleUpload(event, "foodLicenseImg")}'
                :remove='() => deliver.form.foodLicenseImg = ""'>
                <div v-if="!deliver.form.foodLicenseImg">
                  <a-icon :type="loading ? 'loading' : 'plus'" />
                  <div class="ant-upload-text">上传</div>
                </div>
              </a-upload>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="12">
            <a-form-model-item :labelCol='{ span: 12 }' :wrapperCol='{ span: 12 }' label='公章' prop='merchantSeal'>
              <a-upload
                list-type='picture-card'
                :file-list="deliver.form.merchantSeal ? [{
                  uid: '-1',
                  name: 'merchantSeal',
                  status: 'done',
                  url: deliver.form.merchantSeal,
                }] : []"
                :customRequest='(event) => {handleUpload(event, "merchantSeal")}'
                :remove='() => deliver.form.merchantSeal = ""'>
                <div v-if="!deliver.form.merchantSeal">
                  <a-icon :type="loading ? 'loading' : 'plus'" />
                  <div class="ant-upload-text">上传</div>
                </div>
              </a-upload>
            </a-form-model-item>
          </a-col>
          <a-col :span="12"></a-col>
        </a-row>
      </a-form-model>
    </a-modal>

    <a-modal title='修改商户信息' :destroyOnClose='true' :maskClosable='true'
             :visible='deliver1.visible' @cancel='()=>this.deliver1.visible=false'
             ok-text='确认' cancel-text='取消' @ok='handleDeliver1' :width='800'>
      <a-form-model ref='form1' :model='deliver1.form' :rules='deliver1.rules' :labelCol='{ span: 6 }' :wrapperCol='{ span: 18 }'>
        <a-form-model-item label='微信商户号' prop='weixinPaymentNo'>
          <a-input v-model='deliver1.form.weixinPaymentNo' />
        </a-form-model-item>
        <!-- <a-form-model-item label='小程序appSecrect' prop='weixinPaymentSecret'>
          <a-input v-model='deliver1.form.weixinPaymentSecret' />
        </a-form-model-item> -->
      </a-form-model>
    </a-modal>

    <a-modal title='支付渠道绑定' :destroyOnClose='true' :maskClosable='true'
             :visible='deliver5.visible' @cancel='()=>this.deliver5.visible=false'
             ok-text='确认' cancel-text='取消' @ok='handleDeliver5' :width='800'>
      <a-form-model ref='form5' :model='deliver5.form' :rules='deliver5.rules' :labelCol='{ span: 6 }' :wrapperCol='{ span: 18 }'>
        <a-form-model-item label='支付渠道' prop='payChannel'>
            <a-select v-model="deliver5.form.payChannel" @change="payChannelChange" placeholder="请选择">
              <a-select-option :value=1>
                微信
              </a-select-option>
              <a-select-option :value=2>
                乐刷门店
              </a-select-option>
              <a-select-option :value=3>
                乐刷渠道号
              </a-select-option>
            </a-select>
        </a-form-model-item>
<!--        <a-form-model-item label='模式' prop='model' v-if="deliver5.form.payChannel==='2'">-->
<!--          <a-select v-model="deliver5.form.model" @change="payChannelChange" placeholder="请选择" >-->
<!--            <a-select-option value="1" selected>-->
<!--              渠道号模式-->
<!--            </a-select-option>-->
<!--            <a-select-option value="2">-->
<!--              商户号模型-->
<!--            </a-select-option>-->
<!--          </a-select>-->
<!--        </a-form-model-item>-->
        <a-form-model-item label='账户名称' prop='weixinPaymentNo'>
          <a-select
            v-model="deliver5.form.weixinPaymentNo"
            show-search
            placeholder="账户名称"
            :default-active-first-option='false'
            :filter-option='false'
            :allowClear='true'
            @search="handleChannelNameSearch"
            @change="handleChannelNameChange"
          >
            <a-select-option v-for='item in  payChannelList' :key='item.mchid'>
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
      </a-form-model>
    </a-modal>

    <a-modal title='添加备注' :destroyOnClose='true' :maskClosable='true'
             :visible='deliver2.visible' @cancel='()=>this.deliver2.visible=false'
             ok-text='确认' cancel-text='取消' @ok='handleDeliver2' :width='800'>
      <a-form-model ref='form1' :model='deliver2.form' :rules='deliver2.rules' :labelCol='{ span: 6 }' :wrapperCol='{ span: 18 }'>
        <a-form-model-item label='备注内容' prop='operateDes'>
          <a-input v-model='deliver2.form.operateDes' />
        </a-form-model-item>
      </a-form-model>
    </a-modal>

    <a-modal title='扫码转账收款' :destroyOnClose='true' :maskClosable='true'
             :visible='deliver3.visible' @cancel='()=>this.deliver3.visible=false'
             ok-text='确认' cancel-text='取消' @ok='handleDeliver3' :width='800'>
      <a-form-model ref='form3' :model='deliver3.form' :rules='deliver3.rules' :labelCol='{ span: 6 }' :wrapperCol='{ span: 18 }'>
        <a-form-model-item :labelCol='{ span: 12 }' :wrapperCol='{ span: 12 }' label='收款二维码' prop='merchantQrImgUrl'>
          <a-upload
            list-type='picture-card'
            :file-list="deliver3.form.merchantQrImgUrl ? [{
              uid: '-1',
              name: 'merchantQrImgUrl',
              status: 'done',
              url: deliver3.form.merchantQrImgUrl,
            }] : []"
            :customRequest='(event) => {handleUpload3(event, "merchantQrImgUrl")}'
            :remove='() => deliver3.form.merchantQrImgUrl = ""'>
            <div v-if="!deliver3.form.merchantQrImgUrl">
              <a-icon :type="loading ? 'loading' : 'plus'" />
              <div class="ant-upload-text">上传</div>
            </div>
          </a-upload>
        </a-form-model-item>
        <a-form-model-item label='银行账号' prop='merchantAccountNo'>
          <a-input v-model='deliver3.form.merchantAccountNo' />
        </a-form-model-item>
        <a-form-model-item label='开户行' prop='merchantAccountBank'>
          <a-input v-model='deliver3.form.merchantAccountBank' />
        </a-form-model-item>
        <a-form-model-item label='户名' prop='merchantAccountName'>
          <a-input v-model='deliver3.form.merchantAccountName' />
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </page-header-wrapper>
</template>

<script>
import { STable, Ellipsis } from '@/components'
import { getMember, putMerchant, putMerchantRemark, queryMerchantRemark, checkAuthCode ,getContractById} from '@/api/member'
import {queryAccount, queryPayChannel} from '@/api/account'
import client from '@/config/oss.config.js'

const columns = [
  {
    width: 150,
    title: '备注内容',
    dataIndex: 'operateDes',
    scopedSlots: { customRender: 'operateDes' }
  },
  {
    width: 150,
    title: '备注时间',
    dataIndex: 'createTime',
    scopedSlots: { customRender: 'createTime' }
  },
  {
    width: 150,
    title: '备注人',
    dataIndex: 'operatorNickname',
    scopedSlots: { customRender: 'operatorNickname' }
  },
]

export default {
  name: 'MemberEdit',

  data() {
    return {
      id:'',
      loading: false,
      profile: {},
      acountList:[],
      record: [],
      payChannelList: [],
      deliver: { visible: false, form: {},
        rules: {
          merchantShortName: [{ required: true, message: '请填写商户简称', trigger: 'change' }],
          merchantName: [{ required: true, message: '请填写商户名称', trigger: 'change' }],
          businessLicenseNo: [{ required: true, message: '请填写营业执照号码', trigger: 'change' }],
          authCode: [{ required: true, message: '请填写授权编号', trigger: 'change' }],
          masterName: [{ required: true, message: '请填写负责人', trigger: 'change' }],
          gender: [{ required: true, message: '请选择性别', trigger: 'change' }],
          identifier: [
            { required: true, message: '请填写身份证', trigger: 'change' },
            { pattern: /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/, message: '请填写正确的身份证' }
          ],
          idAddress: [{ required: true, message: '请填写身份证地址', trigger: 'change' }],
          location: [{ required: true, message: '请填写地区', trigger: 'change' }],
          merchantBankAccountId: [{ required: true, message: '请选择总销', trigger: 'change' }],
          shareholder: [{ required: true, message: '请选择', trigger: 'change' }],
          depositoryPermission: [{ required: true, message: '请选择', trigger: 'change' }],
          followPermission: [{ required: true, message: '请选择', trigger: 'change' }],
          activityPermission: [{ required: true, message: '请选择', trigger: 'change' }],
          applyActivityPermission: [{ required: true, message: '请选择', trigger: 'change' }],
          signOrderPermission: [{ required: true, message: '请选择', trigger: 'change' }],
          authLicenseImg: [{ required: true, message: '请上传授权证书', trigger: 'change' }],
          masterImg: [{ required: true, message: '请上传负责人照片', trigger: 'change' }],
          idFront: [{ required: true, message: '请上传身份证正面', trigger: 'change' }],
          idBack: [{ required: true, message: '请上传身份证反面', trigger: 'change' }],
          // businessLicenseImg: [{ required: true, message: '请上传营业执照', trigger: 'change' }],
          // foodLicenseImg: [{ required: true, message: '请上传食品经营许可证', trigger: 'change' }]
        }
      },
      deliver1: { visible: false, form: {},
        rules: {
          payChannel: [{ required: true, message: '请填写支付渠道', trigger: 'change' }],
          payName: [{ required: true, message: '请填写账户名称', trigger: 'change' }],
        }
      },
      deliver2: { visible: false, form: {},
        rules: {
          operateDes: [{ required: true, message: '请填写备注', trigger: 'change' }],
        }
      },
      deliver3: { visible: false, form: {},
        rules: {
          merchantQrImgUrl: [{ required: true, message: '请上传收款二维码', trigger: 'change' }],
          merchantAccountNo: [{ required: true, message: '请填写银行账号', trigger: 'change' }],
          merchantAccountBank: [{ required: true, message: '请填写开户行', trigger: 'change' }],
          merchantAccountName: [{ required: true, message: '请填写户名', trigger: 'change' }],
        }
      },
      deliver4: { visible: false, form: {},
        rules: {
          weixinPaymentNo: [{ required: true, message: '请填写微信商户号', trigger: 'change' }],
          weixinPaymentSecret: [{ required: true, message: '请填写小程序appSecrect', trigger: 'change' }],
        }
      },
      deliver5: { visible: false, form: {},
        rules: {
          payChannel: [{ required: true, message: '请填写渠道', trigger: 'change' }],
          model: [{ required: true, message: '请选择模式', trigger: 'change' }],
          weixinPaymentNo: [{ required: true, message: '请填写账户', trigger: 'change' }],
        }
      },
      xuQianLoading:false,
      xuQianDis:false,
      columns,
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        return queryMerchantRemark(Object.assign(parameter, {id: this.$route.query.id}))
          .then(datum => {
            return {
              records: datum
            }
          })
      },
    }
  },
  computed: {
  },
  components: { STable, Ellipsis },
  activated(){
    this.id = this.$route.query.id
    this.handleInitial(this.id)
  },
  created() {
    this.id = this.$route.query.id
    this.handleInitial(this.id)
  },
  methods: {
    handleChannelNameSearch(name){
      let param = {current: 1, size: 50,name:name};
      if(this.deliver5.form.payChannel){
        param.paymentChannelType = this.deliver5.form.payChannel;
      }
      queryPayChannel(param).then(datum => {
        this.payChannelList = datum.records;
      })
    },
    payChannelChange(e){
      this.handleChannelNameSearch('');
      this.$set(this.deliver5.form, "weixinPaymentNo", '');
      // this.$set(this.deliver5.form, "mName", undefined);
    },
    handleChannelNameChange(e) {
      // let dat = this.payChannelList;
      // for(let i in dat){
      //   let item = dat[i];
      //   if(item.mchid === e){
      //     this.$set(this.deliver5.form, "payChannel", item.paymentChannelType+'');
      //   }
      // }
      this.$set(this.deliver5.form, "mName", e);

    },
    onAuthCodeChange() {
      delete this.deliver.authCodeHelp;
      delete this.deliver.authCodeStatus;
    },
    onAuthCodeBlur() {
      delete this.deliver.authCodeHelp;
      delete this.deliver.authCodeStatus;
      const { id } = this.$route.query
      checkAuthCode({id: id, authCode: this.deliver.form.authCode}).catch(e => {
        this.$set(this.deliver, "authCodeHelp", e.data.errorMsg)
        this.$set(this.deliver, "authCodeStatus", "error")
      })
    },
    getMerchantStatus(status) {
      let text = "其他";
      switch (Number(status)) {
        case 1:
          text = "待上传";
          break;
        case 2:
          text = "待签订";
          break;
        case 3:
          text = "生效中";
          break;
        case 4:
          text = "即将到期";
          break;
        case 5:
          text = "已到期";
          break;
        case 6:
          text = "暂停中";
          break;
        case 7:
          text = "已解除";
          break;
        default:
          break;
      }
      return text;
    },
    async handleUpload(event, key) {
      this.loading = true
      let result = await client.put('avatar/' + event.file.uid, event.file)
      this.$set(this.deliver.form, key, result.url)
      this.loading = false
      this.$refs.form.clearValidate()
    },
    async handleUpload3(event, key) {
      this.loading = true
      let result = await client.put('avatar/' + event.file.uid, event.file)
      this.$set(this.deliver3.form, key, result.url)
      this.loading = false
      this.$refs.form3.clearValidate()
    },
    handleStatus(status) {
      this.xuQianLoading = true;
      this.xuQianDis = true;
      putMerchant(Object.assign({}, this.profile, {merchantStatus: status})).then(_result => {
        this.$message.success('操作成功')
        this.handleInitial(this.id)
        this.xuQianLoading = false;
        this.xuQianDis = false;
      })
    },
    initialDeliver3(record) {
      this.deliver3.form = Object.assign({}, record)
      this.deliver3.visible = true
    },
    handleDeliver3() {
      this.$refs.form3.validate((result) => {
        if (result) {
          putMerchant(this.deliver3.form).then(_result => {
            this.$message.success('操作成功')
            this.handleInitial(this.id)
            this.deliver3.visible = false
          })
        }
      })
    },
    initialDeliver2(record) {
      this.deliver2.form = {member: record.id}
      this.deliver2.visible = true
    },
    handleDeliver2() {
      this.$refs.form1.validate((result) => {
        if (result) {
          putMerchantRemark(this.deliver2.form).then(_result => {
            this.$message.success('操作成功')
            this.$refs.table.refresh(true)
            this.deliver2.visible = false
          })
        }
      })
    },
    initialDeliver1(record) {
      this.deliver1.form = Object.assign({}, record)
      this.deliver1.visible = true
    },
    initialDeliver5(record) {
      // console.log(record.paymentChannelType);
      // if(!record.paymentChannelType){
      //   record.paymentChannelType =1;
      // }
      // this.deliver5.form.payChannel = record.paymentChannelType+'';
      this.handleChannelNameSearch('');
      this.deliver5.form = Object.assign({id:record.id,payChannel:record.paymentChannelType})

      for(let index in this.payChannelList){
        let item = this.payChannelList[index];
        if(item.mchid === record.weixinPaymentNo+''){
          this.deliver5.form.weixinPaymentNo = record.weixinPaymentNo+'';
        }
      }
      this.deliver5.visible = true
    },
    handleDeliver1() {
      this.$refs.form1.validate((result) => {
        if (result) {
          putMerchant(this.deliver1.form).then(_result => {
            this.$message.success('操作成功')
            this.handleInitial(this.id)
            this.deliver1.visible = false
          })
        }
      })
    },
    handleDeliver5() {
      this.$refs.form5.validate((result) => {
        if (result) {

            let param = Object.assign(this.deliver5.form);
            if(param.weixinPaymentNo){
              param.weixinPaymentNo = param.mName;
              putMerchant(param).then(_result => {
                this.$message.success('操作成功')
                this.handleInitial(this.id)
                this.deliver5.visible = false
              })
            }else{
              this.$message.error("请选择帐户！")
            }

        }
      })
    },
    //解绑收款商户
    unBindMechant(record){
      if(record){
        let param = Object.assign({id:record.id,weixinPaymentNo:'0'});
        putMerchant(param).then(_result => {
          this.$message.success('操作成功')
          this.handleInitial(this.id)
        });
      } else {
        this.$message.error("解绑失败！");
      }

    },
    getAccountList() {
      queryAccount({ current: 1, size: 1000 }).then(result => {
        this.acountList = result
      })
    },
    initialDeliver(record) {
      // record.shareholder = record.shareholder||0;
      // debugger;
      this.deliver.form = Object.assign({}, record)
      this.deliver.visible = true
    },
    handleDeliver() {
      // debugger;
      this.$refs.form.validate((result) => {
        if (result) {
          let param = this.deliver.form;
          debugger;
          putMerchant(param).then(_result => {
            this.$message.success('操作成功')
            this.handleInitial(this.id)
            this.deliver.visible = false
          })
        }
      })
    },
    handleInitial(id) {

      if (id) {
        getMember(this.$route.query).then(result => {
          // debugger;
          // this.profile = Object.assign({}, this.profile, result)
          this.profile = Object.assign({}, result)
          // let tepmm = this.profile;
          this.$previewRefresh()
        })
        //获取续签记录
        getContractById(this.$route.query).then(result =>{
          this.record = result;
        })
      }
      this.getAccountList();
      //填充账户名称下来数据，默认搜索全部
      this.handleChannelNameSearch('');
    },
  }
}

</script>
