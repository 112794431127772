<template>
  <page-header-wrapper :title='this.$route.meta.title'>
    <a-card :bordered='false'>
      <a-descriptions :column="13">
        <a-descriptions-item :span="12">
          <a-form-model ref='form' :model='form' layout='inline'>
            <a-form-model-item prop='start'>
              <a-range-picker
                v-model="dateArr"
                :format="[formatType, formatType]"
                :show-time="{
                  hideDisabledOptions: true
                }"
                :disabled-date="disabledDate"
                disabled
                @change="dateChange"
              />
            </a-form-model-item>
          </a-form-model>
        </a-descriptions-item>
        <a-descriptions-item>
            <a-button type='primary' @click='initialDeliver1' style="width: 100px">
              修改
            </a-button>
        </a-descriptions-item>
      </a-descriptions>

      <a-descriptions :column="5">
        <a-descriptions-item  label='商户负责人'>
            <span v-if="this.shareholder=='1'" style="color: red;">{{ this.masterName }}</span>
            <span v-else>{{ this.masterName }}</span>
<!--        </a-descriptions-item>-->
<!--        <a-descriptions-item  label='服务商'>-->
<!--          <span>{{ this.spreaderMasterName }}</span>-->
<!--        </a-descriptions-item>-->
<!--        <a-descriptions-item  label='管理商'>-->
<!--          <span>{{ this.spreaderMasterName1 }}</span>-->
<!--        </a-descriptions-item>-->
<!--        <a-descriptions-item  label='运营商' :span="2">-->
<!--          <span>{{ this.spreaderMasterName2 }}</span>-->
        </a-descriptions-item>
      </a-descriptions>

      <a-descriptions :column="3" style="margin-bottom: 20px;margin-top: 20px">
        <a-descriptions-item  label='权益订单总业绩' :span="5" >
          <span class="color-red">{{this.totalAmount}}</span>
        </a-descriptions-item>
      </a-descriptions>

      <a-descriptions :column="3" bordered  style="width:90%">
        <a-descriptions-item  label='' ><span style="font-size: 18px;font-weight: bold">商户编号</span></a-descriptions-item>
        <a-descriptions-item  label='' ><span style="font-size: 18px;font-weight: bold">姓名</span></a-descriptions-item>
<!--        <a-descriptions-item  label='' ><span style="font-size: 18px;font-weight: bold">首次业绩</span></a-descriptions-item>-->
<!--        <a-descriptions-item  label='' ><span style="font-size: 18px;font-weight: bold">复购业绩</span></a-descriptions-item>-->
        <a-descriptions-item  label='' ><span style="font-size: 18px;font-weight: bold">权益业绩</span></a-descriptions-item>

        <template v-for="(item, index) in record">
            <a-descriptions-item :key="'1' + index" label='' >{{item.authCode}}</a-descriptions-item>
            <a-descriptions-item :key="'2' + index" label='' >{{item.masterName}}</a-descriptions-item>
<!--            <a-descriptions-item :key="'3' + index" label='' >{{item.firstAmount}}</a-descriptions-item>-->
<!--            <a-descriptions-item :key="'4' + index" label='' >{{item.repurchaseAmount}}</a-descriptions-item>-->
            <a-descriptions-item :key="'5' + index" label='' >{{item.orderAmount}}</a-descriptions-item>
        </template>
      </a-descriptions>
    </a-card>

    <a-modal title='修改' :destroyOnClose='true' :maskClosable='true'
             :visible='deliver1.visible' @cancel='()=>this.deliver1.visible=false'
             ok-text='确认' cancel-text='取消' @ok='handleDeliver1' :confirmLoading='deliver1.loading'>
      <a-form-model ref='form1' :model='deliver1.form' :rules='deliver1.rules' :labelCol='{ span: 8 }' :wrapperCol='{ span: 16 }'>

        <a-form-model-item label='服务商' prop='spreader'>
          <a-select show-search v-model='deliver1.form.spreader'
                    :default-active-first-option='false'
                    :filter-option='false'
                    placeholder="请选择"
                    :allowClear='true'
                    @change="handleChange1"
                    >
            <a-select-option v-for='item in merchantList' :key='item.id'>
              {{ item.masterName || "未知" }}
            </a-select-option>
          </a-select>
        </a-form-model-item>

        <a-form-model-item label='管理商' prop='spreader'>
          <a-select show-search v-model='deliver1.form.spreader1'
                    :default-active-first-option='false'
                    :filter-option='false'
                    :allowClear='true'
                    placeholder="请选择"
                    @change="handleChange2"
                   >
            <a-select-option v-for='item in merchantList' :key='item.id'>
              {{ item.masterName || "未知" }}
            </a-select-option>
          </a-select>
        </a-form-model-item>

          <a-form-model-item label='运营商' prop='spreader2'>
            <a-select show-search v-model='deliver1.form.spreader2'
                      :default-active-first-option='false'
                      :filter-option='false'
                      :allowClear='true'
                      placeholder="请选择"
                      @change="handleChange3"
            >
              <a-select-option v-for='item in merchantList' :key='item.id'>
                {{ item.masterName || "未知" }}
              </a-select-option>
            </a-select>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </page-header-wrapper>
</template>

<script>
import { STable, Ellipsis } from '@/components'
import {getPresidentDetail, getQuotaOrderDetail} from '@/api/member-order'
import moment from 'moment'
import {queryMerchant, updateSpreaderThree} from "@/api/member";

const formatType = 'YYYY-MM-DD';
const baseStart = moment().startOf('month').format(formatType);
const baseEnd = moment().format(formatType);

export default {
  name: 'PerformancePresidentEdit',

  data() {
    return {
      formatType,
      dateArr: [baseStart, baseEnd],
      form: {
        start: baseStart,
        end: baseEnd,
        // authCode: ""
      },
      record: [],
      deliver1: {
        visible: false,
        loading: false,
        form: {},
        rules: {
          // spreader: [{ required: true, message: '必须填写服务商', trigger: 'change' }],
          // spreader1: [{ required: true, message: '必须填写管理商', trigger: 'change' }],
          // spreader2: [{ required: true, message: '必须填写运营商', trigger: 'change' }],
        },
      },
      totalAmount1:'',
      presidentAmount:0,
      shareholder:'',
      masterName:'',
      spreaderMasterName:'',
      spreaderMasterName1:'',
      spreaderMasterName2:'',
      spreader:'',
      spreader1:'',
      spreader2:'',
      merchantList:[],
      detailBean:{},
    }
  },
  components: { STable, Ellipsis },
  computed: {
    // id () {
    //   return this.$route.query.id;
    // },
    start () {
      return this.$route.query.start;
    },
    end () {
      return this.$route.query.end;
    },
    totalAmount() {
      let totalAmount = 0;
      if (this.record.length > 0) {
        totalAmount = this.record[0].presidentAmount || 0
      }
      return totalAmount
    },
    orderAmount1() {
      let orderAmount1 = 0;
      if (this.record.length > 0) {
        orderAmount1 = this.record[0].orderAmount1  || 0
      }
      return orderAmount1
    }
  },
  created() {
    this.id = this.$route.query.id;
    this.handleInitial(this.id)
  },
  activated()
  {
    this.id = this.$route.query.id;
    this.handleInitial(this.id);
    // debugger;
  },
  methods: {
    moment,
    disabledDate(current) {
      return current && current > moment().endOf('day');
    },
    dateChange() {
      this.form.start =  this.dateArr[0] ? moment(this.dateArr[0]).format(this.formatType) : "";
      this.form.end =  this.dateArr[1] ? moment(this.dateArr[1]).format(this.formatType) : "";
    },
    handleReset() {
      this.dateArr = [baseStart, baseEnd];
      this.form.start = baseStart;
      this.form.end = baseEnd;
      this.$refs.form.resetFields()
      this.handleInitial()
    },
    handleSubmit() {
      this.handleInitial()
    },
    handleInitial(id) {
      let params = {
        id: id
      }
      this.dateArr = [this.start, this.end];
      this.form.start = this.start;
      this.form.end = this.end;

      let params1 = Object.assign(params, this.form);
      getQuotaOrderDetail(params1).then(result => {
        debugger
        if(result && result.length>0){
            let tem = result[0].orderAmount;
          this.totalAmount1 = tem;
        }else{
          this.totalAmount1 = 0 ;
        }
        this.record = result;
      })

      getPresidentDetail({id:this.id}).then(result => {
        this.spreader = result.spreader;
        this.spreader1 = result.spreader1;
        this.spreader2 = result.spreader2;
        // this.presidentAmount = result.presidentAmount;
        this.shareholder = result.shareholder;
        this.masterName = result.masterName;
        this.spreaderMasterName = result.spreaderMasterName;
        this.spreaderMasterName1 = result.spreaderMasterName1;
        this.spreaderMasterName2 = result.spreaderMasterName2;
      });


    },
    initialDeliver1(record) {
      this.handleSearch1();

      //设置选中效果
      this.deliver1.form = Object.assign({
        spreader:this.spreader,
        spreader1:this.spreader1,
        spreader2:this.spreader2,
      });

      this.deliver1.loading = false
      this.deliver1.visible = true
    },
    handleDeliver1() {
      this.$refs.form1.validate((result) => {
        if(result){
          let array = [];
          array.push(this.id);
          array.push(this.deliver1.form.spreader);
          array.push(this.deliver1.form.spreader1);
          array.push(this.deliver1.form.spreader2);

          let isDup = this.hasDuplicates(array);
          // debugger;
          if(this.deliver1.form.spreader2 && !this.deliver1.form.spreader1){
            //如果有第三个推荐，那么第二个必须要有，提示报错并返回
            this.$message.error("必须要选择完管理商，才能选择运营商！");
            return;
          }
          // debugger;
          if((this.deliver1.form.spreader2 || this.deliver1.form.spreader1) && isDup){
            //有重复数据
            this.$message.error("数据选择重复，请重新选择")
          }else{
            this.deliver1.loading=true;
            let params = Object.assign(this.deliver1.form);
            params.id = this.id;
            updateSpreaderThree(params).then(result => {

              this.spreader = this.deliver1.form.spreader;
              this.spreader1 = this.deliver1.form.spreader1;
              this.spreader2 = this.deliver1.form.spreader2;

              this.$message.success("操作成功！");
              this.deliver1.loading=false;
              this.deliver1.visible = false;
            })
          }

        }
      });
    },
    handleSearch1(keyword) {
      queryMerchant({ current: 1, size: 99,president:1 }).then(result => {
        // merchantStatus : "3"
        let tempList = result.records;//查找所有商户,缓存到临时变量
        let _id = this.id;
        let arrList = [];

        if(tempList && tempList.length>0){
          for (const item of tempList) {
            if(item.id !== _id){
              if(!item.masterName){
                 item.masterName = item.mobile;
              }
              arrList.push(item);
            }
          }
        }
        this.merchantList = arrList;//查找所有商户
      })
    },
    handleChange1(e) {
      this.$set(this.deliver1.form, "spreader", e)
    },
    handleChange2(e) {
      this.$set(this.deliver1.form, "spreader1", e)
    },
    handleChange3(e) {
      this.$set(this.deliver1.form, "spreader2", e)
    },
    // 数组判断重复
    hasDuplicates(str) {
      for (let i = 0; i < str.length; i++) {
        if (str.indexOf(str[i]) !== i) {
          return true;
        }
      }
      return false;
    }
  }
}
</script>

<style lang="less" scoped>
/deep/.ant-descriptions-row > th {
  //width: 1% !important;
  padding: 0 0 !important;
  display: none;
}

/deep/.ant-descriptions-row > td {
  padding-bottom: 10px !important;
  //width: 10% !important;
}


</style>
