<template>
  <page-header-wrapper :title='this.$route.meta.title'>
    <a-card title="审核详情" :bordered='false'>
      <span slot='extra'>
        <template>
<!--          自提的 v-if=" order.status == 'delivering'"-->
          <a-space style="margin-left: 20px" >
                  <div><a  @click='openDetail("1")'>购买人可用积分：<span class="color-red">{{profile.cashBalance}}</span></a></div>
                  <div v-if="merchantProfile" style="margin-left:40px"><a  @click='openDetail("2")'>所属商户可用积分：<span class="color-red">{{merchantProfile.cashBalance}}</span></a></div>

          </a-space>

        </template>
      </span>

      <a-descriptions :column="4">
        <a-descriptions-item label='订单号'>{{order.id}}</a-descriptions-item>
        <a-descriptions-item label='下单时间'>{{order.createTime}}</a-descriptions-item>
        <a-descriptions-item label='下单方式'>{{getOrderType(order)}}</a-descriptions-item>
        <a-descriptions-item  v-if="!order.backRecord" label='订单留言'><span style="color: #E62734;">{{ order.remark }}</span></a-descriptions-item>
        <a-descriptions-item v-else></a-descriptions-item>

        <a-descriptions-item label='购买人姓名'>
           <span v-if="order.memberShareholder=='1'" class="color-red">
            {{order.nickname}}
          </span>
          <span v-else>
             {{order.nickname}}
          </span>
        </a-descriptions-item>
        <a-descriptions-item label='购买人手机号'>{{order.memberMobile}}</a-descriptions-item>
        <a-descriptions-item label='购买人编码'>{{getUserCode(order.shareholder,order.memberType,order.memberLevel)}}</a-descriptions-item>
        <a-descriptions-item></a-descriptions-item>

        <a-descriptions-item label='推荐人姓名'>
           <span v-if="order.spreaderShareholder=='1'" class="color-red">
            {{order.spreaderMasterName}}
          </span>
          <span v-else>
             {{order.spreaderMasterName}}
          </span>
        </a-descriptions-item>
        <a-descriptions-item label='推荐人手机号'>{{order.spreaderMobile}}</a-descriptions-item>
        <a-descriptions-item label='推荐人编码'>{{getUserCode(order.shareholder,order.spreaderType,order.spreaderLevel)}}
<!--          -&#45;&#45;{{order.spreaderType}}&#45;&#45;{{order.spreaderLevel}}-->
        </a-descriptions-item>
        <a-descriptions-item label='分公司名称'>{{order.spreaderAreaName}}</a-descriptions-item>
<!--        memberAreaName-->

        <template v-if="order.quickOrder>0" >
          <a-descriptions-item label='预付金额' ><span class="color-red">{{order.quickAmount}}</span></a-descriptions-item>

            <template v-if="order.merchantMember==null || order.merchantMember==order.member ">
              <!--          只针对直付-->
                <a-descriptions-item label='结余金额' >{{order.quickAmount - order.amount}}</a-descriptions-item>
                <a-descriptions-item label='状态' >
                  <span v-if="order.quickOrder=='1'" class="color-red">待选购</span>
                  <span v-if="order.quickOrder=='2' && order.quickAmount == order.amount">已完成</span>
                  <span v-if="order.quickOrder=='2' && (order.quickAmount - order.amount)>0">
                    待退款 <a @click="initBackMoney(order)">手动退款</a>
                  </span>
                  <span v-else-if="order.quickOrder=='3'">已退款</span>
                </a-descriptions-item>
            </template>
          <template v-else>
            <!--          支付给商户就不显示-->
            <a-descriptions-item :span="2"></a-descriptions-item>
          </template>
          <a-descriptions-item label='实退金额' >{{order.quickRefundAmount}}</a-descriptions-item>

          <template v-if="order.merchantMember==null || order.merchantMember==order.member ">
            <a-descriptions-item label='退款方式' :span="4">
              <span v-if="order.quickRefundMethod=='score'">积分</span>
              <span v-else-if="order.quickRefundMethod=='money'">现金</span>
              <span v-else>未退款</span>
            </a-descriptions-item>
          </template>

        </template>

        <template v-if="!order.isShowOne">
          <!--          非后台录单显示-->
        <a-descriptions-item label='所属商户编码'>
              <span v-for="(item, index) in getAuthCodeList(order.authCode)" :key="index"
                    :style="{color: index == 1 ? '#1890ff' : ''}">{{ item }}</span>
            </a-descriptions-item>
            <a-descriptions-item label='负责人姓名'>{{order.masterName}}</a-descriptions-item>
            <a-descriptions-item label='商户名称'>{{order.merchantName}}</a-descriptions-item>
            <a-descriptions-item ></a-descriptions-item>
        </template>

        <template v-if="order.isShowOne">
          <!--            商户录单只显示订单金额-->
          <template v-if="order.merchantRecord">
                  <a-descriptions-item label='订单金额' :span="4">{{order.amount}}</a-descriptions-item>
          </template>
          <template v-else>
                  <!--           商户直付给公司，要取商户字段，-->
                  <template v-if="order.merchantMember==order.member">
                    <a-descriptions-item label='订单原始应付'>{{order.firstMerchantAmount}}</a-descriptions-item>
                  </template>
                  <!--           其他情况取用户购买字段-->
                  <template v-else>
                    <a-descriptions-item label='订单原始应付'>{{order.firstMemberAmount }}</a-descriptions-item>
                  </template>

                  <a-descriptions-item label='订单金额'>{{order.amount}}</a-descriptions-item>

                  <a-descriptions-item label='购买人应付' v-if="order.merchantMember==order.member">{{order.originalMerchantAmount}}</a-descriptions-item>
                  <a-descriptions-item label='购买人应付' v-else>{{order.amount}}</a-descriptions-item>

                  <a-descriptions-item v-if="order.merchantMember==order.member" label='积分抵扣'>{{order.merchantDiscount}}</a-descriptions-item>
                  <a-descriptions-item v-else label='积分抵扣'>{{order.memberDiscount}}</a-descriptions-item>
                  <a-descriptions-item v-if="order.merchantMember && order.merchantMember==order.member" label='转账优惠'>{{order.bankTransDiscount}}</a-descriptions-item>
                  <a-descriptions-item v-else></a-descriptions-item>

                  <template v-if="order.merchantMember==order.member">
                    <!--           商户直付给公司，要取商户字段，-->
                    <a-descriptions-item label='购买人实付'><span class="color-red">{{order.merchantAmount}}</span></a-descriptions-item>
                    <a-descriptions-item label='购买人支付方式'>{{order.merchantPaymentMethodName }}</a-descriptions-item>
                    <a-descriptions-item label='购买人支付时间' :span="2">{{order.merchantActualPaymentTime}}</a-descriptions-item>
                  </template>
                  <template v-else>
                    <!--           其他情况取用户购买字段-->
                    <a-descriptions-item label='购买人实付'><span class="color-red">{{order.consumeAmount}}</span></a-descriptions-item>
                    <a-descriptions-item label='购买人支付方式'>{{order.paymentMethodName }}</a-descriptions-item>
                    <a-descriptions-item label='购买人支付时间' :span="2">{{order.createTime}}</a-descriptions-item>
                  </template>
                </template>
          </template>

        <template v-if="order.isShowTwo">

          <template v-if="order.applyId">
            <!--          首先判断为批量兑换订单,不显示商户相关信息，只显示，商户名称和负责人-->
              <a-descriptions-item label='商户名称'>{{order.merchantName}}</a-descriptions-item>
              <a-descriptions-item label='负责人' :span="4">{{order.masterName}}</a-descriptions-item>
          </template>
          <template v-else>
            <!--         如果是其他订单，那么就显示所有商户信息-->
            <a-descriptions-item label='商户原始应付'>{{order.firstMerchantAmount}}</a-descriptions-item>
            <a-descriptions-item label='商户应付'>{{order.originalMerchantAmount}}</a-descriptions-item>
            <a-descriptions-item label='积分抵扣'>{{order.merchantDiscount}}</a-descriptions-item>
            <a-descriptions-item v-if="order.merchantMember && order.merchantMember!=order.member" label='转账优惠'>{{order.bankTransDiscount}}</a-descriptions-item>
            <a-descriptions-item v-else></a-descriptions-item>
            <a-descriptions-item label='商户实付'><span class="color-red">{{order.merchantAmount}}</span></a-descriptions-item>
          </template>


          <a-descriptions-item label='商户支付方式'>{{order.merchantPaymentMethodName}}</a-descriptions-item>
          <a-descriptions-item label='商户支付时间'>{{order.merchantActualPaymentTime}}</a-descriptions-item>
          <!--            merchantPaymentTime-->
          <a-descriptions-item label='所在地区'>{{order.area}}</a-descriptions-item>


        </template>

        <template v-if="order.isShowThree">
          <a-descriptions-item label='总销'>{{order.wealthQuotaMemberName}}</a-descriptions-item>
          <a-descriptions-item label='付款人姓名'>{{order.fuZeRen}}</a-descriptions-item>
          <a-descriptions-item label='转账凭证'>
            <img v-for="(item, index) in order.merchantPaymentProofList" :key="index" :preview="true" :src='item' style="margin-right: 20px;" width='100px' height="100px" />
          </a-descriptions-item>
          <a-descriptions-item label='转账方式'>{{order.bankTransBuyerName?'他人代付':'本人付'}}</a-descriptions-item>
        </template>

        <template>
          <a-descriptions-item label='购买协议' v-if="order.purchaseAgreementStatus=='1'">
            <img  :preview="true" :src='order.purchaseAgreementUrl' style="margin-right: 20px;" width='100px' height="100px" />
          </a-descriptions-item>
          <a-descriptions-item label='订货协议' v-if="order.orderAgreementStatus=='1'">
            <img  :preview="true" :src='order.orderAgreementUrl' style="margin-right: 20px;" width='100px' height="100px" />
          </a-descriptions-item>
        </template>


      </a-descriptions>

      <a-descriptions :column="4" v-if="order.auditStatus=='1'">
      <template>
        <template  v-for="(item, index) in order.integralRewardList" >
          <a-descriptions-item  :key="index" >{{item.remark}}:   {{item.memberName||' 未知 :'}} <a-input style="width: 100px;" v-model="item.value"/></a-descriptions-item>

        </template>
      </template>

<!--        <a-descriptions-item  :key="11" label="剩余可加速金额">{{order.fastAmount}}</a-descriptions-item>-->
      </a-descriptions>


      <a-descriptions v-if="order.auditStatus=='1'">
        <a-descriptions-item :span="4">
<!--          {{item}}-->
          <a-button type='primary' :loading="loading" @click='handleSubmit'>
            保存审核
          </a-button>
        </a-descriptions-item>
      </a-descriptions>
    </a-card>


    <a-card v-if="record && record.length > 0" style="margin-top: 20px;" :bordered='false'>
      <template slot='title'>
        <span style="padding-right: 10px;">审核记录</span>
        <a-tag v-if='order.auditStatus==1' color='yellow'>待审核</a-tag>
        <a-tag v-else-if='order.auditStatus==2' color='green'>已审核</a-tag>
        <a-tag v-else-if='order.auditStatus==3' color='blue'>已审核</a-tag>
        <a-tag v-else-if='order.auditStatus==4' color='red'>已删除</a-tag>
      </template>


      <a-descriptions :column="1">
        <a-descriptions-item v-for="item in record" :key="item.id">
          <span style="margin-right: 80px">{{ item.createTime }}</span>
          <span style="margin-right: 30px">{{ item.operateDes }}</span>
          <span>{{ item.operatorNickname }}</span>
        </a-descriptions-item>
      </a-descriptions>
    </a-card>

    <a-modal title='可用积分明细' :destroyOnClose='true' :maskClosable='true' width="70%"
             @cancel='()=>this.deliver1.visible=false' :footer="null"
             :visible='deliver1.visible' :confirmLoading='deliver1.loading'>
      <a-descriptions  :column="5">
      </a-descriptions>
      <a-form-model ref='form1' :model='deliver1.form'  layout='inline'>
        <a-form-model-item prop='name'>
          姓名：{{this.guiShuName}}
        </a-form-model-item>
        <a-form-model-item prop='name'>
          积分统计：<span class="color-red">{{this.ext4 }}</span>
        </a-form-model-item>

        <a-form-model-item prop='type'>
          <a-select v-model="deliver1.form.type" placeholder="变动类型" style="width: 250px" @change="changeSelect">
            <a-select-option :value="undefined">
              变动类型
            </a-select-option>
            <a-select-option :value="15">
              推广
            </a-select-option>
            <a-select-option :value="16">
              推广+活动
            </a-select-option>
            <a-select-option :value="28">
              业绩10%
            </a-select-option>
            <a-select-option :value="26">
              业绩5%
            </a-select-option>
            <a-select-option :value="17">
              业绩2%
            </a-select-option>
            <a-select-option :value="24">
              业绩1.5%
            </a-select-option>
            <a-select-option :value="25">
              业绩1-1%
            </a-select-option>
            <a-select-option :value="27">
              业绩1%
            </a-select-option>
            <a-select-option :value="19">
              消费积分
            </a-select-option>
            <a-select-option :value="21">
              批量兑换
            </a-select-option>
            <a-select-option :value="22">
              批量兑换退回
            </a-select-option>
            <a-select-option :value="7">
              取删退回
            </a-select-option>
            <a-select-option :value="1">
              使用
            </a-select-option>
            <a-select-option :value="5">
              转赠
            </a-select-option>
            <a-select-option :value="6">
              受赠
            </a-select-option>
            <a-select-option :value="2">
              修改
            </a-select-option>
            <a-select-option :value="9">
              门店补贴
            </a-select-option>
            <a-select-option :value="29">
              拓展服务
            </a-select-option>
          </a-select>
        </a-form-model-item>

<!--        <a-form-model-item prop='start'>-->
<!--          <a-range-picker-->
<!--            v-model="dateArr"-->
<!--            :format="[formatType, formatType]"-->
<!--            :show-time="{-->
<!--            hideDisabledOptions: true-->
<!--          }"-->
<!--            :disabled-date="disabledDate"-->
<!--            @change="dateChange"-->
<!--          />-->
<!--        </a-form-model-item>-->
        <a-form-model-item>
          <a-button type='primary' @click='handleSubmit1'>
            查询
          </a-button>
        </a-form-model-item>
        <a-form-model-item>
          <a-button @click='handleReset1'>
            重置
          </a-button>
        </a-form-model-item>
      </a-form-model>
      <s-table id="table1" ref='table1' rowKey='id' size='default' :columns='columns1' :pageSize='30' :scroll='{ x: 1500}' :data='loadData1'>
        <span slot='orderId' slot-scope='text, record'>
          <template>
              {{showCaseText(record)}}
          </template>
        </span>
      </s-table>
    </a-modal>

    <a-modal title='手动退款' :destroyOnClose='true' :maskClosable='true'
             :visible='deliver2.visible' @cancel='()=>this.deliver2.visible=false'
             ok-text='确认' cancel-text='取消' @ok='handleBackMoney' :confirmLoading='deliver2.loading'>
      <a-form-model ref='form2' :model='deliver2.form' :rules='deliver2.rules' :labelCol='{ span: 6 }' :wrapperCol='{ span: 18 }'>
        <a-form-model-item label='退款金额' prop='backAmount'>
          <a-input v-model='deliver2.form.backAmount' prefix="￥" type="number"/>
        </a-form-model-item>

        <a-form-model-item  label='退款方式' prop="backType">
          <a-select v-model="deliver2.form.backType" placeholder="请选择" >
            <a-select-option :value="1">积分</a-select-option>
            <a-select-option :value="2">现金</a-select-option>
          </a-select>
        </a-form-model-item>
      </a-form-model>
    </a-modal>

  </page-header-wrapper>
</template>

<script>
import {STable, Ellipsis} from '@/components'
import {getMember, getMerchantMemberByMobile} from '@/api/member'
import {getMemberOrder, putAuditOrder, getAuditRecord, getMemberCash, refund} from '@/api/member-order'
import {getFuZeRen, getMemberOrderType, getSpreaderMobileList, showOrderType} from '@/utils/util'
import moment from 'moment'

const formatType = 'YYYY-MM-DD HH:mm';
// const baseStart = moment().startOf('month').format(formatType);
// const baseEnd = moment().format(formatType);
// const baseEnd = moment().startOf('day').format('YYYY-MM-DD')+ ' 23:59';

const columns1 = [
  {
    width: 80,
    title: '变动类型',
    dataIndex: 'businessName',
    scopedSlots: { customRender: 'businessName' }
  },
  {
    width: 100,
    title: '变动内容',
    dataIndex: 'quantity',
    scopedSlots: { customRender: 'quantity' }
  },
  {
    width: 80,
    title: '变动后可用积分',
    dataIndex: 'afterBalance',
    scopedSlots: { customRender: 'afterBalance' }
  },
  {
    width: 80,
    title: '发生订单号',
    dataIndex: 'orderId',
    scopedSlots: { customRender: 'orderId' }
  },
  {
    width: 100,
    title: '变动时间',
    dataIndex: 'createTime',
    scopedSlots: { customRender: 'createTime' }
  },
  {
    width: 200,
    title: '备注',
    dataIndex: 'des',
    scopedSlots: { customRender: 'des' },
    sorter: true,
  }
]

export default {
  name: 'AuditOrderEdit',

  data() {
    return {
      guiShuName:'',//归属名称
      ext4:0,//积分合计
      loading: false,
      profile: {},
      merchantProfile:{},
      order: {},
      record: [],
      font_class: "",
      yingFu: 0,
      formatType,
      columns1,
      // dateArr: [baseStart, baseEnd],
      deliver1: { visible: false,
        form: {
          // start: baseStart,
          // end: baseEnd,
        }, loading: false
      },
      deliver2: { visible: false,
        form: {
          backAmount:0,
          backType:1,
        },
        loading: false,
        rules:{
          backType: [
            { required: true, message: '请选择退款方式', trigger: 'change' },
          ],
          backAmount:[
            { required: true, message: '请输入数量', trigger: 'change' },
          ],
        }
      },
      mType:1,
      loadData1: parameter => {
        parameter.pageNum = parameter.current;
        parameter.pageSize = parameter.size;
        delete parameter.current;
        delete parameter.size;
        let gsName = '';
        if(this.mType==='1'){
          let _profile = this.profile
          parameter.member = _profile.id;
          gsName= _profile.nickname
        }else{
          let _merchantProfile = this.merchantProfile
          parameter.member = _merchantProfile.id;
          gsName = _merchantProfile.masterName
        }
        this.guiShuName = gsName;

        let params = Object.assign(parameter,this.queryParam, this.deliver1.form);
        // debugger
        return getMemberCash(params).then(datum => {
          this.ext4 = datum.ext4;
            // if(datum.records && datum.records.length>0){
            //   this.guiShuName = datum.records[0].userName
            // }
            return datum
          })
      },


    }
  },
  components: {STable, Ellipsis},
  computed: {
    // id () {
    //   return this.$route.query.id;
    // },
  },
  activated() {
    this.id = this.$route.query.id
    this.handleInitial();
  },
  created() {
    this.id = this.$route.query.id;
    this.handleInitial()
  },
  methods: {
    getSpreaderMobileList,
    handleSubmit() {
      let discountDes = this.order.discountDes;
      // debugger;
      if (discountDes) {
        let isAdopt = false;
        let discountReg = [
          /^-?3\d+$/,
          /^-?4\d+$/,
          /^-?5\d+$/,
          /^-?7\d+$/,
          /^-?3\d+\/-?4\d+$/,
          /^-?3\d+\/-?5\d+$/,
          /^-?3\d+\/-?7\d+$/,
          /^-?4\d+\/-?5\d+$/,
          /^-?4\d+\/-?7\d+$/,
          /^-?5\d+\/-?7\d+$/,
          /^-?3\d+\/-?4\d+\/-?5\d+$/,
          /^-?3\d+\/-?4\d+\/-?7\d+$/,
          /^-?3\d+\/-?5\d+\/-?7\d+$/,
          /^-?4\d+\/-?5\d+\/-?7\d+$/,
          /^-?3\d+\/-?4\d+\/-?5\d+\/-?7\d+$/];
        for (const item of discountReg) {
          if (item.test(discountDes)) {
            isAdopt = true;
          }
        }
        if (!isAdopt) {
          this.$message.warning('商品折扣的格式必须为3X,4X,5X,3X/4X,3X/5X,3X/7X,4X/5X,4X/7X,5X/7X,3X/4X/5X,3X/4X/7X,3X/5X/7X,4X/5X/7X,3X/4X/5X/7/X，其中X必须为正整数');
          return;
        }
      }
      let params = {
        id: this.id,
        // quotaBalance: discountDes,
        // cashBalance: this.order.cashBalance,
      }

      this.loading = true;
      // let tempOrder = this.order;
      if(this.order.integralRewardList){
        params.integralRewardList = this.order.integralRewardList;
      }
      // debugger;
      putAuditOrder(params).then(result => {
        this.$message.success('操作成功')
        this.handleInitial();
        this.loading = false;
      }).catch(() => {
        this.loading = false;
      })
    },
    getAuthCodeList(authCode) {
      if (authCode) {
        let index = authCode.indexOf("-");
        if (index < 0) {
          index = authCode.length - 1;
        } else {
          index--;
        }
        let ownAuthCode = authCode.slice(index - 3, index + 1);
        if (ownAuthCode) {
          const authCodeList = authCode.split(ownAuthCode);
          authCodeList.splice(1, 0, ownAuthCode);
          return authCodeList;
        }
      }
      return [];
    },
    handleInitial() {
      let params = {
        id: this.id
      }
      getMemberOrder(params).then(result => {
        if (result.batchSelect == "1") {
          this.font_class = "font-red";
          //批量
          delete result.centerAuthCode;
          delete result.centerAuthCode1;
          delete result.centerAuthCode2;
          delete result.presidentAuthCode;
          if (result.presidentAuthCode1) {
            result.presidentMemberId1Reward = 960;
          }
          if (result.presidentAuthCode2) {
            result.presidentMemberId2Reward = 640;
          }
        } else if (!result.id.endsWith('7') && !result.id.endsWith('7SD') && result.orderType > 1 || result.id.endsWith('1') || result.id.endsWith('1SD')) {
          if (result.id.endsWith('1') || result.id.endsWith('1SD')) {
            if (result.merchantType == "president") {
              delete result.centerAuthCode;
              delete result.centerAuthCode1;
              delete result.centerAuthCode2;
              delete result.presidentAuthCode;
              delete result.presidentAuthCode1;
              delete result.presidentAuthCode2;
            } else if (result.merchantType == "center") {
              delete result.centerAuthCode;
              delete result.centerAuthCode1;
              delete result.centerAuthCode2;
              delete result.presidentAuthCode1;
              delete result.presidentAuthCode2;
            } else {
              delete result.centerAuthCode1;
              delete result.centerAuthCode2;
              delete result.presidentAuthCode1;
              delete result.presidentAuthCode2;
            }
            if (result.centerAuthCode) {
              result.centerMemberIdReward = parseInt(result.amount / 10);
            }
            if (result.presidentAuthCode) {
              result.presidentMemberIdReward = parseInt(result.amount / 10);
            }
          } else if (result.jumpType > 0 && result.isVipPackage == 2) {// 用户下单32000套餐，上级云店或服务中心跳转使用运营中心名额
            delete result.presidentAuthCode;
            result.centerMemberIdReward = 3200;
            result.centerMemberId1Reward = 640;
            result.centerMemberId2Reward = 320;
            result.presidentMemberId1Reward = 960;
            result.presidentMemberId2Reward = 640;
          } else {
            if (result.isVipPackage == 2) {
              result.centerMemberIdReward = 3200;
              result.centerMemberId1Reward = 640;
              result.centerMemberId2Reward = 320;
              result.presidentMemberIdReward = 3200;
              result.presidentMemberId1Reward = 960;
              result.presidentMemberId2Reward = 640;
            } else if (result.isVipPackage == 1) {
              result.centerMemberIdReward = 480;
              result.centerMemberId1Reward = 96;
              result.centerMemberId2Reward = 48;
              result.presidentMemberIdReward = 480;
              result.presidentMemberId1Reward = 144;
              result.presidentMemberId2Reward = 96;
            } else {
              result.centerMemberIdReward = 100;
              result.centerMemberId1Reward = 31;
              result.centerMemberId2Reward = 15;
              result.presidentMemberIdReward = 100;
              result.presidentMemberId1Reward = 47;
              result.presidentMemberId2Reward = 31;
            }
            if (result.merchantType == "president") {
              delete result.centerAuthCode;
              delete result.centerAuthCode1;
              delete result.centerAuthCode2;
              delete result.presidentAuthCode;
            } else if (result.merchantType == "center") {
              delete result.centerAuthCode;
            }
          }
        } else {
          delete result.centerAuthCode;
          delete result.centerAuthCode1;
          delete result.centerAuthCode2;
          delete result.presidentAuthCode;
          delete result.presidentAuthCode1;
          delete result.presidentAuthCode2;
        }


        result.fuZeRen = getFuZeRen(result);//获取负责人

        result = showOrderType(result); //公共方法获取显示区域



        this.order = result;
        let tempMobile = result.member;

        getMember(Object.assign({id: tempMobile})).then(result => {
          this.profile = Object.assign({}, result);

          getMerchantMemberByMobile(Object.assign({mobile: this.profile.mobile})).then(result => {
            this.merchantProfile = Object.assign({}, result);

          }).catch(error=>{
            this.merchantProfile = undefined;
          })

        })

      })
      getAuditRecord(params).then(result => {
        this.record = result || [];
      })
    },
    getUserCode(shareholder,type,level){
      // debugger
      if(shareholder=='1'){
        //股东判断
        return '股东'
      }
      if(type=='store'){
        return '门店'
      }else if(type=='center') {
        // return '服务中心';
        return 'SF';
      }else if(type=='president') {
        // return '运营总裁';
        return 'SY'
      }else if(type=='normal'){
        if(level=='center'){
          // return '备选服务';
          return 'BF';
        } else if(level=='president'){
          // return '备选运营';
          return 'BY';
        }
        // return '用户';
        return 'Y';
      }else{
        return '';
      }
    },
    openDetail(mType){
      this.deliver1.loading = false;
      this.deliver1.visible = true;
      this.mType = mType;
    },
    disabledDate(current) {
      return current && current > moment().endOf('day');
    },
    // dateChange() {
    //   this.deliver1.form.start =  this.dateArr[0] ? moment(this.dateArr[0]).format(this.formatType) : "";
    //   this.deliver1.form.end =  this.dateArr[1] ? moment(this.dateArr[1]).format(this.formatType) : "";
    // },
    handleSubmit1() {
      this.handleInitial();
      this.$refs.table1.refresh(true)

    },
    handleReset1() {
      // debugger;
      // this.dateArr = [baseStart, baseEnd];
      // this.deliver1.form.start = baseStart;
      // this.deliver1.form.end = baseEnd;
      this.$refs.form1.resetFields()
      this.$refs.table1.refresh(true)
    },
    showCaseText(record){
      // debugger;
      let text = "";
      if(record.businessName==='修改'||record.businessName==='受让'||record.businessName==='转让'){
        // debugger
        text = record.opUsername;
      }else{
        text = record.orderId;
      }
      // debugger;
      // text = text + record;
      return text;
    },
    changeSelect(){
        this.handleSubmit1()
    },
    //显示退款窗口
    initBackMoney(order){
      let amount = order.quickAmount - order.amount;
        this.deliver2.form={
          backType: 1,
          backAmount:amount,
          backAmountBk:amount,
        };
        this.deliver2.loading = false
        this.deliver2.visible = true
    },
    //退款确认
    handleBackMoney(){
      this.$refs.form2.validate((result) => {
        if (result) {
          let backAmount = this.deliver2.form.backAmount;
          let backAmountBk = this.deliver2.form.backAmountBk;
          if(backAmount > backAmountBk){
            this.$message.success('退款金额过大，请设置少于'+backAmountBk);
            return;
          }

          let method = this.deliver2.form.backType==1?'score':'money';
          let amount = this.deliver2.form.backAmount;
          let params = Object.assign({method:method,amount:amount,id:this.id});
          refund(params).then(result => {
            this.handleInitial();
              this.$message.success('操作成功')
          })
          this.deliver2.loading = true
          this.deliver2.visible = false
        }
      })
    },
    getOrderType(order){
      return getMemberOrderType(order);
    },
  }
}
</script>
<style scoped>
.font-red {
  color: #E62734 !important;
}


</style>
